import ErrorBoundary from "../../api/ErrorBoundary";
import ResetPassword from "../rederPages/ResetPassword";
import useGTMHooks from "../../hooks/useGTMHooks";
import { useEffect } from "react";

const ResetPasswordBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <ErrorBoundary>
            <ResetPassword/>
        </ErrorBoundary>
    )
}

export default ResetPasswordBeforeRender