import ErrorBoundary from "../../api/ErrorBoundary";
import SystemError from "../rederPages/SystemError";
import useGTMHooks from "../../hooks/useGTMHooks";
import { useEffect } from "react";

const SystemErrorBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
      <ErrorBoundary>
        <SystemError/>
      </ErrorBoundary>
    )
}

export default SystemErrorBeforeRender