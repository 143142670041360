import React from 'react'
import { Container } from "../../components/layouts/Container";
import { Box } from '../../components/layouts/Box';
import { TitleElement, ExaminationStatusElement, ExminationCancelBarElement } from '../../components/ui_elements';
import useExaminationStatusHooks from '../../hooks/useExaminationStatusHooks';
import { BackButton } from '../../components/ui-parts/BackButton';

const ExaminationStatus = () => {
  const { ExaminationStatusContext, IsService, serviceCodeList, errMsg, navigate } = useExaminationStatusHooks() 

  return (
    <Container
      isLogin
      isInquiry
    >
      <TitleElement />
      <div className="mb8 align_right display-mobile">
        <BackButton onClick={()=>navigate('/dashboard', {state:{routeTo:'dashboard'}})}>ダッシュボードに戻る</BackButton>
      </div>
      <ExminationCancelBarElement 
        examination={ExaminationStatusContext}
      />
      {errMsg === "" &&
        <>
          <Box mt={18}/>
          <ExaminationStatusElement
            examination={ExaminationStatusContext}
            IsService={IsService}
            serviceCodeList={serviceCodeList}
          />
        </>
      }
    </Container>
  );
};

export default ExaminationStatus