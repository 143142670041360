import React from 'react';
import styled from 'styled-components';
import { mixins } from '../../css/variables/mixins';
import { fonts } from '../../css/variables/fonts';
import { EntryIcons } from './EntryIcons';
import { ToolChip } from './ToolChip';

const EntryHeadStyle = styled.h2`
  display: flex;
  gap: 8px;
  align-items: top;
  margin-top: 0;
  margin-bottom: 36px;
  ${mixins.screenOver()}{
    font-size: ${26 / fonts.fontSizeBase() + 'rem'};
  }
  ${mixins.screenUnder()}{
    font-size: ${22 / fonts.fontSizeBase() + 'rem'};
  }
  .text{
    flex:1;
  }
  img{
    vertical-align: -0.5rem;
  }
`;
const ToolChipWrapStyle = styled.span`
  display: inline-block;
  margin-left: 8px;
  vertical-align: text-bottom;
`;


export const EntryHead = ({ icon, children, toolChip }) => {
  return (
    <EntryHeadStyle>
      <span className='icon'><EntryIcons icon={icon} /></span>
      <span className='text'>{children}</span>
      {toolChip ? (
        <ToolChipWrapStyle>
          <ToolChip content={toolChip} />
        </ToolChipWrapStyle>
      ) : (<></>)}
    </EntryHeadStyle>
  );
};

EntryHead.defaultProps = {

};

