import React from "react";
import SystemError from "../pages/rederPages/SystemError";

export default class ErrorBoundary extends React.Component {
    constructor(props){
        super(props);
        this.state = { error:null, errorInfo: null}
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render(){
        if(this.state.errorInfo) {
            return(
                <SystemError/>
            )
        }
        return this.props.children
    }
}