import React, { useRef, useState, useMemo, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSession, setFileInfoData } from '../sessionStorage/sessionStorageMethod';
import { initFileSessionData } from '../constance/sessionData';
import { Context } from '../stores/Provider';
import { validationPattern } from '../validators/publicValidator';
import { PostMethod } from '../api/Api';
import { setIsLoading } from '../function/setIsLoading';
import { InquiryFileItem } from '../components/ui-parts';
import { logoutAbnormalFunction } from '../function/logoutAbnormalFunction';

const useFileInputHooks = ({maxsize, id, multiple, page}) => {
    const {state, dispatch} = useContext(Context)
    const navigate = useNavigate()
    const userInfoData = getSession('user')
    //コンテキスト抽出
    const fileContextState = useMemo(()=>{
        if(state.entry.files === null || state.entry.files === undefined || state.entry.files === initFileSessionData) return getSession('files')
        return state.entry.files
    },[state.entry.files])

    // const fileInfoData = getSession('files')
    const setFileDataHandler = (data) => {
        setFileInfoData(data)
        dispatch({
            type : "set-entry-files",
            payload : data

        })
    }
    const [fileState, setFileState] = useState(fileContextState[id])
    const [dragOverFlg, setDragOverFlg] = useState(false);
    const inputFileRef = useRef(null);
    
    const maxSizeByte = 1024 * 1024 * maxsize;
    const newArrayFileData = useMemo(()=>{
        const newArrayFileData = fileContextState[id].length !== 0 ? Object.values(fileContextState[id]) : []
        return newArrayFileData
    },[fileContextState,id])

    const DragOverHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragOverFlg(true);
    }
    const DragLeaveHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragOverFlg(false);
    }
    const FileDropHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragOverFlg(false);
        var files = e.dataTransfer.files;
        inputFileRef.current.files = files;
        LoadFile();
    }
    const DeteleFileHandler = useCallback(async(index) => {
        const newFiles = newArrayFileData;
        const postData = {
            file_method:"delete",
            entry_id: userInfoData.entry_id,
            files:{
                ...initFileSessionData,
                [id] : [
                    { file_id: newFiles[index].file_id }
                ]
            }
        }
        //ローディング開始
        setIsLoading(true,dispatch)
        const response = await PostMethod(postData, '/file_data')
        //エラー時ハンドラ
        if(response.code !== 200){
            if(response.code === 401) return logoutAbnormalFunction(response,dispatch,navigate)
            return navigate('/system_error')
        }
        newFiles.splice(index, 1)
        setFileDataHandler({ ...fileContextState, [id]:newFiles })
        setFileState(newFiles)
        //ローディング終了
        setIsLoading(false,dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, fileContextState, id, navigate, newArrayFileData, userInfoData.entry_id])

    const thumbs = useMemo(() => {
        const thumb = newArrayFileData.map((file, index) => (
            <InquiryFileItem key={index} itemNum={index} file={file} deleteFile={DeteleFileHandler} upload entry />
        ))
        return thumb
    }, [DeteleFileHandler,newArrayFileData]);

    

    /**
     * ファイルをロード
     */
    
    const LoadFile = async(e) => {
        let insertFileData = fileState;
        let maxFileNumber = 0
        let count = 0;
        const inputFiles = inputFileRef.current.files;
        let sumFileSize = -1

        //複数ファイルバリデーション
        if (!multiple) {
            if (Object.entries(fileContextState[id]).length > 0 || inputFiles.length > 1) {
                alert('アップロードできるファイルは1つだけです。');
                inputFileRef.current.value = '';
                return false;
            }
        }
        
        //ファイルサイズ計算＋ファイル連番最大値取得（API通信前のファイル）
        insertFileData.forEach((file)=>{
            //ファイルサイズ計算
            sumFileSize += file.file_size
            //ファイル連番最大値取得
            let compareNumber = Number(file.file_name.split('.')[0].split('_').pop())
            if(compareNumber > maxFileNumber) maxFileNumber = compareNumber
        })

        
        //ファイルをまとめて登録
        await Promise.all(Object.entries(inputFiles).map(async(fileArray)=>{
            let filePromise = new Promise((resolve)=>{
                let file = fileArray[1]
                sumFileSize += file.size
                //ファイルの合計が30MB以上か確認
                if (sumFileSize > maxSizeByte) {
                    alert(`「アップロードファイルのサイズ上限${maxsize}MBを超えています。ファイルサイズを小さくして再度アップロードしてください。`);
                    count++;
                    sumFileSize -= file.size
                    return
                }
                var fr = new FileReader();
                fr.onload = async(e) => {
                    //ファイル拡張子バリデーション
                    if(!validationPattern.file.includes(file.name.split('.')[1])){
                        let message = 'png,jpeg,pdf以外は登録できません'
                        dispatch({
                            type: `set-validate-${page}`,
                            payload: {
                                ...state.validateMessages[page],
                                [id]:{
                                    message:message
                                }
                            }
                        })
                        return setIsLoading(false, dispatch) 
                    } else {
                        dispatch({
                            type: `set-validate-${page}`,
                            payload: {
                                ...state.validateMessages[page],
                                [id]:{
                                    message:""
                                }
                            }
                        })
                    }
                    
                    maxFileNumber++
                    const fileData = e.target.result !== "" ? e.target.result.split('base64,')[1] : ""
                    const tempFileData = { file_name_origin: file.name, file_size: file.size, file_data: fileData }
                    resolve(tempFileData)
                }
                fr.readAsDataURL(file);
            })
            return filePromise
        })).then(async(responseArray)=>{
            const postData = {
                entry_id : userInfoData.entry_id,
                file_method : "register",
                files : {
                    ...initFileSessionData,
                    [id] : responseArray
                }
            }
            //ローディング開始
            setIsLoading(true, dispatch)
            const response = await PostMethod(postData, '/file_data') 
            //ローディング終了
            setIsLoading(false, dispatch)
            // ファイル登録失敗時の挙動
            if(response.code !== 200){
                if(response.code === 401) return logoutAbnormalFunction(response,dispatch,navigate)
                return navigate('/system_error')
            } 
            // ファイル登録成功時の挙動
            insertFileData = insertFileData.concat(response.response_data.files)
            setFileState(insertFileData)
            setFileDataHandler({ ...fileContextState, [id]: insertFileData });
        })

        const setNullValue = (e) => {
            if(e === undefined) return
            e.target.value = null
        }
        
        //同じファイルを登録->消去->登録ができないバグを解消する
        setTimeout(()=>setNullValue(e), 1000)
    }
    return {dragOverFlg, setDragOverFlg, thumbs, DragOverHandler, DragLeaveHandler, FileDropHandler, LoadFile, inputFileRef, fileContextState}
}

export default useFileInputHooks