import React from 'react';
import styled from 'styled-components'
import { mixins } from '../../../css/variables/mixins';
import { colors } from '../../../css/variables/colors';
import { EntryContentsMerchant } from './EntryContents/EntryContentsMerchant';
import { EntryContentsBankAccount } from './EntryContents/EntryContentsBankAccount';
import { EntryContentsShop } from './EntryContents/EntryContentsShop';
import { EntrySide } from './EntrySide';

const EntryColumnStyle = styled.div`
  display: flex;
  ${mixins.screenOver()}{
    gap: 32px;
  }
  ${mixins.screenUnder()}{
    flex-direction: column;
  }
  &>main{
    flex: 1;
    background: ${colors.themeColor('background_main')};
    border-radius:8px;
    border:1px solid ${colors.themeColor('border_box')};
    ${mixins.screenOver()}{
      padding: 36px;
    }
    ${mixins.screenUnder()}{
      padding: 28px 16px;
    }
  }
  &>div:last-child{
    ${mixins.screenOver()}{
    width: 270px;
  }
  }
`

export const EntryColumn = ({path}) => {
  let showPage;
  switch(path){
    case 'merchant': showPage = <EntryContentsMerchant/> 
      break;
    case 'bank_account': showPage = <EntryContentsBankAccount/>
      break;
    case 'shop': showPage = <EntryContentsShop/>
      break;
    default: showPage = '表示する内容がありません'
  }
  return(
  <EntryColumnStyle>
    <main>
      {showPage}
    </main>
    <div>
      <EntrySide path={path}/>
    </div>
  </EntryColumnStyle>
  )
  };


