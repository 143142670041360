import { ErrorHandler } from "../../api/ErrorHandler"
import { Container } from "../../components/layouts/Container"
import { FormWrap } from "../../components/layouts/FormWrap"
import { Button } from "../../components/ui-parts"
import useUserRegisterAuthenticationHooks from "../../hooks/useUserRegisterAuthenticationHooks"

const UserRegisterAuthentication = () => {
	const { authButtonHandler, normal, abnormal } = useUserRegisterAuthenticationHooks()
	const {ErrorHandle} = ErrorHandler()
	
	const AuthButtonHandler = async() => {
		const response = await authButtonHandler()
		ErrorHandle(response, normal, abnormal)
	}
	return (
		<Container>
			<FormWrap center>
				<h3 className="bold">ボタンを押すと認証が完了します。</h3>
				<Button variant onClick={AuthButtonHandler}>認証する</Button>
			</FormWrap>
		</Container>
	)
}

export default UserRegisterAuthentication