import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { mixins } from '../../../css/variables/mixins';
import visa from '../../../images/examination_status/credit/visa.png';
import master from '../../../images/examination_status/credit/mastercard.png';
import jcb from '../../../images/examination_status/credit/jcb.gif';
import diners from '../../../images/examination_status/credit/diners.gif';
import amex from '../../../images/examination_status/credit/amex.gif';
import D001 from '../../../images/examination_status/cvs/new_SEVEN.gif';
import D002 from '../../../images/examination_status/cvs/new_lawson.gif';
import D015 from '../../../images/examination_status/cvs/new_Ministop.gif';
import D030 from '../../../images/examination_status/cvs/new_Family.gif';
import D050 from '../../../images/examination_status/cvs/new_Daily.gif';
import D060 from '../../../images/examination_status/cvs/new_Seico.gif';
import Paypay from '../../../images/examination_status/bank/new_Paypay.png';
import Rakuten from '../../../images/examination_status/bank/new_Rakuten.gif';
import SBI from '../../../images/examination_status/bank/new_SBI.png';
import Atobarai from '../../../images/examination_status/atobarai/atobarai.svg';
import { Box } from '../../layouts/Box';
import { FlexBox } from '../../layouts/FlexBox';


const WrapperStyle = styled.div`
  text-align: center;
`
const SubmittedStyle = styled.p`
  font-size: ${fonts.fontSizeRem('s')};
  ${props => props.color ? "background-color:" + colors.themeColor(props.color) + ";" : ""}
  color: ${colors.themeColor('text_white')};
  display: inline-block;
  margin: 0;
  margin-top: 8px;
  padding: 8px 4px;
  width: 108px;
  border-radius: 4px;
`
const CreditStatusWrapperStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  justify-content: center;
  ${mixins.screenUnder()}{
    padding: 0 60px;
  }
`

const CreditStatusItemStyle = styled.p`
  margin: 0;
  color: ${colors.themeColor('text_white')};
  font-size: ${fonts.fontSizeRem('xxs')};
  background-color:${props => props.abled ? colors.themeColor("text_button_secoundary"): colors.themeColor("gray")};
  padding: 4px 12px;
  border-radius: 16px;
  margin-right: 6px;
  margin-top: 12px;
  &:nth-child(n + 4) {
    margin-top: 8px;
  }
`
const ImgDiv = styled.div`
  height:64px;
`
const ImgWrapper = styled.img`
  width: ${props => props.width ? props.width : 100 }px;
  margin-top: ${props => props.mt ? props.mt : 0 }px;
  margin-bottom: ${props => props.mb ? props.mb : 0 }%;
  margin-left:auto;
  margin-right:auto;
`
const BankImgWrapper = styled.div`
  height: 50px;
  width: 200px;

`
const AtobaraiImgWrapper = styled.div`
  height: 100px;
`


const ExaminationContent = ({name, statuses, serviceName, wholeStatus}) => {
  const div = statuses.div_status

  const ContentImg = (name) => {
    switch (name) {
      case 'visa':
        return <ImgWrapper src={visa} mt={24}/>
      case 'master':
        return <ImgWrapper src={master} width={80} mt={12}/>
      case 'jcb':
        return <ImgWrapper src={jcb} width={64} mt={12}/>
      case 'amex':
        return <ImgWrapper src={amex} width={64} mt={8} />
      case 'diners':
        return <ImgWrapper src={diners} width={64} />
      case 'seven':
        return <ImgWrapper src={D001} width={64}/>
      case 'lawson':
        return <ImgWrapper src={D002} width={120} mt={24}/>
      case 'ministop':
        return <ImgWrapper src={D015} width={80} />
      case 'familymart':
        return <ImgWrapper src={D030} width={120} mt={8}/>
      case 'yamazaki':
        return <ImgWrapper src={D050} width={88}/>
      case 'seicomart':
        return <ImgWrapper src={D060} width={120} mt={12}/>
      case 'bank':
        return (
          <>
            <FlexBox responsiveColumn align={'center'} gap={20}>
              <BankImgWrapper>
                <ImgWrapper src={SBI} width={200} />
              </BankImgWrapper>
              <BankImgWrapper>
                <ImgWrapper src={Rakuten} width={200} />
              </BankImgWrapper>
              <BankImgWrapper>
                <ImgWrapper src={Paypay} width={200} />
              </BankImgWrapper>
            </FlexBox>
            <h4 className='font-l mt0'>全国の金融機関</h4>
          </>
        )
      case 'atobarai':
        return (
          <AtobaraiImgWrapper>
            <ImgWrapper src={Atobarai} width={160} />
          </AtobaraiImgWrapper>
        )
      default:
        return <></>
    }
  }

  const ContentStatus = (status) => {
    let statusPhrase = <></>
    switch (status) {
      case 'BEFORE_SUBMITTING':
      case 'SUBMITTED':
      case 'INHOUSE_REVIEW':
      case 'EXTERNAL_REVIEW':
      case 'NOTYET':
      case 'CHECKING':
        statusPhrase = <SubmittedStyle color='gray'>審査中…</SubmittedStyle>
        break;
      case 'OK':
      case 'GO':
        statusPhrase = <SubmittedStyle color='primary_dark'>審査OK</SubmittedStyle>
        break;
      case 'NG':
        statusPhrase = <SubmittedStyle color='background_reject'>審査NG</SubmittedStyle>
        break;
      default:
        statusPhrase = <SubmittedStyle>{status}</SubmittedStyle>
        break;
    }
    if(wholeStatus === 'CANCEL') statusPhrase = <SubmittedStyle color='background_reject'>キャンセル済み</SubmittedStyle>
    if(wholeStatus === 'TERMINATION') statusPhrase = <SubmittedStyle color='background_reject'>解約済み</SubmittedStyle>
    return statusPhrase
  }

  return (
    <WrapperStyle>
      {(name !== 'bank' && name !== 'atobarai') &&
        <ImgDiv>
          {ContentImg(name)}
        </ImgDiv>
      }
      {(name === 'bank' || name === 'atobarai') &&
        ContentImg(name)
      }
      <Box mt={4}/>
      {serviceName !== 'credit' && ContentStatus(statuses)}
      {serviceName === 'credit' && 
        <>
        {ContentStatus(statuses.status)}
          <CreditStatusWrapperStyle>
            <CreditStatusItemStyle abled={div.one && wholeStatus !== 'CANCEL' && wholeStatus !== 'TERMINATION'}>一括</CreditStatusItemStyle>
            <CreditStatusItemStyle abled={div.div && wholeStatus !== 'CANCEL' && wholeStatus !== 'TERMINATION'}>分割</CreditStatusItemStyle>
            <CreditStatusItemStyle abled={div.rivo && wholeStatus !== 'CANCEL' && wholeStatus !== 'TERMINATION'}>リボ</CreditStatusItemStyle>
            <CreditStatusItemStyle abled={div.div2_bonus && wholeStatus !== 'CANCEL' && wholeStatus !== 'TERMINATION'}>2回払い・ボーナス</CreditStatusItemStyle>
          </CreditStatusWrapperStyle>
        </>
      }
    </WrapperStyle>
  )
}

export default ExaminationContent