import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { setCompleteCount, setCountBankAccountData, setCountMerchantData, setCountShopAccountData } from "../function/setCountData"
import { getSession } from "../sessionStorage/sessionStorageMethod"


export const useEntryTopHooks = () => {
    const navigate = useNavigate()
    const agentMasterInfoData = getSession('agent_master')
    const merchantInfoData = getSession('merchant')
    const bankAccountInfoData = getSession('bank_account')
    const representativeInfoData = getSession('representative')
    const operationInfoData = getSession('operation')
    const economicInfoData = getSession('economic_condition')
    const fileInfoData = getSession('files')
    const shopInfoData = getSession('shop')
    const reviewContentsDivisionInfoData = getSession('review_contents_division')
    const ObjToDevide = reviewContentsDivisionInfoData

    //入力完了値表示
    const completeCountData = useMemo(()=>{
        let completeFlags = {merchant:false, bank:false, shop:false}
        const countMerchantData = setCountMerchantData(merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData)
        const countBankAccountData = setCountBankAccountData(bankAccountInfoData,fileInfoData)
        const countShopAccountData = setCountShopAccountData(shopInfoData,fileInfoData,agentMasterInfoData,economicInfoData,ObjToDevide,merchantInfoData)
        const merchantWholeData = Object.keys(countMerchantData).length
        const bankWholeData = Object.keys(countBankAccountData).length
        const shopWholeData = Object.keys(countShopAccountData).length
        const merchantCompleteData = setCompleteCount(countMerchantData)
        const bankCompleteData = setCompleteCount(countBankAccountData)
        const shopCompleteData = setCompleteCount(countShopAccountData)
        if(merchantWholeData === merchantCompleteData) completeFlags.merchant = true
        if(bankWholeData === bankCompleteData) completeFlags.bank = true
        if(shopWholeData === shopCompleteData) completeFlags.shop = true
        let completeCountData = {
            merchant:{
                complete:merchantCompleteData,
                whole:merchantWholeData,
                all_complete_flag: completeFlags.merchant
            },
            bank:{
                complete:bankCompleteData,
                whole:bankWholeData,
                all_complete_flag: completeFlags.bank
            },
            shop:{
                complete:shopCompleteData,
                whole:shopWholeData,
                all_complete_flag: completeFlags.shop
            },
        }
        return completeCountData
    },[ObjToDevide,agentMasterInfoData,bankAccountInfoData,economicInfoData,fileInfoData,merchantInfoData,operationInfoData,representativeInfoData,shopInfoData])

    //ダッシュボード遷移画面
    const moveToDashboard = () => {
        return navigate('/dashboard', {state:{routeTo:'dashboard'}})
    }
    //加盟店情報画面表示
    const moveToEntryMerchant = () => {
        return navigate('/entry/merchant', {state:{routeTo:'entry'}})
    }    
    //口座情報画面表示
    const moveToEntryBankAccount = () => {
        return navigate('/entry/bank_account', {state:{routeTo:'entry'}})
    }    
    //ショップ情報画面表示
    const moveToEntryShop = () => {
        return navigate('/entry/shop', {state:{routeTo:'entry'}})
    }    

    return { moveToEntryMerchant, moveToDashboard, moveToEntryBankAccount, moveToEntryShop,completeCountData }
}