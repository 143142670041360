import React from 'react';
import styled from 'styled-components';
import { Button } from '../../ui-parts/Button';
import { mixins } from '../../../css/variables/mixins';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import useEntrySideHooks from '../../../hooks/useEntrySideHooks';
import useEntryAPIHooks from '../../../hooks/useEntryAPIHooks';

const EntrySideStyle = styled.div`
  ${mixins.screenOver()}{
    position: sticky;
    top:120px;
  }
`;
const EntrySideContentsStyle = styled.div`
    display: flex;
    ${mixins.screenOver()}{
      flex-direction: column;
    }
    ${mixins.screenUnder()}{
      justify-content: space-between;
      align-items: center;
      position:${props => props.fixedPosition ? 'fixed' : 'relative'};
      z-index: 900;
      background: ${props => props.fixedPosition ? colors.themeColor('background_main') : 'none'};
      bottom: 0;
      left: 0;
      width: 100%;
      padding: ${props => props.fixedPosition ? '12px 16px' : '12px 0'};
      box-shadow: ${props => props.fixedPosition ? '0px -4px 4px rgba(0, 0, 0, 0.08)' : 'none'};
    }
`
const EntrySideButtonsStyle = styled.div`
  display: flex;
  gap: 10px;
  ${mixins.screenOver()}{
    margin-top: 10px;
    flex-direction: column;
  }
  ${mixins.screenUnder()}{
    align-items: center;
  }
`
const CompleteCountStyle = styled.div`
  ${mixins.screenOver()}{
    font-size: ${fonts.fontSizeRem('ms')};
  }
  ${mixins.screenUnder()}{
    font-size: ${fonts.fontSizeRem('xxs')};
  }
  .count{
    font-weight: bold;
    ${mixins.screenOver()}{
    font-size: ${fonts.fontSizeRem('xl')};
  }
  ${mixins.screenUnder()}{
    font-size: ${fonts.fontSizeRem('l')};
  }
  }
`;

const ScreenOverSaveButton = styled.div`
  ${mixins.screenUnder()}{
    display:none;
  }
`

const ScreenUnderSaveButton = styled.div`
  ${mixins.screenOver()}{
    display:none;
  }
`


export const EntrySide = ({path}) => {

  const { fixedContents, fixedPosition, completeCount, countPropaty} = useEntrySideHooks();
  const { EntryPostMethod, EntryTemporaryPostMethod, fromConfirmFlag } = useEntryAPIHooks();

  return (
    <EntrySideStyle ref={fixedContents}>
      <EntrySideContentsStyle fixedPosition={fixedPosition}>
        <CompleteCountStyle>
          <span className='count'>{completeCount} / {countPropaty} </span><br /><span>入力完了</span>
        </CompleteCountStyle>
        <EntrySideButtonsStyle>
          {!fromConfirmFlag &&
            <>
              <div>
                <Button variant='secoundary' sizeDesktop='large' fullwidth onClick={EntryTemporaryPostMethod}>一時保存する</Button>
              </div>
              <ScreenOverSaveButton>
                <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>{path === 'shop' ? "保存して確認画面へ" : "保存して次へ"}</Button>
              </ScreenOverSaveButton>
              <ScreenUnderSaveButton>
                <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>{path === 'shop' ? "確認画面へ" : "保存して次へ"}</Button>
              </ScreenUnderSaveButton>
            </>
          }
          {fromConfirmFlag &&
            <div>
              <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>確認画面へ戻る</Button>
            </div>
          }
        </EntrySideButtonsStyle>
      </EntrySideContentsStyle>
    </EntrySideStyle>
  )
}

