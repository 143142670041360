import { useEffect, useState } from "react";
import ErrorBoundary from "../../api/ErrorBoundary";
import useLimitDirectAccess from "../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../hooks/useUserAuthHooks";
import EntryCompleted from "../rederPages/EntryCompleted";
import useGTMHooks from "../../hooks/useGTMHooks";

const EntryCompletedBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks();
    const {AuthCheck} = useUserAuthHooks();
    const {DirectAccessCheck} = useLimitDirectAccess()
    const { pushDataLayerHandler } = useGTMHooks()   
    const [directFlag, setDirectFlag] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      pushDataLayerHandler()
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('entry_completed')) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return
    return(
      <ErrorBoundary>
        <EntryCompleted/>
      </ErrorBoundary>
    )
}

export default EntryCompletedBeforeRender