import { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { PostMethod } from '../api/Api.js';
import { confirmPasswordValidate, mailValidate } from '../validators/publicValidator';
import { setIsLoading } from "../function/setIsLoading.js";
import { Context } from "../stores/Provider.js";
import { passCheck } from "../function/passCheck.js";

const useResetPasswordHooks = () => {
    const [authentication, setAuthentication] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errMsg, setErrMsg] = useState({email:"", password:"", confirm:false});
    const [passCheckState, setPassCheckState] = useState({msg:"", score:0})
    const {dispatch} = useContext(Context)
    const navigate = useNavigate();

    // 変数返却
    const AuthenticationData = authentication;
    const PasswordData = password;
    const ConfirmPasswordData = confirmPassword;
    const ErrMsg = errMsg;

    const sendPassword = async (email, password, confirmPassword) => {
        //バリデーションメッセージリセット
        setErrMsg({email:"", password:""})

        const passValidate = confirmPasswordValidate(password, confirmPassword);
        const emailValidate = mailValidate(email)
        if (!passValidate.isValidate || !emailValidate.isValidate) {
            let confirmFlag = false
            if(passValidate.message === "両方のパスワードを入力してください" || passValidate.message === "再入力されたパスワードと一致しません") confirmFlag = true
            return setErrMsg({email:emailValidate.message, password:passValidate.message,confirm:confirmFlag})
        }

        //データセット
        const data = { 
            "email": email,
            "confirmation_code": AuthenticationData,
            "password": password 
        }
        //ローディング画面開始
        setIsLoading(true, dispatch)

        //API通信
        let response = await PostMethod(data, '/reset_password');
        //認証失敗時システムエラーになってしまうためエラーメッセージをセット
        if(response.code === undefined){
            response.message = '認証コード、またはメールアドレスが間違っています。'
            response.code = 401
        }
        //レスポンスリターン
        return response;
    }

    //正常時遷移先
    const normal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigate('/login')
    }
    //異常時遷移先
    const abnormal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigate('/reset_password', {state:{routeTo:'reset_password'}})
    }

    const setPasswordHandler = (e) => {
        const usedByEmailStr = email !== "" ? email.split('@')[0] : ""
        setPassCheckState(passCheck(e.target.value,[email,usedByEmailStr]))
        return setPassword(e.target.value)
    }
    const setConfirmPasswordHandler = (e) => {
        return setConfirmPassword(e.target.value)
    }
    const setAuthenticationHandler = (e) => {
        //スペースがあれば自動で消去
        e.target.value = e.target.value.replace(' ','').replace('　','')
        return setAuthentication(e.target.value)
    }
    const setEmailHandler = (e) => {
        return setEmail(e.target.value)
    }

    return { email, setEmailHandler, sendPassword, PasswordData, ConfirmPasswordData, setPasswordHandler, setConfirmPasswordHandler, ErrMsg, normal, abnormal, setAuthenticationHandler, passCheckState }
}

export default useResetPasswordHooks