import { entryValidator } from "./publicValidator"


//各申し込み項目のバリデーション内容
export const itemValidators = (div,id,data,state) => {
    const resValidate = {
        isValidate: false,
        message: '',
    }
    const validateList = {
        merchant:{
            merchant_name: [['UpperLimitCount','FullWidth'], 125],
            merchant_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 255],
            merchant_last_name: [['UpperLimitCount','RepresentativeName','OnlyStringFullWidth'], 50],
            merchant_first_name: [['UpperLimitCount','RepresentativeName','OnlyStringFullWidth'], 50],
            merchant_last_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            merchant_first_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            merchant_phone_number_before: [['Int', 'FreePhone', 'UpperLimitCount'], 5],
            merchant_phone_number_middle: [['Int', 'UpperLimitCount'],  4],
            merchant_phone_number_after: [['Int', 'UpperLimitCount'],  4],
            merchant_register_post_code3: [['Int', 'JustCount'], 3],
            merchant_register_post_code4: [['Int', 'JustCount'], 4],
            merchant_address_municipality: [['UpperLimitCount','Municiparity'], 50],
            merchant_address_municipality_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            merchant_address_streetbunch: [['UpperLimitCount','Streetbunch'], 50],
            merchant_address_streetbunch_kana: [['UpperLimitCount', 'StreetbunchKana'], 100],
            merchant_address_building: [['UpperLimitCount','Building'], 50],
            merchant_address_building_kana: [['UpperLimitCount', 'BuildingKana'], 100],
            merchant_website_url: [['UpperLimitCount','URLAlphanumeric', 'URLHttp'], 255],
            merchant_annual_business: [['UpperLimitCount', 'Int'], 11],
            merchant_shop_category: [['UpperLimitCount'], 255],
            merchant_corporate_number: [['Int', 'JustCount'], 13],
            //代表者情報
            representative_last_name: [['UpperLimitCount','RepresentativeName','OnlyStringFullWidth'], 50],
            representative_first_name: [['UpperLimitCount','RepresentativeName','OnlyStringFullWidth'], 50],
            representative_last_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            representative_first_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            //担当者情報
            operator_last_name: [['UpperLimitCount','RepresentativeName','OnlyStringFullWidth'], 50],
            operator_first_name: [['UpperLimitCount','RepresentativeName','OnlyStringFullWidth'], 50],
            operator_last_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            operator_first_name_kana: [['UpperLimitCount', 'HalfWidthKana'], 100],
            operator_email: [['UpperLimitCount', 'Email'], 50],
            operator_phone_number_before: [['Int', 'UpperLimitCount'], 5],
            operator_phone_number_middle: [['Int', 'UpperLimitCount'], 4],
            operator_phone_number_after: [['Int', 'UpperLimitCount'], 4],
            support_operator_email: [['UpperLimitCount', 'Email'], 50],
            support_operator_phone_number_before: [['Int', 'UpperLimitCount'], 4],
            support_operator_phone_number_middle: [['Int', 'UpperLimitCount'],  4],
            support_operator_phone_number_after: [['Int', 'UpperLimitCount'], 4],
            identity_doc_images: [['File']]
        },
        bank_account:{
            bank_account_bank_name: [['BankPulldown'], 0, state],
			bank_account_bank_branch: [['BankBranchPulldown'], 0, state],
			bank_account_number: [['JustCountKeta','Int'], 7],
			bank_account_name: [['UpperLimitCount', 'BankName'], 50],
			passbook_images: [['File']]
        },
        shop:{
            account_id: [['UpperLimitCount','AccountId'],255],
			shop_name: [['UpperLimitCount','ShopName'], 50],
			shop_name_kana: [['UpperLimitCount', 'ShopNameKana'], 100],
			shop_name_alphabet: [['UpperLimitCount', 'ShopNameAlphabet'], 25],
			shop_addition_shop_website_check_url: [['UpperLimitCount','URLAlphanumeric', 'URLHttp'], 255],
			shop_addition_business_deal_url: [['UpperLimitCount','URLAlphanumeric', 'URLHttp'], 255],
			shop_cvs_website_name: [['UpperLimitCount','LegalPersonality','ShopWebsiteCantUseSymbol','OnlyFullWidth'], 12],
			shop_cvs_website_name_kana: [['UpperLimitCount','LegalPersonalityKana','HalfWidthKanaPlusHyphen'], 24],
			shop_website_url: [['UpperLimitCount','URLAlphanumeric', 'URLHttp'], 255],
			shop_addition_price_range_min: [['UpperLimitCount', 'Int'], 11],
			shop_addition_price_range_max: [['UpperLimitCount', 'Int'], 11],
            shop_addition_price_validate: [['ComparePrice'], 0, state],
			shop_addition_product_content_freetext: [['UpperLimitCount', 'FreeText'],  255],
			shop_addition_rental_product_url: [['UpperLimitCount','URLAlphanumeric', 'URLHttp'], 255],
			shop_addition_physical_store_url: [['UpperLimitCount','URLAlphanumeric', 'URLHttp'], 255],
			shop_addition_subscription_code: [['UpperLimitCount'], 255],
			shop_addition_shop_website_id: [['UpperLimitCount'], 255],
			shop_addition_shop_website_password: [['UpperLimitCount'], 255],
			shop_atobarai_customer_notice_store_name: [['UpperLimitCount', 'OnlyFullWidth','AtobaraiCantUseSymbol'], 15],
			antique: [['File']],
			examination_document: [['File']],
			rental: [['File']],
			license: [['File']]
        }
    }
    if(!(id in validateList[div])) return resValidate
    const validatorData = validateList[div][id]
    if(validatorData.length === 1) return entryValidator(validatorData[0],data)
    if(validatorData.length === 2) return entryValidator(validatorData[0],data,validatorData[1]) 
    if(validatorData.length === 3) return entryValidator(validatorData[0],data,validatorData[1],validatorData[2]) 
}