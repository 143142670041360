import styled from 'styled-components';
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';

const ButtonStyle = styled.div`
  display: inline-block;
  button {
    background: ${props => props.disabled ? colors.themeColor('text_disabled') : colors.themeColor('background_button_sub')};
    color: ${colors.themeColor('text_white')};
    border: none;
    cursor: ${props => props.disabled ? "auto" : "pointer"};
    border-radius: 4px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      display: ${props => props.disabled ? "block" : "none"};
      top: 30%;
      left: 15%;
      transform: rotate(45deg);
      width: 5px;
      height: 10px;
      border-bottom: solid 1px #fff;
      border-right: solid 1px #fff;
    }
  }
  .small {
    padding: 4px 6px 4px ${props => props.disabled ? "22px" : "6px"};
    font-size: 10px;
  }
  .medium {
    padding: 4px 12px 4px ${props => props.disabled ? "28px" : "12px"};
    font-size: ${fonts.fontSizeRem('xs')};
  }
  .large {
    padding: 6px 16px 6px ${props => props.disabled ? "32px" : "16px"};
    font-size: ${fonts.fontSizeRem('ms')};
  }
`

export const ButtonSub = ({ children, size, disabled, onClick }) => {
  return (
    <ButtonStyle
      disabled={disabled}
      onClick={onClick}
    >
      <button className={size}>
        {children}
      </button>
    </ButtonStyle>
  );
};

ButtonSub.defaultProps = {
  size: 'medium',
  disabled: false,
}