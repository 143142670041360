import { FormWrap } from '../../layouts/FormWrap';
import { TextField, Button, ErrorMessage } from '../../ui-parts';
import { Box } from '../../layouts/Box';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';

const PassCheckMsgStyle = styled.p`
  color:${props => (props.score === 0 || props.score === 1) ? colors.themeColor('text_error') : colors.themeColor('success') };
  margin-top: 4px;
`


const ResetPasswordElement = ({ setEmailHandler, setPasswordHandler, setConfirmPasswordHandler, sendPasswordHandler, ApiErrMsg, ErrMsg, setAuthenticationHandler, passCheckState }) => {
  return (
    <FormWrap>
      <div className='align_center'>
        <h1 className='mt0 mb0'>パスワード再設定</h1>
      </div>
      <ErrorMessage ApiErrMsg={ApiErrMsg} />
      <p className="mt8 mb0 ml4 align_left bold">確認コードを入力</p>
      <TextField
      id='authentication'
      onChange={setAuthenticationHandler}
      />
      <p className="mt32 ml4 align_left bold">メールアドレスを入力</p>
      <TextField
        iserror={ErrMsg.email}
        id='textfield0'
        fullwidth
        onChange={setEmailHandler} />
      <p className="mt32 ml4 align_left bold">新しいパスワードを設定</p>
      <TextField
        iserror={ErrMsg.password}
        id='textfield1'
        label='半角英数字と英字大文字,記号を含む１２文字以上入力してください'
        type='password'
        fullwidth
        onChange={setPasswordHandler} />
      <PassCheckMsgStyle score={passCheckState.score}>{passCheckState.msg}</PassCheckMsgStyle>
      <TextField
      iserror={ErrMsg.confirm}
        id='textfield2'
        label='もう一度入力してください'
        type='password'
        fullwidth
        onChange={setConfirmPasswordHandler} />
      <Box mt={32}>
        <Button
          variant='primary'
          fullwidth
          onClick={sendPasswordHandler}>
          パスワードを再設定する</Button>
      </Box>
    </FormWrap>
  )
}
export default ResetPasswordElement;