import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { Context } from "../stores/Provider";

const useDashboardHooks = () => {
    const navigate = useNavigate();
    const userInfoData = getSession('user')
    const {state} = useContext(Context)
    const status = state.user_status.status
    const canceledFlag = state.user_status.cancel_flag

    // ステータスをステップごとに分ける
    const step = useMemo(()=>{
        let step;
        switch(status){
            case "ENTRING":
            case "CHECK_NG":
            case "WAITING":
                step = 1;
                break
            case "ENTRY_OK":
            case "CHECKING":
            case "FIXED":
                step = 2;
                break
            case "INHOUSE_REVIEW":
            case "INHOUSE_REVIEW_NG":
                step = 3;
                break
            case "EXTERNAL_REVIEW":
            case "EXTERNAL_REVIEW_NG":
            case "WAITING_FINAL_AGREEMENT":
            case "FIXED_FINAL_AGREEMENT":
                step = 4;
                break
            case "CUTOVER":
                step = 5;
                break
            default :
                step = 1;
                break
        }
        return step
      },[status])

    //始めるボタンの遷移先
    const moveToStart = () => {
        //entry_idがあればentry_topへ遷移
        if(userInfoData.entry_id === null){
            return navigate('/economic_select', {state:{routeTo:'economic_select'}})
        }else if((status === "ENTRING" || status === "WAITING") && userInfoData.entry_id !== null){
            return navigate('/entry_top', {state:{routeTo:'entry_top'}})
        }
        return navigate('/entry_check', {state:{routeTo:'entry_check'}})
    }

    //１つ目のボックスボタンの文言
    const buttonPhrase = useMemo(()=>{
        switch(status){
            case 'ENTRING':
                return '始める'
            case 'WAITING':
                return '編集する'
            default:
                return '確認する'
        }
    },[status])

    //NG時の赤枠発現
    const errorStep = useMemo(()=>{
        let errorStep = 0;
        switch(status){
            case "CHECK_NG":
                errorStep = 2;
                break
            case "INHOUSE_REVIEW_NG":
                errorStep = 3;
                break
            case "EXTERNAL_REVIEW_NG":
                errorStep = 4;
                break
            default:
                errorStep = 0;
                break
        }
        return errorStep
    },[status])

    //利用開始遷移ボタン
    const moveToUseStart = () => {
        return navigate('/use_start', {state:{routeTo:'use_start'}})
    }
    //利用開始注意事項遷移ボタン
    const moveToUseStartPrecautions = () => {
        return navigate('/use_start_precautions', {state:{routeTo:'use_start_precautions'}})
    }

    //審査状況確認画面遷移
    const moveToExamination = () => {
        return navigate('/examination_status', {state:{routeTo:'examination_status'}})
    }


    return { step, status, errorStep, buttonPhrase, moveToStart, moveToUseStart, moveToExamination, canceledFlag, moveToUseStartPrecautions }
}

export default useDashboardHooks;