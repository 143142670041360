import React, { useContext } from "react";
import useLoginHooks from '../../hooks/useLoginHooks';
import { Context } from '../../stores/Provider';
import { LoginElement } from '../../components/ui_elements';
import { Container } from "../../components/layouts/Container";
import { ErrorHandler } from "../../api/ErrorHandler";

const Login = () => {

  const { LoginMethod, EmailData, PasswordData, isClosed, normal, abnormal, setPasswordHandler, setEmailHandler, ErrMsgs } = useLoginHooks();
  const { ErrorHandle } = ErrorHandler()
  const { state } = useContext(Context);
  const ApiErrMsg = state.auth
  //Login実行
  const LoginHandler = async () => {
    const response = await LoginMethod(EmailData, PasswordData);
    //エラーハンドリング
    ErrorHandle(response, normal, abnormal, true)
  }

  return (
    <Container>
      <LoginElement
        LoginHandler={LoginHandler}
        setEmailHandler={setEmailHandler}
        setPasswordHandler={setPasswordHandler}
        ErrMsgs={ErrMsgs}
        ApiErrMsg={ApiErrMsg}
        IsClosed={isClosed}
      />
    </Container>
  )
}

export default Login