import { useContext } from "react";
import { PostMethod } from "../api/Api";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { useNavigate } from "react-router-dom";
import { Context } from "../stores/Provider";
import { setIsLoading } from '../function/setIsLoading';

const usePdfDownloadHooks = () => {
    const navigate = useNavigate()
    const {state, dispatch} = useContext(Context)
    const userInfoData = getSession('user')
    const userStatus = state.user_status.status
    const pdfDownloadHandler = async() => {
        let data = {user_id: userInfoData.user_id}
        setIsLoading(true, dispatch)
        const response = await PostMethod(data,'/entry_info_download')
        if(response.code !== 200){
            if(response.code === 401){
                return logoutAbnormalFunction(response,dispatch,navigate)
            }else{
                return navigate('/system_error') 
            }
        }
        // 日付取得
        const date = new Date()
        const today = date.getFullYear().toString() + (date.getMonth()+1).toString() + date.getDate().toString()
        // ファイル名
        const fileName = 'ZEUSWeb申込システム_'
        //a要素を生成
        let element = document.createElement('a');
        //a要素のhref属性を設定
        element.href = 'data:image/pdf;base64,' + encodeURI(response.response_data.data);
        //a要素のdownload属性を設定
        element.download = fileName.concat(today,'.pdf') ;
        //a要素のtarget属性を設定
        element.target = '_blank';
        //a要素のクリック実行
        element.click();

        setIsLoading(false, dispatch)
    }
    return {pdfDownloadHandler, userStatus}
}

export default usePdfDownloadHooks