import { Link } from "react-router-dom"
import styled from "styled-components"
import { Container } from "../../components/layouts/Container"
import { FormWrap } from "../../components/layouts/FormWrap"
import { Button } from "../../components/ui-parts"
import { colors } from "../../css/variables/colors"
import notFoundImg from "../../images/notFound.svg"
import { mixins } from "../../css/variables/mixins"

const BlueSpan = styled.span`
    color:${colors.themeColor('primary')};
`
const CharacterWrapper = styled.div`
    max-width:300px;
    ${mixins.screenUnder()}{
        margin-top:140px;
    }
    
`
const ImgStyle = styled.img`
    position:absolute;
    top: -32px;
    right: -48px;
    ${mixins.screenUnder()}{
        width:180px;
        top: -160px;
        right: -16px;
    }
`
const NotFound = () => {
    return(
            <Container>
                <FormWrap>
                    <div className='relative'>
                        <CharacterWrapper>
                            <h3>ご指定のページが<br className="all"/>見つかりませんでした。</h3>
                            <h4><BlueSpan>404 </BlueSpan>page not found.</h4>
                            <div className="mb32 mt40">
                                <p className="font-m">アクセスされたページは削除されたか、<br className="all"></br>現在利用できない可能性があります。</p>
                            </div>
                        </CharacterWrapper>
                        <div className="align-center">
                            <Button variant>
                                <Link to={'/dashboard'} state={{routeTo:'dashboard'}} >ダッシュボードへ</Link>
                            </Button>
                        </div>
                        <ImgStyle src={notFoundImg} alt="notFoundImg" />
                    </div>
                </FormWrap>
            </Container>
    )
}

export default NotFound