import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PostMethod } from "../api/Api"
import { getSession, setBankAccountInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setShopInfoData } from "../sessionStorage/sessionStorageMethod";
import { Context } from "../stores/Provider";
import { setIsLoading } from "../function/setIsLoading";
import { paymentSiteChangeToCodeHandler } from "../function/paymentSiteToCode";

const useEntryConfirmHooks = () => {
	
    const navigate = useNavigate();
	//セッションデータ抽出
	const userInfoData = getSession('user')
	const merchantInfoData = getSession('merchant')
	const bankAccountInfoData = getSession('bank_account')
	const representativeInfoData = getSession('representative')
	const operationInfoData = getSession('operation')
	const shopInfoData = getSession('shop')
	const reviewContentsDevideInfoData = getSession('review_contents_division')
	const economicInfoData = getSession('economic_condition')
	const systemSettingInfoData = getSession('system_setting_master')
	const {state, dispatch} = useContext(Context)
	const isOpenModal = state.is_open_modal

    //レスポンスをリコイルにセット
    const setRecoilDataHandler = (response) => {
				let merchantObj = {}
				let representativeObj = {}
				let operatorObj = {}
                let bankObj = {}
				for(let merchantItem in response.response_data.merchant){
					if(merchantItem.split('_')[0] === "merchant"){
						merchantObj = {...merchantObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
					if(merchantItem.split('_')[0] === "representative"){
						representativeObj = {...representativeObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
					if(merchantItem.split('_')[0] === "operator" || merchantItem.split('_')[0] === "support"){
						operatorObj = {...operatorObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
				}
                for(let merchantItem in response.response_data.merchant){
					if(merchantItem.split('_')[0] === "bank"){
						bankObj = {...bankObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
				}
                delete response.response_data.shop.user_id
				delete response.response_data.shop.entry_id

				setMerchantInfoData({...merchantInfoData,...merchantObj})
				setRepresentativeInfoData({...representativeInfoData,...representativeObj})
				setOperationInfoData({...operationInfoData,...operatorObj})
				setBankAccountInfoData({...bankAccountInfoData,...bankObj})
				setShopInfoData({...shopInfoData,...response.response_data.shop })
	}
	
	const setReviewContentsNullHandler = (shopData, deivdeData, merchantData) => {
		const setNameObj = {
			antique_flag: ["shop_addition_antique_flag"],
			btob_flag: ["shop_addition_btob_code"],
			estimate_flag: ["shop_addition_estimated_product_flag"],
			online_local_flag: ["shop_addition_online","shop_addition_offline"],
			prepaid_continuous_flag: ["shop_addition_prepaid_flag"],
			purchase_points_flag: ["shop_addition_point_purchase_flag"],
			real_store_flag: ["shop_addition_physical_store_flag","shop_addition_physical_store_url_flag","shop_addition_physical_store_url"],
			rental_flag: ["shop_addition_rental_flag","shop_addition_rental_product_url"],
			reserve_item_flag: ["shop_addition_reserved_product_flag","shop_addition_reservation_period_flag"],
			subscription_flag: ["shop_addition_subscription_flag","shop_addition_subscription_code"],
		}
		let resultObj = {}
		for(let flagName in setNameObj){
			const shopItemArray = setNameObj[flagName]
			//出し分けフラグがtrueの時、すでに入っているデータを入れる
			if(flagName === 'real_store_flag') continue
			if(deivdeData[flagName]){
				shopItemArray.forEach((itemName)=>{
					const shopItemValue = shopData[itemName]
					resultObj[itemName] = shopItemValue	
				})
			//falseの場合、nullを入れる。
			} else {
				shopItemArray.forEach((itemName)=>{
					resultObj[itemName] = null	
				})
			}	
		}
		//実店舗のみ条件が多いため別処理
		if(deivdeData.real_store_flag && merchantData.merchant_type === '2'){
			setNameObj.real_store_flag.forEach((itemName)=>{
				const shopItemValue = shopData[itemName]
				resultObj[itemName] = shopItemValue	
			})
		} else {
			setNameObj.real_store_flag.forEach((itemName)=>{
				resultObj[itemName] = null	
			})
		}
		return resultObj
	}

	const setEconomicData = () => {
		let economicInsertData = economicInfoData
		let creditSystemInsert = {...economicInfoData.credit.system_setting}
        let bankSystemInsert = {...economicInfoData.bank.system_setting}
        let cvsSystemInsert = {...economicInfoData.cvs.system_setting}
		let creditEconomicInsert = {...economicInfoData.credit.economic_requirement}
        let bankEconomicInsert = {...economicInfoData.bank.economic_requirement}
        let cvsEconomicInsert = {...economicInfoData.cvs.economic_requirement}
        let atobaraiEconomicInsert = {...economicInfoData.atobarai.economic_requirement}
		
		// cgi_url追加
        if(systemSettingInfoData.credit_cgi_agent_flag){
            creditSystemInsert.cgi_url = systemSettingInfoData.credit_cgi_url_agent.replace('{accountId}', shopInfoData.account_id)
        }
        if(systemSettingInfoData.bank_cgi_agent_flag){
            bankSystemInsert.cgi_url = systemSettingInfoData.bank_cgi_url_agent.replace('{accountId}', shopInfoData.account_id)
        }
        if(systemSettingInfoData.cvs_cgi_agent_flag){
            cvsSystemInsert.cgi_url = systemSettingInfoData.cvs_cgi_url_agent.replace('{accountId}', shopInfoData.account_id)
        }
		//支払いサイト変換
        creditEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(creditEconomicInsert.payment_site) 
        bankEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(bankEconomicInsert.payment_site) 
        cvsEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(cvsEconomicInsert.payment_site) 
        atobaraiEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(atobaraiEconomicInsert.payment_site) 

		economicInsertData = {
			...economicInsertData, 
			credit:{
				economic_requirement:creditEconomicInsert, 
				system_setting:creditSystemInsert
			}, 
			bank:{
				economic_requirement:bankEconomicInsert,
				system_setting:bankSystemInsert
			},
			cvs:{
				economic_requirement:cvsEconomicInsert, 
				system_setting:cvsSystemInsert
			},
			atobarai:{
				...economicInsertData.atobarai,
				economic_requirement:atobaraiEconomicInsert
			}
		}
		return economicInsertData
	}


    //ステータスをENTRY_OKに変更して、APIを投げる
    const entryComplete = async() => {
		const setReviewContentsNullData = setReviewContentsNullHandler(shopInfoData,reviewContentsDevideInfoData,merchantInfoData)
		const merchantWebsiteUrl = economicInfoData.cvs.system_setting.select_flag ? merchantInfoData.merchant_website_url : ""
		const shopNameAtobarai = economicInfoData.atobarai.system_setting.select_flag ? (shopInfoData.shop_atobarai_customer_notice_store_name !== "" ? shopInfoData.shop_atobarai_customer_notice_store_name.replaceAll('‐','－') : "") : ""
		const shopCvsWebsiteName = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name : ""
		const shopCvsWebsiteNameKana = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name_kana : ""
		const shopAdditionPriceRangeMax = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_max : null
		const shopAdditionPriceRangeMin = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_min : null
		
        const merchantInsertData = {
            ...merchantInfoData,
            ...representativeInfoData,
            ...operationInfoData,
            ...bankAccountInfoData,
			merchant_website_url: merchantWebsiteUrl
        }
		const shopInsertData = {
			...shopInfoData,
			...setReviewContentsNullData,
			shop_atobarai_customer_notice_store_name:shopNameAtobarai,
			shop_cvs_website_name:shopCvsWebsiteName,
			shop_cvs_website_name_kana:shopCvsWebsiteNameKana,
			shop_addition_price_range_max:shopAdditionPriceRangeMax,
			shop_addition_price_range_min:shopAdditionPriceRangeMin,
			
		}
		const economicInsertData = setEconomicData()
		const postStatus = state.user_status.status === "WAITING" ? "FIXED" : "ENTRY_OK"

        const mainData = {
            ...userInfoData,
            status:postStatus,
            merchant:{
				merchant:merchantInsertData,
				bank:bankAccountInfoData
			},
            shop:shopInsertData,
			services:economicInsertData
        }
		//ローディング画面開始
        setIsLoading(true, dispatch)
        const response = await PostMethod(mainData, '/entry_info')

        return response
    }
    //API正常時処理
    const normal = (response) => {
		setRecoilDataHandler(response)
		//ローディング画面開始
        setIsLoading(false, dispatch)
        return navigate('/entry_completed', {state:{routeTo:'entry_completed'}})
    }
    //API異常時処理
    const abnormal = (response) => {
		dispatch({
			type:"set-modal-flag",
			payload:{
				path : 'confirm',
				type : 'error',
				flag : true,
				message :'申し込み登録が完了できませんでした。'
			}
		})
		//ローディング画面開始
		setIsLoading(false, dispatch)
        return navigate('/entry_confirm', {state:{routeTo:'entry_confirm'}})
    }
	//ダッシュボード遷移ハンドラ
    const navigateDashboardHandler = () => {
        return navigate('/dashboard', {state:{routeTo:'dashboard'}})
    }
    return {isOpenModal,entryComplete,normal,abnormal,navigateDashboardHandler}
}

export default useEntryConfirmHooks