import { EntryStepBox } from '../../ui-parts/EntryStepBox';
import imgStep1 from '../../../images/pct_step01.svg';
import imgStep2 from '../../../images/pct_step02.svg';
import imgStep3 from '../../../images/pct_step03.svg';
import imgStep4 from '../../../images/pct_step04.svg';
import imgStep5 from '../../../images/pct_step05.svg';
import { Button } from '../../ui-parts';
import { Box } from '../../layouts/Box';

const DashboardBoxElement = ({ step, status, moveToStart, moveToUseStart, errorStep, buttonPhrase, moveToExamination, canceledFlag, moveToUseStartPrecautions }) => {
  const disabledFlag = (step !== 3 && step !== 4 && step !== 5) ? true : false;
  return (
    <>
      <EntryStepBox content_number={1} step={step} data_mborder={'2'}>
        <div className='box-top'>
          <div className='box_head'>
            <img src={imgStep1} alt='STEP1' className='step_img' />
            <div>
              {(status === "WAITING") && 
                  <>
                    <div className='title'>再お申し込み</div>
                    <p>お申し込みに不備がありました。不備を修正の上再度お申し込みください。</p>
                  </>
              }
              {status === "ENTRING" && 
                <>
                  <div className='title'>お申し込み</div>
                  <p>お申し込みに必要な情報を入力し、お申し込みください</p>
                </>
              }
              {status === "CHECK_NG" && 
                <>
                  <div className='title'>お申し込み</div>
                  <p>以下からお申し込み情報をご確認いただけます。</p>
                </>
              }
              {(status !== "ENTRING" && status !== "WAITING" && status !== "CHECK_NG")&& 
                <>
                  <div className='title'>お申し込み完了</div>
                  <p>お申し込みが完了しました。お申し込み情報をご確認いただけます。</p>
                </>
              }
            </div>
          </div>
          {(status === "WAITING") && 
            <div className='status'>編集可能</div>
          }
          {status === "ENTRING" && 
            <div className='status'>入力中</div>
          }
          {status === "CHECK_NG" && 
            <div className='status'>確認可能</div>
          }
          {(status !== "ENTRING" && status !== "WAITING" && status !== "CHECK_NG")&& 
            <div className='status'>完了</div>
          }
          
        </div>
        <div className='box-bottom'>
          <Button fullwidth_mobile={true} onClick={moveToStart} disabled={canceledFlag}>{buttonPhrase}</Button>
        </div>
      </EntryStepBox>
      <EntryStepBox content_number={2} step={step} data_mborder={'3'} errorStep={errorStep} canceledFlag={step <= 2 && canceledFlag}>
        <div className='box-top'>
          <div className='box_head'>
            <img src={imgStep2} alt='STEP2' className='step_img' />
            <div>
              <div className='title'>お申し込み内容<br className='display-desktop' />チェック</div>
              <p>お申し込み後、ゼウスにて入力項目のチェックを行います。</p>
            </div>
          </div>
          <div className='duration'>最短２営業日〜</div>
        </div>
      </EntryStepBox>
      <EntryStepBox content_number={3} step={step} data_mborder={'5'} errorStep={errorStep} canceledFlag={(step <= 3 && canceledFlag) || errorStep === 2}>
        <div className='box-top'>
          <div className='box_head'>
            <img src={imgStep3} alt='STEP3' className='step_img' />
            <div>
              <div className='title'>一次審査</div>
              <p>ゼウス内の審査を行います。</p>
            </div>
          </div>
          <div className='duration'>最短３営業日〜</div>
          {((step === 3 && !canceledFlag) && !(status === 'CHECK_NG')) &&
            <>
              <Box mt={8}/>
              <Button size='small' variant={"secoundary"} fullwidth disabled={disabledFlag} onClick={moveToExamination}>審査状況の<br className='display-desktop' />ご確認</Button>
            </>
          }
        </div>
      </EntryStepBox>
      <EntryStepBox content_number={4} step={step} data_mborder={'6'} errorStep={errorStep} canceledFlag={(step <= 4 && canceledFlag) || (errorStep < 4 && errorStep !== 0)}>
        <div className='box-top'>
          <div className='box_head'>
            <img src={imgStep4} alt='STEP4' className='step_img' />
            <div>
              <div className='title'>二次審査</div>
              <p>外部審査を行います。</p>
            </div>
          </div>
          <div className='duration pt8 pb8'><span className='bold'>クレジットカード決済</span><br />最短３営業日〜
          </div>
          <div className='duration mt8 pt8 pb8'><span className='bold'>コンビニ決済</span><br />約1ヶ月半〜
          </div>
          <Box mt={8} />
          {((step === 4 || step === 5) && !canceledFlag && (!(status === 'CHECK_NG'))) &&
            <Button size='small' variant={"secoundary"} fullwidth disabled={disabledFlag} onClick={moveToExamination}>審査状況の<br className='display-desktop' />ご確認</Button>
          }
        </div>
      </EntryStepBox>
      <EntryStepBox content_number={5} step={step} data_mborder={'7'} canceledFlag={(step <= 5 && canceledFlag) || errorStep >= 2}>

        <div className='box-top'>
          <div className='box_head'>
            <img src={imgStep5} alt='STEP5' className='step_img' />
            <div>
              <div className='title'>ご利用開始</div>
              <p>ご利用開始するための手順をご確認いただけます</p>
            </div>
          </div>
        </div>
        {(!canceledFlag && !(status === 'CHECK_NG')) &&
          <div className='mt28'>
            <div>
              <Button fullwidth disabled={status === "CUTOVER" ? false : true} onClick={moveToUseStart} fullwidth_mobile={true}>ご利用開始の手順</Button>
            </div>
            <div className='mt8'>
              <Button fullwidth variant={"secoundary"} size='small' disabled={status === "CUTOVER" ? false : true} onClick={moveToUseStartPrecautions} fullwidth_mobile={true}>ご利用時のご注意事項</Button>
            </div>
          </div>
        }
      </EntryStepBox>
    </>
  )
}

export default DashboardBoxElement