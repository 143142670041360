import React from 'react';
import styled from 'styled-components';
import { colors } from '../../css/variables/colors';

const CardStyle = styled.div`
  ${props => props.sx};
  background: ${colors.themeColor('background_main')};
  border-radius: 8px;
  border: 1px solid ${colors.themeColor('border_box')};
`;
const CardContentStyle = styled.div`
  padding: 20px;
  min-height:260px;
` ;
const CardBottomStyle = styled.div`
  padding:16px 20px;
  border-top: 1px solid ${colors.themeColor('border_box')};
`;


export const Card = ({ sx, children }) => {

  return (
    <CardStyle sx={sx}>
      {children}
    </CardStyle>
  );
};
Card.defaultProps = {
};

export const CardContent = ({ children }) => {
  return (
    <CardContentStyle>
      {children}
    </CardContentStyle>
  );
}
CardContent.defaultProps = {
};

export const CardBottom = ({ children }) => {
  return (
    <CardBottomStyle>
      {children}
    </CardBottomStyle>
  );
}
CardBottom.defaultProps = {
};

