import { useNavigate } from "react-router-dom"
import { FormWrap } from "../../components/layouts/FormWrap"
import { Button } from "../../components/ui-parts"
import systemErrorImg from "../../images/systemError.svg"
import { Footer } from "../../components/layouts/Footer"
import { Header } from "../../components/layouts/Header"
import styled from "styled-components"
import { ContentsWrapper } from "../../components/layouts/ContentsWrapper"
import { useContext } from "react"
import { Context } from "../../stores/Provider"
import { logoutAbnormalFunction } from "../../function/logoutAbnormalFunction"

const ContainerWrap = styled.div`
  display:flex;
  flex-direction:column;
  min-height: 100%;
`

const SystemError = () => {
    const {dispatch} = useContext(Context)
    const response = {code:500}
    const navigate = useNavigate()
    return(
        <ContainerWrap>
            <Header/>
            <ContentsWrapper >
                <FormWrap center>
                    <img src={systemErrorImg} alt="systemErrorImg" />
                    <h3>システムエラーが発生しました</h3>
                    <div className="mb32">
                        <p className="font-m">ご迷惑をおかけしております。</p>
                        <p className="font-m">しばらく時間をおいてからアクセスしてください</p>
                    </div>
                    <Button variant onClick={()=>logoutAbnormalFunction(response,dispatch,navigate)}>ログイン画面へ</Button>
                </FormWrap>
            </ContentsWrapper>
            <Footer/>
        </ContainerWrap>
    )
}

export default SystemError