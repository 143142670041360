/**
 * ページテンプレート
 */
import React,{useContext} from 'react';
import { Container } from '../../components/layouts/Container';
import { EntryNav } from '../../components/ui-parts/EntryNav';
import { EntryNavItem } from '../../components/ui-parts/EntryNavItem';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import useEntryHooks from '../../hooks/useEntryHooks';
import { FlexBox } from '../../components/layouts/FlexBox';
import { BackButton } from '../../components/ui-parts/BackButton';
import { ArrowLink, Button } from '../../components/ui-parts';
import styled from 'styled-components';
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import useEntryAPIHooks from '../../hooks/useEntryAPIHooks';
import { Context } from '../../stores/Provider';
import { Snackbar } from '../../components/ui-parts/Snackbar';
import { mixins } from '../../css/variables/mixins';

const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`;

const BottomButtonWrapper = styled.div`
  width:100%;
  margin-top: 32px;
  ${mixins.screenUnder()}{
    display: none;
  }
`

const ButtonWrapper = styled.div`
  width:270px;
  text-align: center;
  margin-right:auto;
  margin-left:auto;
`

export const Entry = () => {

  const { pageTitle, checkStatus, moveToDashboard } = useEntryHooks();
  const { EntryPostMethod, fromConfirmFlag, path } = useEntryAPIHooks();
  const {state} = useContext(Context)
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate()

  const ErrMsgState = state.entryValidateMessage
  const snackbarIsOpen = state.is_open_modal
  
  return (
    <Container isLogin={true} background={'default'} hasFooterMenu={false} align={'top'} isInquiry>
      <Snackbar open={snackbarIsOpen.flag} message={snackbarIsOpen.message} type={snackbarIsOpen.type} service={snackbarIsOpen.path} />
      <FlexBox sx={{ alignItems: 'center' }} spaceBetween>
        <h1>{pageTitle}</h1>
        <div className='display-desktop'>
          <BackButton onClick={moveToDashboard}>ダッシュボードに戻る</BackButton>
        </div>
      </FlexBox>
        <div className='display-mobile align_right mb12'>
            <BackButton onClick={moveToDashboard}>ダッシュボードに戻る</BackButton>
        </div>
      <p className='mb20'>全ての情報を入力するとお申し込みが可能になります。</p>

      <ArrowLink link='/entry_top' routeTo={'entry_top'}>お申し込み情報TOPへ</ArrowLink>
      
      <EntryNav>
        <EntryNavItem title="お客様情報" icon='shop' status={checkStatus.merchant} isCurrent={pathName === '/entry/merchant' ? true : false} onClick={() => navigate('merchant', {state:{routeTo:'entry'}})}/>
        <EntryNavItem title="口座情報" icon='bank' status={checkStatus.bank} isCurrent={pathName === '/entry/bank_account' ? true : false} onClick={() => navigate('bank_account', {state:{routeTo:'entry'}})}/>
        <EntryNavItem title="ショップ情報" icon='cart' status={checkStatus.shop} isCurrent={pathName === '/entry/shop' ? true : false} onClick={() => navigate('shop', {state:{routeTo:'entry'}})}/>
      </EntryNav>
      {ErrMsgState !== '' && <Error>{ErrMsgState}</Error>}
      <hr />
      <Outlet />
      <BottomButtonWrapper>
        {fromConfirmFlag ?
          <ButtonWrapper>
            <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>確認画面へ戻る</Button>
          </ButtonWrapper>
          :
          <ButtonWrapper>
            <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>{path === 'shop' ? "保存して確認画面へ" : "保存して次へ"}</Button>
          </ButtonWrapper>
        }
      </BottomButtonWrapper>
    </Container>
  );
};
