import { initialState } from "../stores/Provider"
import { setSessionAllData } from "./setInitSessionData"

export const refreshMethods = (dispatch) => {
    //コンテキストをリセット
    const contextRefresh = () => {
        dispatch({
            type:"set-context-data",
            payload:initialState
        })
    }
    //セッションデータ初期値をセット
    setSessionAllData()
    contextRefresh()
}

export const logoutAbnormalFunction = (response,dispatch,navigate) => {
    //セッションデータとコンテキストをリセット
    refreshMethods(dispatch)
    if(response.code === 401) response.message = 'セッションタイムアウト'
    dispatch({
        type: 'set-context-data',
        payload: {
            auth: {
                err_message: response.message,
                code: response.code,
            }
        }
    })
    
    return navigate('/login')
}