import { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../stores/Provider";
import { industryObj, toolChipList } from "../constance/constance"; 
import { PostMethod } from "../api/Api";
import { getSession, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData } from "../sessionStorage/sessionStorageMethod";
import { initMerchantSessionData, initOperationSessionData, initRepresentativeSessionData } from "../constance/sessionData";
import { itemValidators } from "../validators/validationPattern";
import { setIsLoading } from "../function/setIsLoading";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { useNavigate } from "react-router-dom";

const useEntryContentsMerchantHooks = () => {
    //申し込みフォームのデータ管理の仕方（セッションストレージはレンダリングとの相性が悪い＋一時保存ボタンとのデータ同期のため、コンテキストとセッションストレージ両方を使用）
    //⓵画面読み込み時にコンテキストにセッションストレージのデータを保存
    //⓶画面へ返すデータは、コンテキストが取れていない場合、セッションのデータを使用。取れた場合はステイトのデータを使用
    
    const { state, dispatch } = useContext(Context);
    const [postCodeErrMsg, setPostCodeErrMsg] = useState("")
    const navigate = useNavigate()
    //セッションデータ抽出
    const economicInfoData = getSession('economic_condition')
    //バリデーションメッセージ抽出
    const validateErrMsgs = state.validateMessages.merchant
    
    //コンテキスト読み取りまで一時的にセッションデータを読み取る
    const merchantState = useMemo(()=>{
        let data = getSession('merchant')
        if(state.entry.merchant === null || state.entry.merchant === initMerchantSessionData) return data
        data = state.entry.merchant
        return data
    },[state.entry.merchant])

    const representativeState = useMemo(()=>{
        let data = getSession('representative')
        if(state.entry.representative === null || state.entry.representative === initRepresentativeSessionData) return data
        data = state.entry.representative
        return data
    },[state.entry.representative])
    
    const operationState = useMemo(()=>{
        let data = getSession('operation')
        if(state.entry.operation === null || state.entry.operation === initOperationSessionData) return data
        data = state.entry.operation
        return data
    },[state.entry.operation])

    //コンテキストとセッションに同時に保存
    //加盟店
    const setMerchantDataHandler = (data) => {
        const insertData = {...merchantState, ...data}
        setMerchantInfoData(insertData)
        dispatch({
            type : "set-entry-merchant",
            payload : insertData
        })
    }
    //代表者
    const setRepresentativeDataHandler = (data) => {
        const insertData = {...representativeState, ...data}
        setRepresentativeInfoData(insertData)
        dispatch({
            type : "set-entry-representative",
            payload : insertData
        })
    }
    //担当者
    const setOperationDataHandler = (data) => {
        const insertData = {...operationState, ...data}
        setOperationInfoData(insertData)
        dispatch({
            type : "set-entry-operation",
            payload : insertData
        })
    }

    //画面読み込み時処理
    useEffect(()=>{
        //自動スクロールトップ&ローディング画面を非表示
        const scrollWindow = () => {
            window.scrollTo(0,0)
            setIsLoading(false,dispatch)
        }
        //ローディング画面開始
        setIsLoading(true, dispatch)
        setTimeout(scrollWindow,1500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // 契約者情報ラジオボタン
    const radioItemsContractor = [
        { label: '法人', value: '1' },
        { label: '個人', value: '2' },
    ]
    //性別ラジオボタン
    const radioItemsSex = [
        { label: '男性', value: 'M' },
        { label: '女性', value: 'F' },
    ]
    //都道府県配列
    const prefecturesArray = [
        '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都',
        '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府',
        '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県',
        '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
    ]
    //都道府県カナ
    const prefecturesKanaArray = [
        'ﾎｯｶｲﾄﾞｳ', 'ｱｵﾓﾘｹﾝ', 'ｲﾜﾃｹﾝ', 'ﾐﾔｷﾞｹﾝ', 'ｱｷﾀｹﾝ', 'ﾔﾏｶﾞﾀｹﾝ', 'ﾌｸｼﾏｹﾝ', 'ｲﾊﾞﾗｷｹﾝ', 'ﾄﾁｷﾞｹﾝ',
        'ｸﾞﾝﾏｹﾝ', 'ｻｲﾀﾏｹﾝ', 'ﾁﾊﾞｹﾝ', 'ﾄｳｷｮｳﾄ', 'ｶﾅｶﾞﾜｹﾝ', 'ﾆｲｶﾞﾀｹﾝ', 'ﾄﾔﾏｹﾝ', 'ｲｼｶﾜｹﾝ', 'ﾌｸｲｹﾝ',
        'ﾔﾏﾅｼｹﾝ', 'ﾅｶﾞﾉｹﾝ', 'ｷﾞﾌｹﾝ', 'ｼｽﾞｵｶｹﾝ', 'ｱｲﾁｹﾝ', 'ﾐｴｹﾝ', 'ｼｶﾞｹﾝ', 'ｷｮｳﾄﾌ', 'ｵｵｻｶﾌ', 'ﾋｮｳｺﾞｹﾝ',
        'ﾅﾗｹﾝ', 'ﾜｶﾔﾏｹﾝ', 'ﾄｯﾄﾘｹﾝ', 'ｼﾏﾈｹﾝ', 'ｵｶﾔﾏｹﾝ', 'ﾋﾛｼﾏｹﾝ', 'ﾔﾏｸﾞﾁｹﾝ', 'ﾄｸｼﾏｹﾝ', 'ｶｶﾞﾜｹﾝ', 'ｴﾋﾒｹﾝ',
        'ｺｳﾁｹﾝ', 'ﾌｸｵｶｹﾝ', 'ｻｶﾞｹﾝ', 'ﾅｶﾞｻｷｹﾝ', 'ｸﾏﾓﾄｹﾝ', 'ｵｵｲﾀｹﾝ', 'ﾐﾔｻﾞｷｹﾝ', 'ｶｺﾞｼﾏｹﾝ', 'ｵｷﾅﾜｹﾝ',
    ]

    // 都道府県セレクト
    const selectOptionsPrefectures = useMemo(() => {
        const initArray = [
            { label: '都道府県を選択', value: '', disabled: false },
        ]
        prefecturesArray.forEach((value) => {
            initArray.push({ label: value, value: value, disabled: false })
        })
        return initArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // 西暦セレクト
    let today = new Date()
    let leastYear = today.getFullYear() - 18
    let maxYear = today.getFullYear() - 100
    const selectOptionsCE = useMemo(() => {

        const initArray = [
            { label: '選択してください', value: '', disabled: false },
        ]
        for (let i = leastYear; i > maxYear; i--) {
            initArray.push({ label: i, value: i, disabled: false })
        }
        return initArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // 月セレクト
    let leastMonth = today.getMonth() + 1
    const selectOptionsMonth = useMemo(() => {
        const initArray = [
            { label: '選択してください', value: '', disabled: false },
        ]

        //１７歳未満の月を表示しないように
        if (Number(representativeState.representative_birthday_CE) === leastYear) {
            for (let i = 0; i < leastMonth; i++) {
                initArray.push({ label: i + 1, value: i + 1, disabled: false })
            }
        } else {
            for (let i = 0; i < 12; i++) {
                initArray.push({ label: i + 1, value: i + 1, disabled: false })
            }
        }
        return initArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [representativeState.representative_birthday_CE])
    // 日セレクト
    let leastDay = today.getDate()
    const selectOptionsDay = useMemo(() => {
        const initArray = [
            { label: '選択してください', value: '', disabled: false },
        ]
        //１７歳未満の日付を表示しないように
        if (Number(representativeState.representative_birthday_CE) === leastYear && Number(representativeState.representative_birthday_month) === leastMonth) {
            for (let i = 0; i < leastDay; i++) {
                initArray.push({ label: i + 1, value: i + 1, disabled: false })
            }
        } else {
            for (let i = 0; i < 31; i++) {
                initArray.push({ label: i + 1, value: i + 1, disabled: false })
            }
        }
        return initArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [representativeState.representative_birthday_month, representativeState.representative_birthday_CE])

    //業種セレクト
    const industryOption = useMemo(()=>{
        const initArray = [
            { label: '選択してください', value: '', disabled: false },
        ]
        for(let value in industryObj){
            initArray.push({ label: industryObj[value], value: value, disabled: false })
        }
        return initArray
    },[])

    //住所検索ハンドラ
    //⓵郵便番号が両方正しく入力されていることを確認
    //⓶郵便番号をAPIでポストしてレスポンスを取得
    //⓷住所のデータ、apiメッセージをリコイル・ステイトにセット
    const searchAddressHandler = async () => {
        let code3Message = itemValidators('merchant',"merchant_register_post_code3",merchantState.merchant_register_post_code3).message
        let code4Message = itemValidators('merchant',"merchant_register_post_code4",merchantState.merchant_register_post_code4).message
        dispatch({
            type:"set-validate-data",
            payload:{
                ...state.validateMessages,
                merchant:{
                    ...state.validateMessages.merchant,
                    merchant_register_post_code3:{
                        ...state.validateMessages.merchant.merchant_register_post_code3,
                        message:code3Message,
                    },
                    merchant_register_post_code4:{
                        ...state.validateMessages.merchant.merchant_register_post_code4,
                        message:code4Message,
                    }
                }
            }
        })
        setPostCodeErrMsg('')
        if(code3Message !== "" || code4Message !== "") return
        const postCodeData = {
            post_code : merchantState.merchant_register_post_code3 + merchantState.merchant_register_post_code4
        }
        //ローディング開始
        setIsLoading(true, dispatch)
        const response = await PostMethod(postCodeData, '/get_addresses')
        if(response.code !== 200){
            if(response.code === 401) return logoutAbnormalFunction(response, dispatch, navigate)
            return navigate('/system_error')
        }
        //空データが返ってきたとき、エラーメッセージ設定
        if(Object.keys(response.response_data).length === 0){
            const addressData = {
                merchant_address_prefectures : "",
                merchant_address_prefectures_kana : "",
                merchant_address_municipality : "",
                merchant_address_municipality_kana : "",
                merchant_address_streetbunch : "",
                merchant_address_streetbunch_kana : "",
            }
            setMerchantDataHandler(addressData)
            setPostCodeErrMsg(toolChipList.merchant_post_code_empty_error)
            dispatch({
                type:"set-validate-data",
                payload:{
                    ...state.validateMessages,
                    merchant:{
                        ...state.validateMessages.merchant,
                        merchant_register_post_code3:{
                            ...state.validateMessages.merchant.merchant_register_post_code3,
                            message:"",
                        },
                        merchant_register_post_code4:{
                            ...state.validateMessages.merchant.merchant_register_post_code4,
                            message:"",
                        }
                    }
                }
            })
            //ローディング終了
            return setIsLoading(false, dispatch)
        } 
        setPostCodeErrMsg("")
        const addressResponse = response.response_data
        const addressData = {
            merchant_address_prefectures : addressResponse.prefectures,
            merchant_address_prefectures_kana : addressResponse.prefectures_kana,
            merchant_address_municipality : addressResponse.municipality,
            merchant_address_municipality_kana : addressResponse.municipality_kana,
            merchant_address_streetbunch : addressResponse.streetbunch,
            merchant_address_streetbunch_kana : addressResponse.streetbunch_kana,
        }
        
        setMerchantDataHandler(addressData)
        //ローディング終了
        return setIsLoading(false, dispatch)
    }

    //入力時に即座にバリデーションをかける関数
    const onClickValidator = (e) => {
        const validateMessage = itemValidators("merchant",e.target.id,e.target.value)
        dispatch({
            type:"set-validate-merchant",
            payload:{
                ...state.validateMessages.merchant,
                [e.target.id]:validateMessage
            }
        })
    }   

    //店舗情報用リコイルセットハンドラ
    const setMerchantStateHandler = async (e) => {
        onClickValidator(e)
        //都道府県カナ追加
        if (e.target.id === 'merchant_address_prefectures') {
            const index = prefecturesArray.indexOf(e.target.value)
            const kanaData = prefecturesKanaArray[index]
            return setMerchantDataHandler({[e.target.id]: e.target.value, merchant_address_prefectures_kana: kanaData })
        }
        return setMerchantDataHandler({[e.target.id]: e.target.value })
    }
    //モーダル閉じるハンドラ
    const snackbarCloseHandler = (type) => {
        dispatch({
            type : 'set-modal-flag',
            payload : {
                ...state.is_open_modal,
                merchant : {
                    ...state.is_open_modal.merchant,
                    [type] : false
                }
            }
        })
    }
    

    //代表者用リコイルセットハンドラ
    const setRepresentativeStateHandler = async (e) => {
        onClickValidator(e)
        //都道府県カナ追加
        if (e.target.id === 'representative_address_prefectures') {
            const index = prefecturesArray.indexOf(e.target.value)
            const kanaData = prefecturesKanaArray[index]
            return setRepresentativeDataHandler({[e.target.id]: e.target.value, representative_address_kana_prefectures: kanaData })
        }
        return setRepresentativeDataHandler({[e.target.id]: e.target.value })
    }


    //担当者用リコイルステイトハンドラ
    const setOperationStateHandler = (e) => {
        onClickValidator(e)
        return setOperationDataHandler({[e.target.id]: e.target.value })
    }

    //店舗情報ラジオ用リコイルセットハンドラ
    const setRadioMerchantStateHandler = (e) => {
        return setMerchantDataHandler({[e.target.name]: e.target.value })
    }
    //代表者ラジオ用リコイルセットハンドラ
    const setRadioRepresentativeStateHandler = (e) => {
        return setRepresentativeDataHandler({[e.target.name]: e.target.value })
    }

    //お客様電話番号を一つにまとめる
    useEffect(()=>{
        let phoneNumber = merchantState.merchant_phone_number_before + '-' + merchantState.merchant_phone_number_middle + '-' + merchantState.merchant_phone_number_after
        setMerchantDataHandler({merchant_phone_number:phoneNumber})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[merchantState.merchant_phone_number_before,merchantState.merchant_phone_number_middle,merchantState.merchant_phone_number_after])
    
    //担当者電話番号を一つにまとめる
    useEffect(()=>{
        let phoneNumber = operationState.operator_phone_number_before + '-' + operationState.operator_phone_number_middle + '-' + operationState.operator_phone_number_after
        setOperationDataHandler({operator_phone_number:phoneNumber})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[operationState.operator_phone_number_before,operationState.operator_phone_number_middle,operationState.operator_phone_number_after])
    
    //ショップ連絡先電話番号を一つにまとめる
    useEffect(()=>{
        let phoneNumber = operationState.support_operator_phone_number_before + '-' + operationState.support_operator_phone_number_middle + '-' + operationState.support_operator_phone_number_after
        setOperationDataHandler({support_operator_phone_number:phoneNumber})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[operationState.support_operator_phone_number_before,operationState.support_operator_phone_number_middle,operationState.support_operator_phone_number_after])
    
    //運営責任者補完
    useEffect(() => {
        if (merchantState.merchant_type === '2') {
            setOperationDataHandler({
                operator_director_last_name: merchantState.merchant_last_name,
                operator_director_first_name: merchantState.merchant_first_name,
                operator_director_last_name_kana: merchantState.merchant_last_name_kana,
                operator_director_first_name_kana: merchantState.merchant_first_name_kana,
            })
        }
        if (merchantState.merchant_type === '1') {
            setOperationDataHandler({
                operator_director_last_name: representativeState.representative_last_name,
                operator_director_first_name: representativeState.representative_first_name,
                operator_director_last_name_kana: representativeState.representative_last_name_kana,
                operator_director_first_name_kana: representativeState.representative_first_name_kana,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantState.merchant_type, merchantState.merchant_last_name, merchantState.merchant_first_name, merchantState.merchant_last_name_kana, merchantState.merchant_first_name_kana, merchantState.merchant_phone_number, representativeState.representative_last_name, representativeState.representative_first_name, representativeState.representative_last_name_kana, representativeState.representative_first_name_kana])


    return {
        economicInfoData, merchantState, representativeState, operationState, radioItemsContractor, radioItemsSex, selectOptionsPrefectures, selectOptionsCE, selectOptionsMonth,
        selectOptionsDay, industryOption,postCodeErrMsg, snackbarCloseHandler, setRadioMerchantStateHandler, setMerchantStateHandler, setRadioRepresentativeStateHandler, setRepresentativeStateHandler, setOperationStateHandler,searchAddressHandler,
        validateErrMsgs
    }
}

export default useEntryContentsMerchantHooks