
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import { mixins } from '../../css/variables/mixins';
import imgArrowRightOn from '../../images/flow_arrow_right_on.svg';
import imgArrowRightOff from '../../images/flow_arrow_right_off.svg';
import imgArrowBottomOn from '../../images/flow_arrow_bottom_on.svg';
import imgArrowBottomOff from '../../images/flow_arrow_bottom_off.svg';
import imgChecked from '../../images/icon_check_white.svg';
import imgAlertIcon from '../../images/icon_alert.svg';
import imgNgIcon from '../../images/icon_ng_white.svg';
import imgFloWAllowBarRight from '../../images/flow_arrow_bar_right.png';
import imgFloWAllowBarBottom from '../../images/flow_arrow_bar_bottom.png';
import imgMessageTriangle from '../../images/pct_message_triangle.png';

export const MessageBox = styled.div`
  margin-right: -16px;
  position: relative;
  &[data-stepcount='1']::after{
    left : calc(20% - ((20% - 16px) / 2) - 16px);
    }
  &[data-stepcount='2']::after{
    left : calc(40% - ((20% - 16px) / 2) - 16px);
  }
  &[data-stepcount='3']::after{
    left : calc(60% - ((20% - 16px) / 2) - 16px);
  }
  &[data-stepcount='4']::after{
    left : calc(80% - ((20% - 16px) / 2) - 16px);
  }
  &[data-stepcount='5']::after{
    left : calc(100% - ((20% - 16px) / 2) - 16px);
  }
  ${mixins.screenOver()}{
    &::after{
      content:"";
      display: block;
      position: absolute;
      //background-color: #000;
      background-image:url(${imgMessageTriangle});
      background-size: 100% 100% ;
      width: 50px;
      height: 56px;
      bottom: -55px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .inner{
    background:${colors.themeColor('light_yellow')} ;
    margin-right: 16px;
    padding: 16px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${mixins.screenUnder()}{
      text-align: left;
    }
  }
`
export const EntryStep = styled.div`
  background: ${colors.themeColor('background_whitegray')};
  border-color:${colors.themeColor('border_box')};
  border-top: 1px solid ${colors.themeColor('border_box')};
  border-bottom: 1px solid ${colors.themeColor('border_box')};
  &>div{
    max-width:${mixins.contentsWidth()};
    margin-left: auto;
    margin-right: auto;
    padding: 40px 16px;
    ${mixins.screenUnder()}{
      padding: 24px 16px 40px;
    }
  }
`
export const EntryStepList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  ${mixins.screenOver()}{
    flex-wrap: wrap;
    margin-right: -16px;
  }
  ${mixins.screenUnder()}{
    flex-direction: column;
  }
  &>li{
    ${mixins.screenUnder()}{
      &[data_mborder='1']{
        order: 1;
      }
      &[data_mborder='2']{
        order: 2;
      }
      &[data_mborder='3']{
        order: 3;
      }
      &[data_mborder='4']{
        order: 4;
      }
      &[data_mborder='5']{
        order: 5;
      }
      &[data_mborder='6']{
        order: 6;
      }
      &[data_mborder='7']{
        order: 7;
      }
    }
  }
  .step{
    display:flex;
    position: relative;
    &.checked::before{
          content: "";
          display: block;
          width: 32px;
          height: 32px;
          background-color: ${colors.themeColor('success')};
          background-image: url(${imgChecked}) ;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          position: absolute;
          top: -10px;
          left: 16px;
    }
    &.error::before{
      content: "";
      display: block;
      width: 32px;
      height: 32px;
      background-color: ${colors.themeColor('background_error')};
      background-image: url(${imgNgIcon}) ;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      left: 16px;
    }
    ${mixins.screenOver()}{
      padding-right: 16px;
      text-align: center;
      width: 20%;
      position: relative;
      &:not(:nth-child(5))::after{
        content:"";
        display: block;
        width: 10px;
        height: 25px;
        background: url(${imgArrowRightOff});
        position:absolute ;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.checked::after{
        background: url(${imgArrowRightOn});
      }
    }
    ${mixins.screenUnder()}{
      &:not(:nth-child(5)){
        padding-bottom: 24px;
        &::after{
          content:"";
          display: block;
          width: 25px;
          height: 10px;
          background: url(${imgArrowBottomOff});
          position:absolute ;
          bottom:8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &.checked::after{
        background: url(${imgArrowBottomOn});
      }
    }
  }
  .box{
    background: ${colors.themeColor('background_main')};
    border-radius: 8px;
    padding: 4px;
    background: ${colors.themeColor('border_box')};
    height: 100%;
    width: 100%;
  }
  .step.current .box{
    background: ${colors.themeColor('primary_gradetion')};
  }
  .step.error .box{
    background: ${colors.themeColor('error_gradetion')}
  }
  .box_inner{
    padding: 16px 8px;
    background:${colors.themeColor('background_main')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  .box-top{
  }
  .box_head{
    ${mixins.screenUnder()}{
      display: flex;
      gap: 12px;
      align-items: flex-start;
    }
    p{
      margin: 8px 0;
      ${mixins.screenOver()}{
        font-size: ${fonts.fontSizeRem('xs')};
      }
      ${mixins.screenUnder()}{
        font-size: ${fonts.fontSizeRem('s')};
      }
    }
    .title{
      font-size: ${fonts.fontSizeRem('l')};
      font-weight: bold;
    }
    .step_img{
      width: 100%;
      max-width: 150px;
      ${mixins.screenUnder()}{
        width: 80px;
        max-width: 100%;
      }
    }
  }
  .box-bottom{
    margin-top: 12px;
  }
  .status{
    background: ${colors.themeColor('light_yellow')};
    font-size: ${fonts.fontSizeRem('xs')};
    padding: 8px;
    border-top: 1px solid ${colors.themeColor('border_box')};
    border-bottom: 1px solid ${colors.themeColor('border_box')};
    text-align: center;
  }
  .count{
    font-weight: bold;
    font-size:${fonts.fontSizeRem('l')}
  }
  .duration{
    padding: 10px;
    line-height: 1.2;
    background: ${colors.themeColor('background_whitegray')};
    font-size:${fonts.fontSizeRem('xs')};
    text-align: center;
  }
  .step_bar{
    ${mixins.screenOver()}{
      width: 40%;
      padding-right: 16px;
      padding-top: 16px;
    }
    ${mixins.screenUnder()}{
      margin-bottom: 12px;
      padding-bottom: 10px;
    }
  }
  .step_bar_inner{
    background: #6E86A3;
    height:100%;
    position: relative;
    color:${colors.themeColor('text_white')};
    ${mixins.screenOver()}{
      padding: 8px 16px;
      display: flex;
      gap:16px;
      align-items: center;
      .title{
        white-space: nowrap;
      }
      .body{
        flex: 1;
        line-height: 1.4;
        img{
          vertical-align: middle;
        }
      }
      &::after{
        content: "";
        display: block;
        position: absolute;
        right: -10px;
        width:10px;
        height: 100%;
        background-image: url(${imgFloWAllowBarRight});
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    ${mixins.screenUnder()}{
      padding: 8px 12px 12px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      &::after{
        content: '';
        position: absolute;
        background-image: url(${imgFloWAllowBarBottom});
        background-size: 100% 100%;
        display: block;
        bottom: -10px;
        width: 100%;
        height: 10px;
        left: 0;
      }
    }
    .alert{
        padding-left:20px;
        background: url(${imgAlertIcon}) no-repeat left center;
      }
  }
`