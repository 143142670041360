import React from 'react';
import styled from 'styled-components'
import ZeusLogoImg from '../../images/zeus_color_white.png'
import { mixins } from '../../css/variables/mixins';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';
import iconArrowLeft from '../../images/icon_breadcrumbs_arrowleft.svg';
import { Menu } from '../ui-parts';
import createImg from '../../images/icon_create_inquiry.svg';
import { FlexBox } from './FlexBox';
const StyledHeader = styled.header`
  position: sticky;
  z-index: 900;
  top: 0;
  background: ${colors.themeColor('background_main')};
`;
const HeaderBar = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-bottom:2px solid ${colors.themeColor('primary')};
`
const ZeusLogo = styled.img`
  width:104px;
  margin-right:24px;
  display: block;
  ${mixins.screenUnder()} {
    width:78px;
    margin-right: 8px;
  }
`
const TitleLogo = styled.div`
  display: flex;
  align-items: center;
`
const TitleText = styled.div`
  font-size:${fonts.fontSizeRem('m')};
  font-weight: bold;
  ${mixins.screenOver()}{
    padding-left: 24px;
    border-left: 1px solid ${colors.themeColor('border_box')};
  }
  ${mixins.screenUnder(767)} {
    font-size:${fonts.fontSizeRem('xs')};
  }
`
const BreadCrumbs = styled.div`
  position:relative;
  height: 44px;
  padding: 0 16px;
  font-size:${fonts.fontSizeRem('xs')};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.themeColor('border_box')};
  span{
    display: inline-block;
    padding-right:28px ;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      display: block;
      right: 0;
      width: 28px;
      height: 12px;
      background: url(${iconArrowLeft}) no-repeat center;
      top:50% ;
      transform: translateY(-50%);
    }
    &:last-child::after{
      content:none;
    }
  }
`
const CreateInquiryBtnStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background: ${props => props.canceledFlag ? colors.themeColor('background_gray') : colors.themeColor('primary') };
  padding: 4px 0;
  border-radius: 8px;
  margin-left: 16px;
  cursor: pointer;
  position:absolute;
  right:16px;
  top:8px;
  z-index:200;
  border: none;
`
const CreateInquiryBtnIconStyle = styled.div`
  width: 12.15px;
  height: 12.15px;
  background: url(${createImg}) no-repeat center;
`
const CreateInquiryBtnTextStyle = styled.div`
  margin-left: 5.57px;
  color: ${props => props.canceledFlag ? colors.themeColor('text_disabled') : colors.themeColor('text_white') };
`
export const Header = ({ isLogin, ModalFlag, modalOpenHandler, canceledFlag, closeState }) => (
  <StyledHeader>
    <HeaderBar>
      <TitleLogo>
        <ZeusLogo src={ZeusLogoImg} alt="株式会社ゼウス" />
        <TitleText className='mr24'>Web申込システム</TitleText>
        {isLogin && closeState.confirm_flag && 
          <TitleText className='display-desktop red_text'>{closeState.confirm_date}にこちらの申し込み画面がご利用いただけなくなります。</TitleText>
        }
      </TitleLogo>
      {isLogin ? (
        <Menu />
      ) : (
        <>
        </>
      )}
    </HeaderBar>
    {isLogin ? (
      <BreadCrumbs>
        {(isLogin && !ModalFlag) &&
          <CreateInquiryBtnStyle onClick={modalOpenHandler} canceledFlag={canceledFlag} disabled={canceledFlag}>
            <CreateInquiryBtnIconStyle />
            <CreateInquiryBtnTextStyle>お問い合わせはこちら</CreateInquiryBtnTextStyle>
          </CreateInquiryBtnStyle>
        }
      </BreadCrumbs>
    ) : (
      <>
      </>
    )
    }

  </StyledHeader>
);

 Header.defaultProps = {
  isLogin : false
};
