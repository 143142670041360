import { useEffect, useState } from "react";
import useLimitDirectAccess from "../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../hooks/useUserAuthHooks";
import UseStart from "../rederPages/UseStart";
import useGTMHooks from "../../hooks/useGTMHooks";

const UseStartBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks();
    const {AuthCheck} = useUserAuthHooks();
    const {DirectAccessCheck} = useLimitDirectAccess()
    const [directFlag, setDirectFlag] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    const { pushDataLayerHandler } = useGTMHooks()
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      pushDataLayerHandler()
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('use_start')) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return
    return(
        <UseStart/>
    )
}

export default UseStartBeforeRender