import { useContext } from "react";
import { useNavigate } from 'react-router-dom'
import { Context } from '../stores/Provider';
import { setIsLoading } from "../function/setIsLoading";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";

export const ErrorHandler = () => {

    const { state, dispatch } = useContext(Context);
    const navigate = useNavigate();

    const ErrorHandle = (response, normal, abnormal, userOperate) => {
        if ( response === undefined ) { return }
        //200only
        if ( response.code === 200 ) {
            dispatch({
                type: 'set-context-data',
                payload: {
                    auth: {
                        err_message: '',
                        code: response.code
                    }
                }
            })
            normal(response)
            setIsLoading(false, dispatch)
            //400台
        } else if ( 400 <= response.code && response.code < 500 ) {
            if(response.code === 401){
                //stateにコードを登録
                dispatch({
                    type: 'set-context-data',
                    payload: {
                        auth: {
                            err_message: response.message,
                            code: response.code,
                        }
                    }
                })
                if(!userOperate){
                    setIsLoading(false, dispatch)
                    return logoutAbnormalFunction(response,dispatch,navigate)
                }
            }
            abnormal(response);
            setIsLoading(false, dispatch)
        } else {
            navigate('/system_error')
        }
    }
    return {ErrorHandle, state}
}