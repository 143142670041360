import { Container } from "../../components/layouts/Container"
import EconomicEstimateElement from "../../components/ui_elements/Economic/EconomicEstimateElement"

const EconomicEstimate = () => {

	return (
		<Container isLogin={true} background={'default'} hasFooterMenu={false} align={'top'}>
			<EconomicEstimateElement/>
		</Container>
	)
}

export default EconomicEstimate