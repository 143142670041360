import { Routing } from './route/Routing';

const App = () => {
  

  return (
      <Routing />
  );
}


export default App