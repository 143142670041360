import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { mixins } from '../../css/variables/mixins';

const Wrapper = styled.div`
  background:${props => props.background==='white' ? 'white' : colors.themeColor('background_whitegray')};
  flex-grow:1;
  display: flex; 
  align-items: ${props => props.align==='top' ? 'flex-start' : 'center'};
  &>div{
    padding: 40px ${props => props.fullwidth ? '0' : '16px'} 60px;
    margin: 0 auto;
    width:100%;
    max-width: ${props => props.fullwidth ? '100%' : mixins.contentsWidth()};
    ${mixins.screenUnder()}{
      padding: 24px ${props => props.fullwidth ? '0' : '16px'} 40px;
    }
  }
`

export const ContentsWrapper = ({ background, align, fullwidth, children }) => (
  <Wrapper background={background} align={align} fullwidth={fullwidth}>
    <div>
    {children}
    </div>
  </Wrapper>
);

ContentsWrapper.defaultProps = {
  background: 'default',
  align:'center',
  fullwidth:false
};
