import { useNavigate } from "react-router-dom"
import { getSession } from "../sessionStorage/sessionStorageMethod";

const useServiceTypeElmHooks = () => {
    const navigate = useNavigate();
    //セッションデータ抽出
    const economicInfoData = getSession('economic_condition')
    const economicDevidedInfoData = getSession('economic_devided')
    const navigateEconomicSelect = () => {
        return navigate('/economic_select', {state:{routeTo:'economic_select'}})
    }
    return {navigateEconomicSelect,economicInfoData,economicDevidedInfoData}
}
export default useServiceTypeElmHooks