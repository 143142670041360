import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../stores/Provider";
import { GetMethod } from "../api/Api";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { initExaminationStatusContext } from "../constance/constance";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { useNavigate } from "react-router-dom";

const useExaminationStatusHooks = () => { 
    const { dispatch } = useContext(Context);
    const navigate = useNavigate()

    //セッションデータ抽出
	const userInfoData = getSession('user')
    const [errMsg] = useState("")
    const [serviceCodeList, setServiceCodeList] = useState("credit")
    const [ExaminationStatusContext, setExaminationStatusContext] = useState(initExaminationStatusContext)

    const [isService, setIsService] = useState({
        credit: {
            status: false,
            array: [],
        }, 
        cvs: {
            status: false,
            array: [],
        }, 
        bank: {
            status: false,
            array: [],
        }, 
        atobarai: {
            status: false,
            array: [],
        }, 
    })
    const IsService = isService;

    //審査ステータス情報取得
    const getExaminationStatus = useCallback(async() => {
        let serviceData = {}
        const data = { entry_id: userInfoData.entry_id }
        let {code, response_data} = await GetMethod(data, '/status')
        const response = {code:code}
        if(code !== 200){
            if(code === 401) return logoutAbnormalFunction(response,dispatch,navigate)
            return navigate('/system_error')
        } 

        setServiceCodeList(response_data.entry.service_code_list)
        setExaminationStatusContext(response_data)
        dispatch({
            type: 'set-context-data',
            payload: {
                examination_status: response_data
            }
        })
        const serviceArray = ['credit', 'cvs', 'bank', 'atobarai']
        const creditOrderArray = ['visa','master','jcb','amex','diners']
        const cvsOrderArray = ['seven', 'lawson','familymart','ministop','yamazaki','seicomart']
        
        serviceArray.forEach((key) => {
            let arrayData
            const IsFlag = key in response_data.entry.service
            if(IsFlag){
                if(key !== 'bank' || key !== 'atobarai'){
                    if(key === 'cvs'){
                        let cvsData = []
                        arrayData = Object.entries(response_data.entry.service.cvs.use_cvs)
                        cvsOrderArray.forEach((orderKey)=>{
                            arrayData.forEach((cvsKey)=>{
                                if(orderKey !== cvsKey[0]) return
                                if(orderKey === "lawson") arrayData.push(['ministop', cvsKey[1]])
                                cvsData.push([cvsKey[0],cvsKey[1]])
                            })
                        })
                        arrayData = cvsData
                    }
                    if(key === 'credit'){
                        let creditData = []
                        arrayData = Object.entries(response_data.entry.service[key].use_brand)
                        creditOrderArray.forEach((orderKey)=>{
                            arrayData.forEach((creditKey)=>{
                                if(orderKey !== creditKey[0]) return
                                creditData.push([creditKey[0],creditKey[1]])
                            })
                        })
                        arrayData = creditData
                    }   
                }else {
                    arrayData = []
                }
            }else {
                arrayData = []
            }
            serviceData[key] = {statuses: IsFlag, array: arrayData, status: response_data.entry.service[key].status}
        });
        setIsService(serviceData)
    },[dispatch,navigate,userInfoData.entry_id])


    //画面読み込み時処理
    useEffect(() => {
        getExaminationStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { ExaminationStatusContext, IsService, serviceCodeList, errMsg, navigate }
}

export default useExaminationStatusHooks

