import { Container } from "../../components/layouts/Container"
import { Button } from "../../components/ui-parts"
import { Link } from "react-router-dom"
import { FormWrap } from "../../components/layouts/FormWrap"
import VectorImg from "../../images/Vector.svg"

const FinalAgreementCompleted = () => {
  return (
    <Container>
      <FormWrap center>
        <img src={VectorImg} alt="finalAgreementImg" />
        <h3>合意を確認いたしました</h3>
        <div className="mb32">
          <p className="font-m">ご利用の準備完了まで今しばらくお待ちください。</p>
        </div>
        <Button variant>
          <Link to={'/dashboard'} state={{ routeTo: 'dashboard' }} >ダッシュボードに戻る</Link>
        </Button>
      </FormWrap>
    </Container>
  )
}

export default FinalAgreementCompleted