const setResult = (countData,unionData) => {
    let resultData = {}
    countData.forEach((value)=>{
        for(let key in unionData){
            if(key === value){
                resultData = {...resultData, [key]:unionData[key]}
            }
        }
    })
    return resultData
}

export const setCountMerchantData = (merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData) => {
    let unionData = {
        ...merchantInfoData,
        ...representativeInfoData,
        ...operationInfoData,
        ...fileInfoData,
        ...agentMasterInfoData,
    }
    
    let countData = [
        'merchant_type','merchant_phone_number_before','merchant_phone_number_middle','merchant_phone_number_after','merchant_register_post_code3','merchant_register_post_code4','merchant_address_prefectures','merchant_address_municipality','merchant_address_municipality_kana','merchant_address_streetbunch','merchant_address_streetbunch_kana','merchant_annual_business','merchant_shop_category','operator_last_name','operator_last_name_kana','operator_first_name','operator_first_name_kana','operator_email','operator_phone_number_before','operator_phone_number_middle','operator_phone_number_after','support_operator_email','support_operator_phone_number_before','support_operator_phone_number_middle','support_operator_phone_number_after',
    ]
    if(merchantInfoData.merchant_type === '1'){
        countData.push(
            'merchant_name','merchant_name_kana','merchant_corporate_number','representative_last_name','representative_first_name','representative_last_name_kana','representative_first_name_kana','representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day',
        )
    } else if (merchantInfoData.merchant_type === '2'){
        countData.push(
            'identity_doc_images','merchant_last_name','merchant_first_name','merchant_last_name_kana','merchant_first_name_kana','representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day'
        )
    }
    
    const resultData = setResult(countData,unionData)
    return resultData
}

export const setCountBankAccountData = (bankAccountInfoData,fileInfoData) => {
    const unionData = {
        ...bankAccountInfoData,
        ...fileInfoData,
    }
    let countData = [
        'passbook_images','bank_account_bank_name','bank_account_bank_branch','bank_account_category','bank_account_number','bank_account_name',
    ]
    const resultData = setResult(countData,unionData)
    return resultData
}

export const setCountShopAccountData = (shopInfoData,fileInfoData,agentMasterInfoData,economicInfoData,ObjToDevide,merchantInfoData) => {
    const unionData = {
        ...shopInfoData,
        ...fileInfoData,
    }
    let countData = [
        'shop_name','shop_name_kana','shop_name_alphabet','shop_website_url','shop_addition_site_status_code','shop_addition_conduct_start_yyyy','shop_addition_conduct_start_mm','shop_addition_product_content_code','shop_addition_major_content_code','shop_addition_medium_content_code','shop_addition_product_content_freetext','shop_addition_available_product_flag','shop_addition_non_infringing_product_presence_absence_flag','shop_addition_non_administrative_penalties_five_year_flag','shop_addition_non_losing_judgment_flag'
    ]
    if(agentMasterInfoData.account_id_flag){
        countData.push('account_id')
    }
    if(shopInfoData.shop_addition_site_status_code === 0){
        countData.push('shop_addition_site_status_method')
        if(shopInfoData.shop_addition_site_status_method === 0){
            countData.push('shop_addition_business_deal_url','shop_addition_shop_website_check_url')
        }else if(shopInfoData.shop_addition_site_status_method === 1){
            countData.push('examination_document')
        }
    }
    if(economicInfoData.cvs.system_setting.select_flag){
        countData.push('shop_cvs_website_name','shop_cvs_website_name_kana','shop_addition_price_range_min','shop_addition_price_range_max')
    }
    if(economicInfoData.atobarai.system_setting.select_flag){
        countData.push('shop_atobarai_customer_notice_store_name')
    }
    if(ObjToDevide.antique_flag){
        countData.push('shop_addition_antique_flag',)
        if(shopInfoData.shop_addition_antique_flag){
            countData.push('antique',)
        }
    }
    if(ObjToDevide.subscription_flag){
        countData.push('shop_addition_subscription_flag',)
        if(shopInfoData.shop_addition_subscription_flag){
            countData.push('shop_addition_subscription_code')
        }

    }
    if(ObjToDevide.purchase_points_flag){
        countData.push('shop_addition_point_purchase_flag',)
    }
    if(ObjToDevide.prepaid_continuous_flag){
        countData.push('shop_addition_prepaid_flag',)
    }
    if(ObjToDevide.reserve_item_flag){
        countData.push('shop_addition_reserved_product_flag',)
        if(shopInfoData.shop_addition_reserved_product_flag){
            countData.push('shop_addition_reservation_period_flag')
        }
    }
    if(ObjToDevide.estimate_flag){
        countData.push('shop_addition_estimated_product_flag',)
    }
    if(ObjToDevide.rental_flag){
        countData.push('shop_addition_rental_flag')
        if(shopInfoData.shop_addition_rental_flag){
            //shop_addition_rental_product_urlはAPIHooksのみ（EntrySideHooksには追加なし）
            countData.push('shop_addition_rental_validate',)
        }
    }
    if(ObjToDevide.online_local_flag){
        countData.push('shop_addition_offer_place',)
    }
    if(ObjToDevide.btob_flag){
        countData.push('shop_addition_btob_code',)
    }
    if(ObjToDevide.real_store_flag && merchantInfoData.merchant_type === "2"){
        countData.push('shop_addition_physical_store_flag')
        if(shopInfoData.shop_addition_physical_store_flag){
            countData.push('shop_addition_physical_store_url_flag')
            if(shopInfoData.shop_addition_physical_store_url_flag){
                countData.push('shop_addition_physical_store_url')
            }
        }
    }
    const resultData = setResult(countData,unionData)
    return resultData
}

export const setCompleteCount = (entryDataByPages) => {
    const keys = Object.keys(entryDataByPages)
    let counter = 0;
    keys.forEach((key) => {
        if(entryDataByPages[key] ===  null) return 
        //同意事項とサービスの提供場所とレンタルのみ例外処理
        if(key ===  'shop_addition_available_product_flag' ||key ===  'shop_addition_non_infringing_product_presence_absence_flag' || key === 'shop_addition_offer_place' || key === 'shop_addition_rental_validate'){
            entryDataByPages[key] === true && counter++
            return
        }
        typeof(entryDataByPages[key]) === 'string' && entryDataByPages[key] !== '' && counter++
        typeof(entryDataByPages[key]) === 'object' && entryDataByPages[key].length !== 0 && counter++
        typeof(entryDataByPages[key]) === 'number' && entryDataByPages[key].length !== 0 && counter++
        typeof(entryDataByPages[key]) === 'boolean' && counter++
    })
    return counter
} 

export const countAllKeys = (entryDataByPages) => {
    return Object.keys(entryDataByPages).length
}