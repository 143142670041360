import { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../stores/Provider";
import { GetMethod, PostMethod } from "../api/Api";
import { slideToggle } from "../function/accordion";
import { entryValidator } from "../validators/publicValidator";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { setIsLoading } from "../function/setIsLoading";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { useNavigate } from "react-router-dom";

const useInquiryItemHooks = (accordionOpen, id, navigation, inquiryComment) => {
    const { state, dispatch } = useContext(Context);
    const userInfoData = getSession('user')
    const operationInfoData = getSession('operation')
    const navigate = useNavigate()

    const emailData = userInfoData.email
    // アコーディオン開閉State
    const[itemToggle, setItemToggle] = useState(false);
    const ItemToggle = itemToggle;
    const ToggleRef = useRef();
    const NavToggleRef = useRef()
    const canceledFlag = state.user_status.cancel_flag

    // アコーディオンState変更関数
    const itemToggleChangeHandler = () => {
        let slideTime = 500
        if(navigation) slideTime = 0
        setItemToggle(prev => !prev);
        if(!navigation) slideToggle(ToggleRef.current, slideTime)
        if(navigation) slideToggle(NavToggleRef.current, slideTime)
    };
    // ファイルState
    const[fileData, setFileData] = useState([])
    const FileDatas = fileData

    // textareaのheight自動調整State
    const[scrollHeight, setScrollHeight] = useState(118);
    const ScrollHeight = scrollHeight;
    const TextareaRef = useRef();

    // コメント登録用State
    const [commentRegister, setCommentRegister] = useState('');

    // コメントバリデーションState
    const [commentRegisterErrMsg, setCommentRegisterErrMsg] = useState('');
    const CommentRegisterErrMsg = commentRegisterErrMsg;

    // height自動調整State変更関数
    const scrollHeightChangeHandler = () => {
        setScrollHeight(TextareaRef.current.scrollHeight);
        setCommentRegister(TextareaRef.current.value)
    };
    
    // コメント登録処理
    const commentRegisterHandler = async (ticket_id,files) => {
        if(commentRegister === '') return setCommentRegisterErrMsg('コメントを入力してください');
        const fileValidateMessage = entryValidator(['File'],files).message
        if(fileValidateMessage !== "") return setCommentRegisterErrMsg(fileValidateMessage)
        setCommentRegisterErrMsg('');
        const reqData = {
            "entry_id": userInfoData.entry_id,
            "ticket_id": ticket_id,
            "comment": commentRegister,
            "created_by": {
                "name" : 'merchant', // ユーザー側からの登録のため固定
                "email" : operationInfoData.operator_email
            }
                
        }
        if(files.length !== 0){
            reqData.files = {}
            reqData.files.comment = files
        }
        //ローディング画面表示
        setIsLoading(true, dispatch)
        const ticketsResponse = await PostMethod(reqData, '/tickets')
        if(ticketsResponse.code !== 200){
            if(ticketsResponse.code === 401){
                logoutAbnormalFunction(ticketsResponse,dispatch,navigate)
            } else {
                // setCommentRegisterErrMsg(ticketsResponse.message)
                navigate('/system_error')
            }
            return setIsLoading(false, dispatch)
        } 
        setCommentRegister('')
        setCommentRegisterErrMsg('')
        TextareaRef.current.value = '';
        //ファイル消去
        setFileData([])
        //コメント再取得
        const commentsArray = []
        const inquiryData = state.inquiry.inquiry_all_data
        await Promise.all(inquiryData.map( async (data, i) => {
            const getReqData = { entry_id: userInfoData.entry_id,ticket_id: data.ticket_id }
            const commentResponse = await GetMethod(getReqData, '/tickets/comments');
            if(commentResponse.code === 200){
                commentsArray.push(commentResponse.response_data.comments)
            } else if(commentResponse.code === 401) {
                return logoutAbnormalFunction(commentResponse,dispatch,navigate)
            } else {
                return navigate('/system_error')
            }
            commentsArray.sort((a,b)=>{
                if(a[0].created_at < b[0].created_at){
                    return 1
                } else {
                    return -1
                }
            })
        }));
        dispatch({
            type: "set-inquiry-comment-data",
            payload: commentsArray
        });
        //ローディング画面非表示
        setIsLoading(false, dispatch)
    };

    // モーダルの自動オープン
    const[isFlag, setIsFlag] = useState(false)

    useEffect(() => {
        if(id === accordionOpen) {
            setIsFlag(true)
            setItemToggle(true)
        }else {
            setIsFlag(false)
            setItemToggle(false)
        }
    }, [accordionOpen,id])

    return { 
        ItemToggle,
        itemToggleChangeHandler,
        ToggleRef,
        NavToggleRef,
        ScrollHeight,
        scrollHeightChangeHandler,
        TextareaRef,
        commentRegisterHandler,
        isFlag,
        emailData,
        CommentRegisterErrMsg,
        FileDatas,
        setFileData,
        canceledFlag
    }
};

export default useInquiryItemHooks;