/**
 * エントリートップ
 */
import React, { useContext } from 'react';
import { mixins } from '../../css/variables/mixins';
import { Container } from '../../components/layouts/Container';
import { FlexBox } from '../../components/layouts/FlexBox';
import { Box } from '../../components/layouts/Box';
import { BackButton } from '../../components/ui-parts/BackButton';
import { ServiceTypeElm } from '../../components/ui_elements/EntryTop/ServiceTypeElm';
import { Card, CardContent, CardBottom } from '../../components/ui-parts/Card';
import { EntryIcons } from '../../components/ui-parts/EntryIcons';
import { Button } from '../../components/ui-parts/Button';
import { Typography } from '../../components/ui-parts/Typography';
import { EntryListWrapper } from '../../components/layouts/EntryListWrapper';
import { EntryTopHeadSection, EntryTopHeadRead, EntryTopHeadButtons, EntryTopHeadServices } from '../../components/layouts/EntryTopHeadSection';
import { useEntryTopHooks } from '../../hooks/useEntryTopHooks';
import { ErrorMessage } from '../../components/ui-parts';
import { Context } from '../../stores/Provider';
import { entryTopPhrases } from '../../constance/constance';

export const EntryTop = () => {
  const { completeCountData, moveToEntryMerchant, moveToDashboard, moveToEntryBankAccount, moveToEntryShop } = useEntryTopHooks();

  const { state } = useContext(Context);
  const ApiErrMsg = state.auth.err_message
  return (
    <Container isLogin={true} background={'default'} hasFooterMenu={false} align={'top'} isInquiry>
      <FlexBox sx={{ alignItems: 'center' }} spaceBetween>
        <h1>お申し込み情報</h1>
        <div className='display-desktop'>
          <BackButton onClick={moveToDashboard}>ダッシュボードに戻る</BackButton>
        </div>
      </FlexBox>
        <div className='display-mobile align_right'>
          <BackButton onClick={moveToDashboard}>ダッシュボードに戻る</BackButton>
        </div>

      <EntryTopHeadSection>
        <EntryTopHeadRead>
          <Typography sizeDesktop={'xxl'} sizeMobile={'l'} bold>お申し込みに必要な情報を入力しましょう</Typography>
          <p className='mt4'>全ての情報を入力するとお申し込みが可能になります。</p>
        </EntryTopHeadRead>
        <EntryTopHeadServices>
          <ServiceTypeElm />
        </EntryTopHeadServices>
        <EntryTopHeadButtons>
          <FlexBox align={'center'} sx={mixins.screenUnder() + '{flex-direction:column}'}>
            <Box sx={mixins.screenUnder() + '{width:100%;}'}>
              <Button size='large' onClick={moveToEntryMerchant} fullwidth>入力を始める</Button>
            </Box>
          </FlexBox>
        </EntryTopHeadButtons>
      </EntryTopHeadSection>
      <ErrorMessage ApiErrMsg={ApiErrMsg} />

      <hr />

      <EntryListWrapper>
        <Card>
          <CardContent>
            <FlexBox>
              <EntryIcons icon={'shop'} />
              <Typography size={'xl'} bold>お客様情報</Typography>
            </FlexBox>
            <Box mt={16}><span className='font-ms'>{entryTopPhrases.merchant}</span></Box>
          </CardContent>
          <CardBottom>
            <FlexBox align={'center'}>
              <Button variant={'secoundary'} onClick={moveToEntryMerchant}>入力する</Button>
              <Typography size={'xs'} color={completeCountData.merchant.all_complete_flag ? 'success' : 'text_main'}>{completeCountData.merchant.complete}/{completeCountData.merchant.whole} 入力完了</Typography>
            </FlexBox>
          </CardBottom>
        </Card>
        <Card>
          <CardContent>
            <FlexBox>
              <EntryIcons icon={'bank'} />
              <Typography size={'xl'} bold>口座情報</Typography>
            </FlexBox>
            <Box mt={16}><span className='font-ms'>{entryTopPhrases.bank}</span></Box>
          </CardContent>
          <CardBottom>
            <FlexBox align={'center'}>
              <Button variant={'secoundary'} onClick={moveToEntryBankAccount}>入力する</Button>
              <Typography size={'xs'} color={completeCountData.bank.all_complete_flag ? 'success' : 'text_main'}>{completeCountData.bank.complete}/{completeCountData.bank.whole} 入力完了</Typography>
            </FlexBox>
          </CardBottom>
        </Card>
        <Card>
          <CardContent>
            <FlexBox>
              <EntryIcons icon={'cart'} />
              <Typography size={'xl'} bold>ショップ情報</Typography>
            </FlexBox>
            <Box mt={16}><span className='font-ms'>{entryTopPhrases.shop}</span></Box>
          </CardContent>
          <CardBottom>
            <FlexBox align={'center'}>
              <Button variant={'secoundary'} onClick={moveToEntryShop}>入力する</Button>
              <Typography size={'xs'} color={completeCountData.shop.all_complete_flag ? 'success' : 'text_main'}>{completeCountData.shop.complete}/{completeCountData.shop.whole} 入力完了</Typography>
            </FlexBox>
          </CardBottom>
        </Card>
      </EntryListWrapper>


    </Container>
  );
};
