import React, { useMemo } from 'react';
import { Box } from '../../layouts/Box';
import { MessageBox } from '../../style/DashboardStyle';
import { useNavigate } from 'react-router-dom'
import { Button } from '../../ui-parts';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  padding: auto;
  width:100%;
  text-align:center;
`

const DashboardMessageElement = ({ status, step, canceledFlag }) => {
  const navigate = useNavigate()

  const mainMessage = useMemo(()=>{
    let mainMessage;
    switch (status) {
      case "ENTRING":
        mainMessage = <>まずは必要な情報を入力しましょう。すべての情報を入力するとお申し込みが可能になります。</>
        break;
      case "ENTRY_OK":
        mainMessage = <>申し込みが完了しました。次にゼウスにてお申込内容の項目チェックとなります。</>
        break;
      case "CHECKING":
        mainMessage = <>ただいまゼウスにてお申込内容の項目チェック中です。</>
        break;
      case "WAITING":
        mainMessage = <>お申込み内容に不備があります。編集するボタンから編集いただき、再お申込みをお願いいたします。</>
        break;
      case "FIXED":
        mainMessage = <>再お申し込みが完了しました。審査完了までしばらくお待ちください。</>
        break;
      case "CHECK_NG":
        mainMessage = <div className='red_text'>{'取扱商品が、取扱不可商材などに該当いたしました。\nお取り扱いが出来ず、決済サービスのご提供ができませんので、申込み手続きを終了とさせていただきます。'}</div>
        break;
      case "INHOUSE_REVIEW":
        mainMessage = <>一次審査中です。審査完了までしばらくお待ちください。</>
        break;
      case "INHOUSE_REVIEW_NG":
        mainMessage = <div className='red_text'>{'一次審査で、審査NGとなりました。「審査状況のご確認」をご覧ください。\nこの一次審査の結果により、決済サービスのご提供ができませんので、申込み手続きを終了とさせていただきます。'}</div>
        break;
      case "EXTERNAL_REVIEW":
        mainMessage = <>二次審査中です。審査完了までしばらくお待ちください。</>
        break;
      case "EXTERNAL_REVIEW_NG":
        mainMessage = <div className='red_text'>{'二次審査で、審査NGとなりました。「審査状況のご確認」をご覧ください。\nこの二次審査の結果により、決済サービスのご提供ができませんので、申込み手続きを終了とさせていただきます。'}</div>
        break;
      case "WAITING_FINAL_AGREEMENT":
        mainMessage = <>二次審査中です。お申込内容から変更があるため、こちらから内容の確認・合意をお願いします。<ButtonWrapper><Button onClick={()=>navigate('/final_agreement', {state:{routeTo:'final_agreement'}})}>変更内容の確認</Button></ButtonWrapper></>
        break;
      case "FIXED_FINAL_AGREEMENT":
        mainMessage = <>お申し込みの内容変更の合意を受け付けました。ご利用開始のお知らせまでしばらくお待ちください。</>
        break;
      case "CUTOVER":
        mainMessage = <>利用開始が可能となりました。「ご利用開始の手順」で手順をご確認ください。</>
        break;
      default:
        mainMessage = <></>
    }
    if(canceledFlag) mainMessage = <div className='red_text'>{'キャンセルのご依頼を受け付けました。\nお申し込みのキャンセル手続きは完了しています。'}</div>

    return mainMessage
  },[status,canceledFlag,navigate])


  return (
    <section>
      <Box contentsWidth>
        <h1 className='align_center'>Web申込システム</h1>
        <p className='align_center'>下記の手順に沿って必要な情報を入力の上、お申し込みください。</p>
      </Box>
      <Box mt_desktop={32} mt_mobile={16} contentsWidth>
        <MessageBox data-stepcount={step}>
          <div className='inner newline'>
            {mainMessage}
          </div>
        </MessageBox>
      </Box>
    </section>
  )
}

export default DashboardMessageElement