import { useContext } from "react";
import { Context } from '../../stores/Provider';
import useChangePasswordHooks from '../../hooks/useChangePasswordHooks';
import { ChangePasswordElement } from "../../components/ui_elements";
import { Container } from "../../components/layouts/Container";
import { ErrorHandler } from "../../api/ErrorHandler";
import { BackButton } from "../../components/ui-parts/BackButton";
import { Link } from "react-router-dom";

const ChangePassword = () => {
	const { setCurrentPasswordHandler, setNewPasswordHandler, setConfirmPasswordHandler, sendPassword, normal, abnormal,
		CurrentPasswordData, NewPasswordData, ConfirmPasswordData, ErrMsgs } = useChangePasswordHooks();

	const { ErrorHandle } = ErrorHandler();
	const { state } = useContext(Context);
	const ApiErrMsg = state.auth.err_message;


	//ログイン状態
	const isLogin = true;

	const sendPasswordHandler = async () => {
		const response = await sendPassword(CurrentPasswordData, NewPasswordData, ConfirmPasswordData);
		//エラーハンドリング実行
		ErrorHandle(response, normal, abnormal)
	}

	return (
		<Container isLogin={isLogin}>
			<div align="right" className="mb32">
				<BackButton><Link to={'/dashboard'} state={{ routeTo: 'dashboard' }} >ダッシュボードへ戻る</Link></BackButton>
			</div>
			<ChangePasswordElement
				setCurrentPasswordHandler={setCurrentPasswordHandler}
				setNewPasswordHandler={setNewPasswordHandler}
				setConfirmPasswordHandler={setConfirmPasswordHandler}
				sendPasswordHandler={sendPasswordHandler}
				ErrMsgs={ErrMsgs}
				ApiErrMsg={ApiErrMsg}
			/>
		</Container>
	)
}

export default ChangePassword