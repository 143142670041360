import { useState, useContext, } from "react";
import { useNavigate } from 'react-router-dom'
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api.js';
import { mailValidate } from "../validators/publicValidator";
import { setIsLoading } from "../function/setIsLoading";

const useResetPasswordMailHooks = () => {
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ errMsg, setErrMsg ] = useState('');

    // 変数返却
    const EmailData = email;
    const ErrMsg = errMsg;

    const sendEmail = async (email) => {

        // バリデーション処理
        const { isValidate, message } = mailValidate(email)
        setErrMsg('')

        if (!isValidate) {
            return setErrMsg(message)
        }
        const data = { "email": email }

        //ローディング画面開始
        setIsLoading(true, dispatch)
        //API通信
        const response = await PostMethod(data, '/reset_password_mail');
        
        return response;

    }

    //正常時遷移先
    const normal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigate('/reset_password_mail_completed')
    }
    //異常時遷移先
    const abnormal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigate('/reset_password_mail_completed')
    }

    const setEmailHandler = (e) => {
        return setEmail(e.target.value)
    }

    return { sendEmail, EmailData, setEmailHandler, ErrMsg, normal, abnormal }
}

export default useResetPasswordMailHooks