import { useState, useContext, useEffect, useRef, useMemo, useCallback } from "react";
import { Context } from "../stores/Provider";
import { PostMethod, GetMethod } from "../api/Api";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading } from "../function/setIsLoading";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";

const useInquiryNavHooks = (isLogin) => {
    const location = useLocation();
    const navigate = useNavigate()
    let accordionNum
    if(location.state !== null){
        accordionNum = location.state.accordionNum
    }
    const path = location.pathname
    //セッションデータ抽出
	const userInfoData = getSession('user')
    // entry_idの格納
    let entry_id;
    if(userInfoData !== null) entry_id = userInfoData.entry_id;
    
    
    // グローバルStateから不備連携情報の取得
    const { state, dispatch } = useContext(Context);

    //不備Navi用のステイト
    const [naviFlag, setNaviFlag] = useState(false)
    const NaviFlag = naviFlag

    const InquiryData = useMemo(()=>{
        return state.inquiry.inquiry_all_data
    },[state.inquiry.inquiry_all_data])

    const InquiryCommentData = useMemo(()=>{
        return state.inquiry.inquiry_comment_data
    },[state.inquiry.inquiry_comment_data])
    // ===========================================
    // アコーディオン開閉State
    const[toggle, setToggle] = useState(false);
    const Toggle = toggle;
    const ToggleRef = useRef();

    // アコーディオンState変更関数
    const toggleChangeHandler = () => {
        setToggle(prev => !prev)
        // slideToggle(ToggleRef.current, 500)
    };
    // 不備NaviState変更関数
    const naviToggleChangeHandler = () => {
        setNaviFlag(prev => !prev)
    };

    //エラーメッセージステイト
    const [registerApiErrMsg, setRegisterApiErrMsg] = useState("")
    const [inquiryApiErrMsg] = useState("")

    //不備スライドインの表示フラグ
    const slideInShowFlag = useMemo(() => {
        let flag = false
        switch(path){
            case '/economic_select':
            case '/economic_estimate':
            case '/entry/merchant':
            case '/entry/bank_account':
            case '/entry/shop':
            case '/entry_check':
            case '/entry_confirm':
                flag = true
                break
            default:
                break
        }
        return flag
    },[path])

    //申し込みガイドの表示フラグ
    const EntryGuideShowFlag = useMemo(() => {
        let flag = false
        switch(path){
            case '/economic_select':
            case '/economic_estimate':
            case '/entry/merchant':
            case '/entry/bank_account':
            case '/entry/shop':
            case '/dashboard':
            case '/entry_top':
                flag = true
                break
            default:
                break
        }
        return flag
    },[path])

    // ===========================================
    // モーダル用のState
    const[modalFlag, setModalFlag] = useState(false);
    const ModalFlag = modalFlag;

    

    // モーダルの送信状態State
    const[modalSendFlag, setModalSendFlag] = useState(false);
    const ModalSendFlag = modalSendFlag;

    const modalOpenHandler = (e) => {
        e.stopPropagation();
        setModalFlag(prev => !prev);
        setModalSendFlag(false);
    };

    // お問い合わせ作成用のState
    const[inquiryRegister, setInquiryRegister] = useState({title: '', content: '', name: '', email: ''});

    // お問い合わせバリデーションState
    const[inquiryRegisterErrMsg, setInquiryRegisterErrMsg] = useState({text:"", select:""});
    const InquiryRegisterErrMsg = inquiryRegisterErrMsg;
    

    // お問い合わせState変更関数
    const setInquiryRegisterHandler = (e) => {
        const itemName = e.target.id.replace('send_mail_','')
        setInquiryRegister({...inquiryRegister, [itemName]: e.target.value})
    };

    //お問い合わせブランクバリデーション
    const setInquiryRegisterErrMsgHandler = (inquiryName,inquiryEmail,inquiryContent,inquiryTitle) => {
        let insertErrMsg = {}
        if (inquiryName === '' || inquiryEmail === "" || inquiryContent === '') {
            insertErrMsg = {...insertErrMsg, text:'お名前、メールアドレス、お問い合わせ内容(詳細)をすべてを入力してください。'};
        };
        if (inquiryTitle === '') {
            insertErrMsg = {...insertErrMsg, select:'お問い合わせ内容を選択してください。'};
        };
        return insertErrMsg
    }

    // お問い合わせ作成関数
    const inquiryRegisterHandler = async () => {
        const inquiryTitle = inquiryRegister.title;
        const inquiryContent = inquiryRegister.content;
        const inquiryName = inquiryRegister.name;
        const inquiryEmail = inquiryRegister.email;
        let insertErrMsg = {}
        
        //エラーメッセージ設定
        if (inquiryName === '' || inquiryEmail === "" || inquiryContent === '' || inquiryTitle === '') {
            insertErrMsg = setInquiryRegisterErrMsgHandler(inquiryName,inquiryEmail,inquiryContent,inquiryTitle)
            return setInquiryRegisterErrMsg(insertErrMsg)
        }
        
        setInquiryRegisterErrMsg({text:"", select:""});
        const reqData = {
            "name" : inquiryName,
            "user_id": userInfoData.user_id,
            "email": inquiryEmail,
            "content": inquiryTitle,
            "detail": inquiryContent,
        };
        //ローディング開始
        setIsLoading(true, dispatch)
        const {code} = await PostMethod(reqData, '/inquiry_send');
        if (code === 200) {
            setRegisterApiErrMsg('')
            setModalSendFlag(true)
            setInquiryRegister({title: '', content: '', name: '', email: ''})
        } else if (code === 401) {
            const response = {code:code}
            return logoutAbnormalFunction(response,dispatch,navigate)
        } else {
            // setRegisterApiErrMsg('メール送信に失敗しました。')
            setModalSendFlag(false)
            return navigate('/system_error')
        }
        //ローディング終了
        setIsLoading(false, dispatch)
    };

    // ===========================================
    // API接続で不備連携情報（お問い合わせ情報）の取得
    const getDataOfInquiryAll = useCallback(async () => {
        const reqData = { entry_id: entry_id }
        const response = await GetMethod(reqData, '/tickets');
        if (response.code === 200) { //お問い合わせ項目がある時
            dispatch({
                type: "set-inquiry-data",
                payload: response.response_data.tickets,
            });
        }else if (response.code === 204) { //お問い合わせ項目が無い時
            dispatch({
                type: "set-inquiry-data",
                payload: [],
            });
        } else if(response.code === 401){
            return logoutAbnormalFunction(response, dispatch, navigate)
        } else {
            return navigate('/system_error')
        }
        return response
    },[dispatch,entry_id,navigate])

    // API接続でコメント情報の取得
    const getDataOfInquiryCommentAll = useCallback(async(responseInquiry) => {
        const commentsArray = []
        const inquiryData = responseInquiry.response_data.tickets
        await Promise.all(inquiryData.map( async (data, i) => {
            const reqData = { entry_id: entry_id,ticket_id: data.ticket_id }
            const { code, response_data } = await GetMethod(reqData, '/tickets/comments');
            const response = {code: code}
            if(code === 200){
                commentsArray.push(response_data.comments)
            } else if(code === 401){
                return logoutAbnormalFunction(response, dispatch, navigate)
            } else {
                return navigate('/system_error')
            }
        }));
        commentsArray.sort((a,b)=>{
            if(a[0].created_at < b[0].created_at){
                return 1
            } else {
                return -1
            }
        })

        dispatch({
            type: "set-inquiry-comment-data",
            payload: commentsArray
        });
    },[dispatch,entry_id,navigate])
    
    const getDataInquiry = useCallback(async() => {
        const response =  await getDataOfInquiryAll()
        //不備が0個の場合リターン
        if(response === undefined || response.response_data === undefined || Object.values(response.response_data).length === 0) return
        await getDataOfInquiryCommentAll(response)
    },[getDataOfInquiryAll,getDataOfInquiryCommentAll])

    //チケットIDで不備オブジェクト取得
    const serchInquiryData = (ticketId) => {
        let returnObj = {}
        InquiryData.forEach((inquiry)=>{
            if(inquiry.ticket_id === ticketId){
                returnObj = inquiry
            }
        })
        return returnObj
    }

    //ステータスがCLOSED以外のものをカウント
    const nonClosedCount = useMemo(()=>{
        let count = 0
        InquiryData.forEach((data)=>{
            if(data.status !== "CLOSED") count++
        })
        return count
    },[InquiryData])

    // レンダリング後に不備連携情報の取得関数を実行(ログイン後ページ以外とentry_idがない場合は通信しない)
    useEffect(() => {
        if(!isLogin || entry_id === null || entry_id === undefined || entry_id === "") return
        getDataInquiry()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //closed,activeそれぞれの数をカウント
    const inquiryCount = useMemo(()=>{
        const countData = {closed:0, active:0}
        InquiryData.forEach((data)=>{
            if(data.status === 'CLOSED') countData.closed += 1
            if(data.status === 'ACTIVE') countData.active += 1
        })
        return countData
    },[InquiryData])

    //コンテンツ選択セレクトメニュー
    const selectTitle = [
        { label: 'コンテンツを選択', value: '', disabled: false },
        { label: '申し込みについて', value: '申し込みについて', disabled: false },
        { label: '審査について', value: '審査について', disabled: false },
        { label: '申し込みのキャンセル', value: '申し込みのキャンセル', disabled: false },
        { label: 'その他', value: 'その他', disabled: false },
    ]


    return {
        InquiryData, 
        InquiryCommentData,
        toggleChangeHandler, 
        Toggle, 
        ToggleRef,
        ModalFlag,
        ModalSendFlag,
        modalOpenHandler,
        inquiryRegisterHandler,
        setInquiryRegisterHandler,
        InquiryRegisterErrMsg,
        registerApiErrMsg,
        inquiryApiErrMsg,
        accordionNum,
        path,
        serchInquiryData,
        nonClosedCount,
        inquiryCount,
        naviToggleChangeHandler,
        NaviFlag,
        slideInShowFlag,
        EntryGuideShowFlag,
        selectTitle
    }
};

export default useInquiryNavHooks;
