import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';
import ImgIconCheck from '../../images/icon_check_white.svg';
import { ToolChip } from './ToolChip';
import { mixins } from '../../css/variables/mixins';

const CheckboxStyle = styled.div`
  display: inline-block;
  padding: 6px 4px;
  label{
    font-size:${fonts.fontSizeRem('ms')};
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    &:before{
      content: '';
      margin-right: 8px;
      border-radius: 2px;
      min-width: 20px;
      min-height: 20px;
      background-color: ${colors.themeColor('background_disabled')};
      border: 1px solid ${colors.themeColor('border_disabled')};
    }
    &:hover,&:active{
      background:${colors.themeColor('background_menu_hover')}
    }
    ${mixins.screenUnder()}{
      font-size: ${fonts.fontSizeRem('s')};
    }
  }
  input{
    display: none;
    &:checked + label:before{
      background-image:url(${ImgIconCheck});
      background-position: center center;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-color: ${props => props.disabled ? colors.themeColor('text_gray') : colors.themeColor('text_button_secoundary')};
      border: 1px solid ${colors.themeColor('text_button_secoundary')};
    }
  }
  ${mixins.screenUnder()}{
    display: flex;
  }
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
  `
const ToolChipWrapStyle = styled.span`
  display: inline-block;
  vertical-align:top;
  margin-left: 8px;
`
;


export const Checkbox = ({ id, name, label, checked, onChange, iserror, toolChip, disabled, toolChipWidth, responsivePlacement, value }) => {

  return (
    <CheckboxStyle disabled={disabled}>
      <input type='checkbox' id={id} name={name} checked={checked} onChange={onChange} value={value} data-testid={name} iserror={iserror} disabled={disabled}/>
      <label htmlFor={id}>
        {label}
      </label>
      {toolChip ? (
        <ToolChipWrapStyle>
          <ToolChip content={toolChip} placement={'center'} width={toolChipWidth} responsivePlacement={responsivePlacement} />
        </ToolChipWrapStyle>
      ) : (<></>)}
      {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
    </CheckboxStyle>
  );
};

Checkbox.defaultProps = {
};

