import { Button } from "./Button"

const PdfDownloadButton = ({pdfDownloadHandler, disabledFlag}) => {
    return(
        <Button onClick={pdfDownloadHandler} disabled={disabledFlag}>
            お申し込み情報ダウンロード
        </Button>
    )
}
PdfDownloadButton.defaultProps = {
    disabledFlag: false
}

export default PdfDownloadButton