import { BackGround } from "../../layouts/BackGround"
import { ExaminationContentTitle, ExaminationContent } from "../../ui-parts"
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { mixins } from '../../../css/variables/mixins';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.credit ? '16px 0 36px 0;' : '32px 0 36px 0;' }
  position: relative;
  ${mixins.screenUnder()}{
    flex-direction: column;
  }
`

const ExaminationContentWrapperStyle = styled.div`
  border-right: dotted 1px ${colors.themeColor('border_box')};
  width: ${props => props.contentWidth === 'credit' ? '20%' : 'calc(100% / 6)'};
  ${mixins.screenUnder()}{
    width: 100%;
    border-right: none;
    border-bottom: dotted 1px ${colors.themeColor('border_box')};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  &:last-child {
    border-right: none;
    ${mixins.screenUnder()}{
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`
const CancelTerminatePhraseStyle = styled.span`
  color: ${colors.themeColor('text_error')};
`


const ExaminationStatusElement = ({ examination, IsService, serviceCodeList }) => {
  const serviceStopFlag = {
    credit: (IsService.credit.status === 'CANCEL' || IsService.credit.status === 'TERMINATION'),
    cvs: (IsService.cvs.status === 'CANCEL' || IsService.cvs.status === 'TERMINATION'),
    bank: (IsService.bank.status === 'CANCEL' || IsService.bank.status === 'TERMINATION'),
    atobarai: (IsService.atobarai.status === 'CANCEL' || IsService.atobarai.status === 'TERMINATION')
  }

  return (
    <BackGround>
      {serviceCodeList.indexOf("credit") !== -1 &&
        <>
          <ExaminationContentTitle>クレジットカード決済　<CancelTerminatePhraseStyle>{IsService.credit.status === 'CANCEL' && 'キャンセル済み'}{IsService.credit.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper credit>
            {IsService.credit.array.map((value, index) => (
              <ExaminationContentWrapperStyle contentWidth={'credit'} key={index}>
                <ExaminationContent
                  key={index}
                  name={value[0]}
                  statuses={value[1]}
                  serviceName={'credit'}
                  wholeStatus={IsService.credit.status}
                />
              </ExaminationContentWrapperStyle>
            ))}
            {serviceStopFlag.credit && <div className="grayout"/>}
          </ContentWrapper>
        </>
      }
      {serviceCodeList.indexOf("atobarai") !== -1 &&
        <>
          <ExaminationContentTitle>コンビニあと払い　<CancelTerminatePhraseStyle>{IsService.atobarai.status === 'CANCEL' && 'キャンセル済み'}{IsService.atobarai.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper>
              <ExaminationContent
                name={'atobarai'}
                statuses={examination.entry.service.atobarai.status}
                wholeStatus={IsService.atobarai.status}
              />
              {serviceStopFlag.atobarai && <div className="grayout"/>}

          </ContentWrapper>
        </>
      }
      {serviceCodeList.indexOf("bank") !== -1 &&
        <>
          <ExaminationContentTitle>ネットバンク決済（入金おまかせサービス）<CancelTerminatePhraseStyle>{IsService.bank.status === 'CANCEL' && 'キャンセル済み'}{IsService.bank.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper>
              <ExaminationContent
                name={'bank'}
                statuses={examination.entry.service.bank.status}
                wholeStatus={IsService.bank.status}
              />
              {serviceStopFlag.bank && <div className="grayout"/>}
          </ContentWrapper>
        </>
      }
      {serviceCodeList.indexOf("cvs") !== -1 &&
        <>
          <ExaminationContentTitle>コンビニ決済　<CancelTerminatePhraseStyle>{IsService.cvs.status === 'CANCEL' && 'キャンセル済み'}{IsService.cvs.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper>
            {IsService.cvs.array.map((value, index) => (
              <ExaminationContentWrapperStyle contentWidth={'cvs'} key={index}>
                <ExaminationContent
                  name={value[0]}
                  statuses={value[1]}
                  serviceName={'cvs'}
                  wholeStatus={IsService.cvs.status}
                />
              </ExaminationContentWrapperStyle>
            ))}
            {serviceStopFlag.cvs && <div className="grayout"/>}
          </ContentWrapper>
        </>
      }
    </BackGround>
      
  )
}

export default ExaminationStatusElement