import React, { useContext } from "react";
import { Context } from '../../stores/Provider';
import { ResetPasswordElement } from "../../components/ui_elements";
import useResetPasswordHooks from "../../hooks/useResetPasswordHooks";
import { Container } from "../../components/layouts/Container";
import { ErrorHandler } from "../../api/ErrorHandler";

const ResetPassword = (props) => {

  const { email, setEmailHandler, sendPassword, PasswordData, ConfirmPasswordData, setPasswordHandler,
    setConfirmPasswordHandler, ErrMsg, normal, abnormal, setAuthenticationHandler, passCheckState } = useResetPasswordHooks(props);

  const { state } = useContext(Context);
  const ApiErrMsg = state.auth.err_message
  const { ErrorHandle } = ErrorHandler()

  //パスワードリセット実行
  const sendPasswordHandler = async () => {
    const response = await sendPassword(email, PasswordData, ConfirmPasswordData)
    //エラーハンドリング実行
    ErrorHandle(response, normal, abnormal, true)
  }

  return (
    <Container>
      <ResetPasswordElement
        ApiErrMsg={ApiErrMsg}
        setPasswordHandler={setPasswordHandler}
        setConfirmPasswordHandler={setConfirmPasswordHandler}
        sendPasswordHandler={sendPasswordHandler}
        setAuthenticationHandler={setAuthenticationHandler}
        setEmailHandler={setEmailHandler}
        passCheckState={passCheckState}
        ErrMsg={ErrMsg}
      />
    </Container>
  )
}

export default ResetPassword