// ./Provider.js
// データの初期設定やデータの受け渡しの設定を行う。

import React from "react";
import reducer from "./Reducer";
import { RecoilRoot } from 'recoil';
import { initBankAccountSessionData, initFileSessionData, initMerchantSessionData, initOperationSessionData, initRepresentativeSessionData, initReviewContentsDivisionSessionData, initShopSessionData } from "../constance/sessionData";

// React Contextの作成
const Context = React.createContext();
// Reducer関数の初期値
// ここでデータ構造を定義する。
const initialState = {
    // これ多分いらない
    login: {
        email: "",
        password: "",
    },
    auth: {
        err_message: "",
        code: ""
    },
    user: {
        name: "",
        email: "",
        password: "",
        tempPassword: "",
        access_token: "",
        reflesh_token: "",
        agent_service_id: "",
        incentive_id: "",
    },
    user_status: {
        status: "",
        canceled_flag: false,
        closeScheduleDate: null,
        confirm_flag: false
    },
    examination_status: {},
    use_start_data: {
        serviceData: {},
        managementData: [],
        atobaraiManagementData: [],
    },
    service_code_list:"credit",
    service_status:{
        credit:"NOTYET",
        bank:"NOTYET",
        cvs:"NOTYET",
        atobarai:"NOTYET",
    },
    inquiry: {
        inquiry_all_data: [],
        inquiry_comment_data: []
    },
    is_open_modal : {
        path : "",
        type : "",
        flag : false,
        message : ""
    },
    entryValidateMessage:"",
    validateMessages:{
        merchant:{
            merchant_type:{isValidate:'',message:''},
            merchant_name:{isValidate:'',message:''},
            merchant_name_kana:{isValidate:'',message:''},
            merchant_last_name:{isValidate:'',message:''},
            merchant_first_name:{isValidate:'',message:''},
            merchant_last_name_kana:{isValidate:'',message:''},
            merchant_first_name_kana:{isValidate:'',message:''},
            merchant_phone_number_before:{isValidate:'',message:''},
            merchant_phone_number_middle:{isValidate:'',message:''},
            merchant_phone_number_after:{isValidate:'',message:''},
            merchant_register_post_code3:{isValidate:'',message:''},
            merchant_register_post_code4:{isValidate:'',message:''},
            merchant_address_prefectures:{isValidate:'',message:''},
            merchant_address_prefectures_kana:{isValidate:'',message:''},
            merchant_address_municipality:{isValidate:'',message:''},
            merchant_address_municipality_kana:{isValidate:'',message:''},
            merchant_address_streetbunch:{isValidate:'',message:''},
            merchant_address_streetbunch_kana:{isValidate:'',message:''},
            merchant_address_building:{isValidate:'',message:''},
            merchant_address_building_kana:{isValidate:'',message:''},
            merchant_website_url:{isValidate:'',message:''},
            merchant_annual_business:{isValidate:'',message:''},
            merchant_shop_category:{isValidate:'',message:''},
            merchant_corporate_number:{isValidate:'',message:''},
            representative_last_name:{isValidate:'',message:''},
            representative_first_name:{isValidate:'',message:''},
            representative_last_name_kana:{isValidate:'',message:''},
            representative_first_name_kana:{isValidate:'',message:''},
            representative_sex:{isValidate:'',message:''},
            representative_birthday_CE:{isValidate:'',message:''},
            representative_birthday_month:{isValidate:'',message:''},
            representative_birthday_day:{isValidate:'',message:''},
            representative_position_name:{isValidate:'',message:''},
            operator_last_name:{isValidate:'',message:''},
            operator_first_name:{isValidate:'',message:''},
            operator_last_name_kana:{isValidate:'',message:''},
            operator_first_name_kana:{isValidate:'',message:''},
            operator_email:{isValidate:'',message:''},
            operator_phone_number_before:{isValidate:'',message:''},
            operator_phone_number_middle:{isValidate:'',message:''},
            operator_phone_number_after:{isValidate:'',message:''},
            support_operator_email:{isValidate:'',message:''},
            support_operator_phone_number_before:{isValidate:'',message:''},
            support_operator_phone_number_middle:{isValidate:'',message:''},
            support_operator_phone_number_after:{isValidate:'',message:''},
            identity_doc_images:{isValidate:'',message:''},

        },
        bank_account: {
            bank_account_bank_name: { isValidate: '', message: '' },
            bank_account_bank_branch: { isValidate: '', message: '' },
            bank_account_category: { isValidate: '', message: '' },
            bank_account_number: { isValidate: '', message: '' },
            bank_account_name: { isValidate: '', message: '' },
            passbook_images: { isValidate: '', message: '' },
        },
        shop: {
            account_id: { isValidate: '', message: '' },
            shop_name: { isValidate: '', message: '' },
            shop_name_kana: { isValidate: '', message: '' },
            shop_name_alphabet: { isValidate: '', message: '' },
            shop_website_url: { isValidate: '', message: '' },
            shop_addition_site_status_code: { isValidate: '', message: '' },
            shop_addition_site_status_method: { isValidate: '', message: '' },
            shop_cvs_website_name: { isValidate: '', message: '' },
            shop_cvs_website_name_kana: { isValidate: '', message: '' },
            shop_addition_shop_website_check_url: { isValidate: '', message: '' },
            shop_addition_business_deal_url: { isValidate: '', message: '' },
            shop_addition_shop_website_id: { isValidate: '', message: '' },
            shop_addition_shop_website_password: { isValidate: '', message: '' },
            shop_addition_conduct_start_yyyy: { isValidate: '', message: '' },
            shop_addition_conduct_start_mm: { isValidate: '', message: '' },
            shop_addition_price_range_min: { isValidate: '', message: '' },
            shop_addition_price_range_max: { isValidate: '', message: '' },
            shop_addition_price_validate: { isValidate: '', message: '' },
            shop_addition_product_content_code: { isValidate: '', message: '' },
            shop_addition_major_content_code: { isValidate: '', message: '' },
            shop_addition_medium_content_code: { isValidate: '', message: '' },
            shop_addition_product_content_freetext: { isValidate: '', message: '' },
            shop_addition_antique_flag: { isValidate: '', message: '' },
            shop_addition_subscription_flag: { isValidate: '', message: '' },
            shop_addition_subscription_code: { isValidate: '', message: '' },
            shop_addition_point_purchase_flag: { isValidate: '', message: '' },
            shop_addition_prepaid_flag: { isValidate: '', message: '' },
            shop_addition_reserved_product_flag: { isValidate: '', message: '' },
            shop_addition_reservation_period_flag: { isValidate: '', message: '' },
            shop_addition_estimated_product_flag: { isValidate: '', message: '' },
            shop_addition_rental_flag: { isValidate: '', message: '' },
            shop_addition_rental_product_url: { isValidate: '', message: '' },
            //レンタル規約のURLもしくは画像の有無判定
            shop_addition_rental_validate: { isValidate: '', message: '' },
            //online/offlineの有無判定
            shop_addition_offer_place: { isValidate: '', message: '' },
            shop_addition_btob_code: { isValidate: '', message: '' },
            shop_addition_physical_store_flag: { isValidate: '', message: '' },
            shop_addition_physical_store_url_flag: { isValidate: '', message: '' },
            shop_addition_physical_store_url: { isValidate: '', message: '' },
            shop_addition_non_administrative_penalties_five_year_flag: { isValidate: '', message: '' },
            shop_addition_non_losing_judgment_flag: { isValidate: '', message: '' },
            shop_addition_available_product_flag: { isValidate: '', message: '' },
            shop_addition_non_infringing_product_presence_absence_flag: { isValidate: '', message: '' },
            shop_atobarai_customer_notice_store_name: { isValidate: '', message: '' },
            antique: { isValidate: '', message: '' },
            license: { isValidate: '', message: '' },
            rental: { isValidate: '', message: '' },
            examination_document: { isValidate: '', message: '' },
        },
    },
    entry : {
        merchant : initMerchantSessionData,
        representative : initRepresentativeSessionData,
        operation : initOperationSessionData,
        bank_account : initBankAccountSessionData,
        shop : initShopSessionData,
        files : initFileSessionData,
        review_contents_division : initReviewContentsDivisionSessionData,
    },
    entry_check_status:{
        merchant:"default",
        bank:"default",
        shop:"default",
    },
    entry_bank_pulldown: {},
    isLoading: false

};


// コンテキストプロバイダーコンポーネント※基本いじらない
const ContextProvider = (props) => {
    // useReducerでreducer関数と初期値をセット
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        // コンテキストプロバイダーとしてuseReducerのstateとdispatchをコンテキストに設定
        <RecoilRoot>
            <Context.Provider value={value}>
                {props.children}
            </Context.Provider>
        </RecoilRoot>
    );
}


export {
    initialState,
    Context,
    ContextProvider
};