import { FormWrap } from '../../layouts/FormWrap';
import { Box } from '../../layouts/Box';
import { TextField, Button, ErrorMessage } from '../../ui-parts';
import { BackButton } from '../../ui-parts/BackButton';
import { Link } from 'react-router-dom';

const ResetPasswordMailElement = ({ setEmailHandler, sendEmailHandler, ErrMsg, ApiErrMsg, changePassword }) => {
  const passPhrase = changePassword ? 'パスワード変更' : 'パスワード再設定'
  return (
    <>
      {changePassword && 
        <div align="right" className="mb32">
          <BackButton><Link to={'/dashboard'} state={{routeTo:'dashboard'}} >ダッシュボードへ戻る</Link></BackButton>
        </div>
      }
      <FormWrap>
        <div className='align_center'>
          <h1 className='mt0 mb0'>{passPhrase}</h1>
          <p className="mt16 align_left">ご登録のメールアドレスを入力してください。{passPhrase}のURLを送信します。</p>
        </div>
        <ErrorMessage ApiErrMsg={ApiErrMsg} />
        <TextField
          mt={20}
          onChange={setEmailHandler}
          iserror={ErrMsg}
          id='textfield1'
          label='メールアドレス'
          placeholder='user@cardservice.co.jp'
          fullwidth
        />
        <Box mt={32}>
          <Button variant='primary' fullwidth onClick={sendEmailHandler}>{passPhrase}メールを送信する</Button>
        </Box>
      </FormWrap>
    </>
  )
}

export default ResetPasswordMailElement