import { PostMethod } from '../api/Api.js';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSession, setSession, setUserInfoData } from '../sessionStorage/sessionStorageMethod.js';
import { Context } from '../stores/Provider.js';
import { logoutAbnormalFunction, refreshMethods } from '../function/logoutAbnormalFunction.js';

const useLogoutHooks = () => {
    const navigate = useNavigate();
    const {dispatch} = useContext(Context)
    
    const user = getSession('user')
    // ログアウトに成功したら、login画面に遷移させる
    const Logout = async() => {
        setUserInfoData({...user, code:""})
        const data = ""
        const response = await PostMethod(data, '/logout');
        return response
    }

    //正常時処理
	const normal = (response) => {
        let loginData = {
            code:"",
            err_message: response.message,
        }
        refreshMethods(dispatch)
        // フロント側のセッションにアクセス状況を登録
        setSession('login', loginData)
		return navigate('/login')
	}

	// 異常時処理
	const abnormal = (response) => {
        return logoutAbnormalFunction(response,dispatch,navigate)
	}

    return { Logout, normal, abnormal }
}

export default useLogoutHooks;