import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { useMemo } from "react";
import { PostMethod } from "../api/Api";
import { getSession, setEconomicInfoData, setShopInfoData, setUserInfoData } from "../sessionStorage/sessionStorageMethod";
import { paymentSiteChangeToCodeHandler, paymentSiteChangeToPhraseHandler } from "../function/paymentSiteToCode";
import { Context } from "../stores/Provider";
import { setIsLoading } from "../function/setIsLoading";


const useEconomicEstimateElementHooks = () => {

    const [checkBoxState, setCheckBoxState] = useState({agreement:false,charges_check:false});
    const [vacantCheckState, setVacantCheckState] = useState({agreement_msg:'', charges_check_msg:''})
    const [creditPaymentSiteFeeState, setCreditPaymentSiteFeeState] = useState(0)
    const {state, dispatch} = useContext(Context)
	const navigate = useNavigate()

    const userInfoData = getSession('user')
    const agentMasterInfoData = getSession('agent_master')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const economicInfoData = getSession('economic_condition')
    const sumFeeInfoData = getSession('sum_fee')
    const shopInfoData = getSession('shop')

    const EconomicInfoData = economicInfoData;
    const demandCode = agentMasterInfoData.demand_code
    const snackbarIsOpen = state.is_open_modal

    //チェックボックスステイトハンドラ
    const setCheckBoxStateHandler = (e) => {
		return setCheckBoxState({...checkBoxState, [e.target.id]:e.target.checked})
	}

    //戻るボタン遷移
	const navigateBack = () => {
		return navigate('/economic_select', {state:{routeTo:'economic_select'}})
	}

    //オプション料金を申し込みAPIの経済条件データへ渡す(後払い以外)
    const setEconomicData = (economicData) => {
        let result = {...economicData};
        const creditSystemSetting = {...economicData.credit.system_setting}
        const bankSystemSetting = {...economicData.bank.system_setting}
        const cvsSystemSetting = {...economicData.cvs.system_setting}
        const atobaraiSystemSetting = {...economicData.atobarai.system_setting}
        let creditEconomicRequirement = {...economicData.credit.economic_requirement}
        let bankEconomicRequirement = {...economicData.bank.economic_requirement}
        let cvsEconomicRequirement = {...economicData.cvs.economic_requirement}
        let atobaraiEconomicRequirement = {...economicData.atobarai.economic_requirement}
        const feeNameList = {
            div:{system:"system_usage_fee_div2_bonus", option:"monthly_option_fee_div2_bonus", master:"option_fee_div2_bonus"},
            threed_secure:{system:"system_usage_fee_3d_secure", option:"monthly_option_fee_3d_secure", master:"option_fee_3d_secure"},
            cvv:{system:"system_usage_fee_cvv", option:"monthly_option_fee_cvv", master:"option_fee_cvv"},
            subscription:{system:"system_usage_fee_continuous_billing", option:"monthly_option_fee_continuous_billing", master:"option_fee_continuous_billing"},
            send_mail:{system:"system_usage_fee_confirmation_email", option:"monthly_option_fee_confirmation_email", master:"option_fee_confirmation_email"},
            payment_now:{system:"system_usage_fee_payment_now", option:"monthly_option_fee_payment_now", master:"option_fee_payment_now"},
            id_password_storage:{system:"system_usage_fee_id_password_storage", option:"monthly_option_fee_id_password_storage", master:"option_fee_id_password_storage"},
            customer_support:{system:"system_usage_fee_customer_support", option:"monthly_option_fee_customer_support", master:"option_fee_customer_support"},
            early_payment_flag:{system:"system_usage_fee_early_deposit_service", option:"monthly_option_fee_early_deposit_service", master:"option_fee_early_deposit_service"},
        }
        // demand_codeで変わる部分の料金を全部nullに変更
        for(let creditColumn in creditEconomicRequirement){
            if(creditColumn.indexOf("system_usage_fee_") !== -1 || creditColumn.indexOf("monthly_option_fee_") !== -1){
                creditEconomicRequirement = {...creditEconomicRequirement, [creditColumn]:null}
            }
        }
        for(let bankColumn in bankEconomicRequirement){
            if(bankColumn.indexOf("system_usage_fee_") !== -1 || bankColumn.indexOf("monthly_option_fee_") !== -1){
                bankEconomicRequirement = {...bankEconomicRequirement, [bankColumn]:null}
            }
        }
        for(let cvsColumn in cvsEconomicRequirement){
            if(cvsColumn.indexOf("system_usage_fee_") !== -1 || cvsColumn.indexOf("monthly_option_fee_") !== -1){
                cvsEconomicRequirement = {...cvsEconomicRequirement, [cvsColumn]:null}
            }
        }
        //credit
        if(creditSystemSetting.div){
            const targetName = feeNameList.div[demandCode]
            const masterFee = feeNameList.div.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        if(creditSystemSetting.threed_secure){
            const targetName = feeNameList.threed_secure[demandCode]
            const masterFee = feeNameList.threed_secure.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        if(creditSystemSetting.cvv){
            const targetName = feeNameList.cvv[demandCode]
            const masterFee = feeNameList.cvv.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        if(creditSystemSetting.subscription){
            const targetName = feeNameList.subscription[demandCode]
            const masterFee = feeNameList.subscription.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        if(creditSystemSetting.payment_now){
            const targetName = feeNameList.payment_now[demandCode]
            const masterFee = feeNameList.payment_now.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee]
        }
        if(creditSystemSetting.id_password_storage){
            const targetName = feeNameList.id_password_storage[demandCode]
            const masterFee = feeNameList.id_password_storage.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        if(creditSystemSetting.customer_support){
            const targetName = feeNameList.customer_support[demandCode]
            const masterFee = feeNameList.customer_support.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        if(creditSystemSetting.send_mail){
            const targetName = feeNameList.send_mail[demandCode]
            const masterFee = feeNameList.send_mail.master
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        }
        //クレジット支払いサイトは２回払い・３回払いの場合、料金を挿入
        if(creditEconomicRequirement.payment_site === economicConditionMasterInfoData.credit.payment_site){
            const targetName = feeNameList.early_payment_flag[demandCode]
            creditEconomicRequirement[targetName] = null
        } else if (creditEconomicRequirement.payment_site === '月2回締め 月2回払い'){
            const targetName = feeNameList.early_payment_flag[demandCode]
            const masterFee = "option_fee_early_deposit_service_two"
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        } else if (creditEconomicRequirement.payment_site === '月3回締め 月3回払い'){
            const targetName = feeNameList.early_payment_flag[demandCode]
            const masterFee = "option_fee_early_deposit_service_three"
            creditEconomicRequirement[targetName] = economicConditionMasterInfoData.credit[masterFee] 
        } else {
            const targetName = feeNameList.early_payment_flag[demandCode]
            creditEconomicRequirement[targetName] = null
        }
        //bank
        if(bankSystemSetting.customer_support){
            const targetName = feeNameList.customer_support[demandCode]
            const masterFee = feeNameList.customer_support.master
            bankEconomicRequirement[targetName] = economicConditionMasterInfoData.bank[masterFee] 
        }
        if(bankEconomicRequirement.early_payment_flag){
            const targetName = feeNameList.early_payment_flag[demandCode]
            const masterFee = feeNameList.early_payment_flag.master
            bankEconomicRequirement[targetName] = economicConditionMasterInfoData.bank[masterFee] 
        }
        if(bankSystemSetting.send_mail){
            const targetName = feeNameList.send_mail[demandCode]
            const masterFee = feeNameList.send_mail.master
            bankEconomicRequirement[targetName] = economicConditionMasterInfoData.bank[masterFee] 
        }
        //cvs
        if(cvsSystemSetting.customer_support){
            const targetName = feeNameList.customer_support[demandCode]
            const masterFee = feeNameList.customer_support.master
            cvsEconomicRequirement[targetName] = economicConditionMasterInfoData.cvs[masterFee] 
        }
        if(cvsEconomicRequirement.early_payment_flag){
            const targetName = feeNameList.early_payment_flag[demandCode]
            const masterFee = feeNameList.early_payment_flag.master
            cvsEconomicRequirement[targetName] = economicConditionMasterInfoData.cvs[masterFee] 
        }
        if(cvsSystemSetting.send_mail){
            const targetName = feeNameList.send_mail[demandCode]
            const masterFee = feeNameList.send_mail.master
            cvsEconomicRequirement[targetName] = economicConditionMasterInfoData.cvs[masterFee] 
        }
        //支払いサイト変換
        creditEconomicRequirement.payment_site = paymentSiteChangeToCodeHandler(creditEconomicRequirement.payment_site) 
        bankEconomicRequirement.payment_site = paymentSiteChangeToCodeHandler(bankEconomicRequirement.payment_site) 
        cvsEconomicRequirement.payment_site = paymentSiteChangeToCodeHandler(cvsEconomicRequirement.payment_site) 
        atobaraiEconomicRequirement.payment_site = paymentSiteChangeToCodeHandler(atobaraiEconomicRequirement.payment_site) 

        result = {
            ...result,
            credit:{
                system_setting:creditSystemSetting,
                economic_requirement:creditEconomicRequirement,
            },
            bank:{
                system_setting:bankSystemSetting,
                economic_requirement:bankEconomicRequirement,
            },
            cvs:{
                system_setting:cvsSystemSetting,
                economic_requirement:cvsEconomicRequirement,
            },
            atobarai:{
                system_setting:atobaraiSystemSetting,
                economic_requirement:atobaraiEconomicRequirement
            }
        }

        return result
    }

    //経済条件をレスポンスから受け取ってリコイルにセット
    const setEconomicInfoDataHandler = (response) => {
        const systemSettingArray = ['type','cgi','cgi_url','cgi_param','div','threed_secure','cvv','cvv_setting','subscription','auth','customer_support','id_password_storage','payment_now','send_mail']
        //経済条件
        let economicData = {...economicInfoData}
        //レスポンスデータのsystem_setting_がついている項目だけ回す
        for(let systemSettingContent in response.response_data){
            if(!systemSettingContent.includes('system_setting_')) continue
            const division = systemSettingContent.split('_')[2]
            let systemSettingObj = {...economicInfoData[division].system_setting}
            let economicRequirementObj = {...economicInfoData[division].economic_requirement}
            //各サービスの項目を回して、system_settingとeconomic_requirementに分ける
            for(let item in response.response_data[systemSettingContent]){
                
                //配列に存在する項目はsystem_settingへ、それ以外はeonomic_requirementへ格納
                if(systemSettingArray.includes(item)){
                    systemSettingObj = {...systemSettingObj, [item]:response.response_data[systemSettingContent][item]}
                }else{
                    let itemData = response.response_data[systemSettingContent][item]
                    if(item === 'payment_site') itemData = paymentSiteChangeToPhraseHandler(response.response_data[systemSettingContent][item])
                    economicRequirementObj = {...economicRequirementObj, [item]:itemData}
                }
            }
            economicData = {...economicData, 
                [division]:{
                    economic_requirement : economicRequirementObj,
                    system_setting : systemSettingObj
                }
            }
        }
        return economicData
    }


    //全オプションと金額一覧
    const originFeeArrayHandler = useCallback((key)=>{
        const originFeeArray = {
            threed_secure:{
                name:'3Dセキュア（本人認証システム）利用料',
                fee:economicConditionMasterInfoData[key].option_fee_3d_secure
            },
            cvv:{
                name:'セキュリティコード（CVV2/CVC2）利用料',
                fee:economicConditionMasterInfoData[key].option_fee_cvv
            },
            subscription:{
                name:'継続決済サービス利用料',
                fee:economicConditionMasterInfoData[key].option_fee_continuous_billing
            },
            div:{
                name:'２回払い/ボーナス一括払い利用料',
                fee:economicConditionMasterInfoData[key].option_fee_div2_bonus
            },
            send_mail:{
                name:"決済結果通知メール利用料",
                fee:economicConditionMasterInfoData[key].option_fee_confirmation_email
            },
            id_password_storage:{
                name:"ID・パスワード一時預かり利用料",fee:economicConditionMasterInfoData[key].option_fee_id_password_storage
            },
            customer_support:{
                name:"カスタマーサポート利用料",
                fee:economicConditionMasterInfoData[key].option_fee_customer_support
            },
            payment_now:{
                name:"今すぐ決済利用料",
                fee:economicConditionMasterInfoData[key].option_fee_payment_now
            },
            early_payment_flag:{
                name:"早期入金サービス利用料",
                fee:economicConditionMasterInfoData[key].option_fee_early_deposit_service
            },
        }
        return originFeeArray
    },[economicConditionMasterInfoData])

    //オプション利用項目表示
    const feeArray = useMemo(()=>{
        let feeArray = {
            credit:[],
            cvs:[],
            bank:[],
            atobarai:[]
        }
        //クレジットの支払いサイトが「デフォルト」「月2回締め 月2回払い」「月3回締め 月3回払い」で計算するマスタの料金を変える
        let creditPaymentSiteFee = 0;
        if(economicInfoData.credit.economic_requirement.payment_site === '月2回締め 月2回払い') creditPaymentSiteFee = economicConditionMasterInfoData.credit.option_fee_early_deposit_service_two
        if(economicInfoData.credit.economic_requirement.payment_site === '月3回締め 月3回払い') creditPaymentSiteFee = economicConditionMasterInfoData.credit.option_fee_early_deposit_service_three

        //サービスごとに回す
        for(let service in feeArray){
            const originFeeArray = originFeeArrayHandler(service)
            //system_setting内のオプションフラグがtrue、かつoriginFeeArrayに項目があるものを配列に追加
            for(let systemKey in economicInfoData[service].system_setting){
                if((systemKey in originFeeArray) && economicInfoData[service].system_setting[systemKey]){
                    feeArray[service].push({...originFeeArray[systemKey], key:systemKey})
                }
            }
            //early_payment_flagのみ例外的に処理
            //cvs,bankの時、early_payment_flagがtrueの時、配列に追加
            if((service === 'cvs' || service === 'bank') && economicInfoData[service].economic_requirement.early_payment_flag){
                feeArray[service].push(originFeeArray.early_payment_flag)
            }
            //credit
            if(service === 'credit' && economicInfoData.credit.economic_requirement.early_payment_flag){
                feeArray[service].push({
                    name:"早期入金サービス利用料",
                    fee:creditPaymentSiteFee
                })
                //クレジット支払いサイトの料金を保存
                setCreditPaymentSiteFeeState(creditPaymentSiteFee)
            }
        }
        return feeArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //支払方法プルダウン
	const selectItemsPaymentMethod = useMemo(()=>{
        const selectItemArray = [
            { label: '月々クレジットカード払い', value: '1', disabled: false },
            { label: '年間一括銀行振り込み払い', value: '3', disabled: false },
        ]
        return selectItemArray
    },[]) 
        
    //支払い方法文言
    const systemPaymentMethodPhrase = useMemo(()=>{
        const serviceList = ['credit','cvs','bank','atobarai']
        let tempObj = {
            credit:'',
            cvs:'',
            bank:'',
            atobarai:'',
        }
        serviceList.forEach((title)=>{
            selectItemsPaymentMethod.forEach((key)=>{
                if(economicInfoData[title].economic_requirement.monthly_payment_method === key.value){
                    tempObj = {...tempObj, [title]:key.label}
                }
            })
        }) 
            
        return tempObj
    },[economicInfoData,selectItemsPaymentMethod])

    //チェックバリデーション関数
    const vacantCheckAndSetMessage = () => {
        let validateFlag = false
        let vacantMsgs = {agreement_msg:'', charges_check_msg:''}
        if(checkBoxState.agreement === false) vacantMsgs.agreement_msg = '規約への同意は必須です'
        if(checkBoxState.charges_check === false) vacantMsgs.charges_check_msg = '取引手数料の確認は必須です'
        setVacantCheckState(vacantMsgs)
        //前回と今回のサービス選択を比較して差があった場合、かつ規約同意と手数料同意がされていない場合リターン
        if( !checkBoxState.agreement || !checkBoxState.charges_check) validateFlag = true
        return validateFlag
    }
    //ショップ情報に連携する項目をセット
    const setShopImpleData = () => {
        let shopData = shopInfoData
        if(economicInfoData.credit.system_setting.threed_secure){
            shopData.shop_addition_authentication_imple_flag = "1"
        } else {
            shopData.shop_addition_authentication_imple_flag = "3"
        }
        if(economicInfoData.credit.system_setting.cvv){
            shopData.shop_addition_security_code_imple_flag = "1"
        } else {
            shopData.shop_addition_security_code_imple_flag = "3"
        }
        return shopData
    }

    //エントリーページへ遷移する際の処理
	const entryEconomicData = async() => {
        if(vacantCheckAndSetMessage()) return null

        //オプション料金を経済条件にセット
        const economicSetOptionFeeData = setEconomicData(economicInfoData)
        //shop_addition_security_code_imple_flag,shop_addition_authentication_imple_flagをセット
        const insertShopData = setShopImpleData()
        const mainData = {
            user_id:userInfoData.user_id,
            entry_id:userInfoData.entry_id,
            status:state.user_status.status,
            email:userInfoData.email,
            services:economicSetOptionFeeData,
        }
        //ローディング開始
        setIsLoading(true, dispatch)
        const response = await PostMethod(mainData, '/entry_info')
        setShopInfoData(insertShopData)
        return response
	}

    //登録時のモーダル表示ハンドラ
	const setModalFlagHandler = (type) => {
		let message = ""
        if(type === 'success') message = "ご利用サービス情報を保存しました"
        if(type === 'error') message = "ご利用サービス情報の保存に失敗しました"
		
		dispatch({
			type : 'set-modal-flag',
			payload : {
					path : 'economic',
					type : type,
					flag : true,
					message :message
				}
		})
	}

    //API通信正常時実行関数
    const normal = (response) => {
        //保存成功モーダル表示
        // setModalFlagHandler('success')
        const navigatePage = '/entry/merchant'
        //ユーザ情報をセット
        setUserInfoData({...userInfoData, entry_id:response.response_data.entry_info.id})
        const economicData = setEconomicInfoDataHandler(response)
        setEconomicInfoData(economicData)
        //ローディング終了
        setIsLoading(false, dispatch)
		return navigate(navigatePage, {state:{routeTo:'entry'}})
	}

    //API通信異常時実行関数
    const abnormal = (response) => {
        // //保存失敗モーダル表示
        setModalFlagHandler('error')
        //ローディング終了
        setIsLoading(false, dispatch)
        return navigate('/economic_estimate', {state:{routeTo:'economic_estimate'}})
    }

    
    return {economicConditionMasterInfoData,feeArray,EconomicInfoData,systemPaymentMethodPhrase,sumFeeInfoData,vacantCheckState,checkBoxState,creditPaymentSiteFeeState,normal,abnormal,setEconomicInfoData,setCheckBoxStateHandler,navigateBack,entryEconomicData,vacantCheckAndSetMessage,originFeeArrayHandler,demandCode,snackbarIsOpen}
}

export default useEconomicEstimateElementHooks