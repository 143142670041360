import { useContext, useEffect, useMemo, useCallback } from "react"
import { Context } from "../stores/Provider"
import { GetMethod } from "../api/Api";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { useNavigate } from 'react-router-dom'
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { setIsLoading } from "../function/setIsLoading";
import { ToolChipDiv } from "../constance/constance";
import styled from "styled-components";
import { fonts } from "../css/variables/fonts";
import { colors } from "../css/variables/colors";

const AnavailableDaySpan = styled.span`
    font-weight: bold;
    font-size: ${fonts.fontSizeRem('s')};
    margin-bottom: 32px;
    color: ${colors.themeColor('text_error')};
`

const useContainerHooks = ({isLogin}) => {
    const {state, dispatch} = useContext(Context)
    const navigate = useNavigate();
    const isLoading = state.isLoading
    const canceledFlag = state.user_status.cancel_flag
    const serviceStatusData = state.service_status
    const confirmDate = state.user_status.close_schedule_date
    const serviceCodeList = state.service_code_list
    //セッションデータ抽出
	const userInfoData = getSession('user')

    const closeState = useMemo(()=>{
        let returnState = {confirm_date: null, confirm_flag: false}
        returnState = {confirm_date: state.user_status.close_schedule_date, confirm_flag: state.user_status.confirm_flag}
        return returnState
    },[state.user_status.close_schedule_date, state.user_status.confirm_flag])

    const closingNoticeText = useMemo(()=>{
        // 各テキスト格納
        const allGoText = <ToolChipDiv>{'・お申込みの決済サービスがすべてご利用開始となりましたので、Web申込システムのご利用が終了となります。\n　　'}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan>{'\n・なお、お申込み内容は、ダッシュボード画面右下の「お申し込み情報ダウンロード」ボタンよりPDFでダウンロードが可能です。\n　必要に応じて、ご利用終了日までにダウンロードを行ってください。'}</ToolChipDiv>
        const cvsCancelText = <ToolChipDiv>{'・コンビニ決済サービスのキャンセル手続きが完了しましたので、Web申込システムのご利用が終了となります。\n　　'}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan>{'\n・なお、お申込み内容は、ダッシュボード画面右下の「お申し込み情報ダウンロード」ボタンよりPDFでダウンロードが可能です。\n　必要に応じて、ご利用終了日までにダウンロードを行ってください。'}</ToolChipDiv>
        const cvsNgText = <ToolChipDiv>{'・コンビニ決済サービスは審査の結果ご提供不可となりました。審査終了に伴い、Web申込システムのご利用が終了となります。\n　　'}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan>{'\n・なお、お申込み内容は、ダッシュボード画面右下の「お申し込み情報ダウンロード」ボタンよりPDFでダウンロードが可能です。\n　必要に応じて、ご利用終了日までにダウンロードを行ってください。'}</ToolChipDiv>
        const allNgText = <ToolChipDiv>{'・お申込みの決済サービスは審査の結果、ご提供不可となりました。審査終了に伴い、Web申込システムのご利用が終了となります。\n　　'}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan></ToolChipDiv>
        const allChancelText = <ToolChipDiv>{'・お申込みの決済サービスは、すべてキャンセル手続きが完了いたしました。キャンセルに伴い、Web申込システムのご利用が終了となります。\n　　'}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan></ToolChipDiv>
        const closeServiceCodeList = serviceCodeList.split('/')

        // 選択サービス全NGパターン
        let allNgFlag = true
        closeServiceCodeList.forEach((selectedService) => {
            if(serviceStatusData[selectedService].status !== 'NG') allNgFlag = false           
        })
        if(allNgFlag || state.user_status.status === 'CHECK_NG') return allNgText

        //　選択サービス全キャンセルパターン
        let allChancelFlag = true
        closeServiceCodeList.forEach((selectedService) => {
            if(serviceStatusData[selectedService].status !== 'CANCEL') allChancelFlag = false           
        })
        // 全サービスキャンセルか申込全体がキャンセルの時
        if(allChancelFlag || canceledFlag) return allChancelText

        //  選択サービスにコンビニがあるかチェック
        if(serviceCodeList.includes("cvs")){
            // クレカがキャンセル以外＆＆コンビニがキャンセルの時
            if(serviceStatusData.credit.status !== 'CANCEL' && serviceStatusData.cvs.status === 'CANCEL'){
                return cvsCancelText
            }
            // クレカが審査NG以外＆＆コンビニが審査NGの時
            if(serviceStatusData.credit.status !== 'NG' && serviceStatusData.cvs.status === 'NG'){
                return cvsNgText
            }
        }

        //　上記の条件以外
        return allGoText
        
    },[state, serviceCodeList, serviceStatusData, confirmDate])
    
    //画面読み込み時ローディング表示、ページトップ移動後ローディングを消去
    useEffect(()=>{
        const getResponse = async () => {
            setIsLoading(true, dispatch)
            const entryId = {entry_id: userInfoData.entry_id}
            let response = ""
            response = await GetMethod(entryId, '/status') 
            if(response.code !== 200){
                if(response.code === 401){
                    return logoutAbnormalFunction(response,dispatch,navigate)
                }
                setIsLoading(false, dispatch)
                return navigate('/system_error')
            } 
  
            getServiceStatus(response.response_data.entry)
            setIsLoading(false, dispatch)
        }
        
        if(isLogin && state.user_status.status !== 'ENTRING') getResponse()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.user_status.staus])

        // 各サービス情報
    const getServiceStatus = useCallback((data) => {
        dispatch({
            type: "set-context-data",
            payload: {
                service_code_list:data.service_code_list,
                service_status:{
                    credit:data.service.credit,
                    bank:data.service.bank,
                    cvs:data.service.cvs,
                    atobarai:data.service.atobarai,
                },
                user_status:{
                    ...state.user_status,
                    cancel_flag:data.cancel_flag 
                }
            }
        })
    },[dispatch])


    // 申し込みガイドの遷移
    const navigateEntryGuide = () => {
        window.open("https://www.cardservice.co.jp/partner/ocnk02/guide/", '_blank', 'noopener=yes')
    }


    return{isLoading, navigateEntryGuide, canceledFlag, closeState, closingNoticeText}
}

export default useContainerHooks