import styled from "styled-components";
import { toolChipList } from "../../constance/constance";
import { colors } from "../../css/variables/colors";
import { fonts } from "../../css/variables/fonts";
import useEconomicEstimateTableHooks from "../../hooks/useEconomicEstimateTableHooks";
import { FlexBox } from "../layouts/FlexBox";
import { ToolChip } from "./ToolChip";
import { mixins } from "../../css/variables/mixins";

const ServiceCostTable = styled.table`
	width:100%;
	table-layout: fixed;
` 
const TableTr = styled.tr`
	p{
		margin-bottom:0px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.cvs-settle-fee{
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
        ${mixins.screenUnder()}{
            width:100%;
        }
        ${mixins.screenOver()}{
            width:49%;
        }
	}
	.credit-settle-fee{
		padding:4px;
		width:100%;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
    ${mixins.screenUnder()}{
        ${props => props.responsiveDisplayNone ? "display:none;" : ""}
    }
`

const TableTh = styled.th`
	width:35%;
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:normal;
	background: ${colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:10px;
    position:relative;
    ${mixins.screenUnder()}{
        display: block;
        width:100%;
        text-align:left;
    }
`

const TableTd = styled.td`
	color:${(props) => props.black ? colors.themeColor('text_main') : colors.themeColor('primary_dark')};
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('s')};
	font-weight:bold;
	padding:9px; 
    text-align: right;
    ${mixins.screenUnder()}{
        display: block;
        width:100%;
    }
`

const EconomicEstimateTable = ({contentName,settlementFee,sumSystemCost,options,salesHandleFee,paymentSiteFee, businessOperatorFee,shopData,productContentPhrase,finalAgreementResponseData,compareData,page}) => {
    const {economicInfoData,creditFee,cvsFee,systemOptionFee,demand_code} = useEconomicEstimateTableHooks({options,page})
    
    return(
        <ServiceCostTable>
                <tbody>
                    {contentName === "atobarai" &&
                        <>
                            <TableTr>
                                <TableTh>
                                    決済手数料
                                    <ToolChip content={toolChipList.business_operator_fee} placement={'left'} responsivePlacement={'center'} width={440}/>
                                </TableTh>
                                <TableTd><span className="font-xl">{businessOperatorFee}</span> ％</TableTd>
                            </TableTr>
                        </>
                    }
                    <TableTr>
                        <TableTh>
                            取引手数料
                            {contentName === "atobarai" && 
                                <ToolChip content={toolChipList.transaction_fee} placement={'left'} responsivePlacement={'center'} width={310} />
                            }
                            {contentName === "cvs" && 
                                <ToolChip content={toolChipList.cvs_fee} placement={'left'} responsivePlacement={'center'} width={320}/>
                            }
                        </TableTh>
                        {contentName === "credit" &&
                            <TableTd>
                                {creditFee}    
                            </TableTd>
                        }
                        {(contentName === "bank" || contentName === "atobarai") &&
                            <TableTd>
                                <span className="font-xl">{settlementFee}</span> ％
                            </TableTd>
                        }
                        {contentName === "cvs" &&
                            <TableTd>
                                <div className="cvs-settle-wrap">
                                    {cvsFee}
                                </div>
                            </TableTd>
                        }
                    </TableTr>
                    <TableTr>
                        <TableTh>初期費用（開設契約金）</TableTh>
                        <TableTd>￥ <span className="font-xl">{economicInfoData[contentName].economic_requirement.opening_contract_fee}</span></TableTd>
                    </TableTr>
                    <TableTr>
                        <TableTh>
                            月額費用（システム利用料）
                        </TableTh>
                        <TableTd>
                            <p className="font-s blue">￥ <span className="font-xl">{sumSystemCost}</span> /月</p>
                            {(options.length !== 0 && demand_code === 'system') &&
                                <div className="mt4">
                                    <p className="mb8">オプション利用料を含む</p>
                                    {systemOptionFee}
                                </div>
                            }
                        </TableTd>
                    </TableTr>
                    {(options.length !== 0 && demand_code !== 'system') &&
                        <TableTr>
                            <TableTh>
                                オプション利用料
                            </TableTh>
                            <TableTd>
                                {systemOptionFee}
                            </TableTd>
                        </TableTr>
                    }  
                    {salesHandleFee &&
                        <TableTr>
                            <TableTh>
                                {contentName === "atobarai" ?
                                    <> 
                                        請求処理料
                                        <ToolChip content={toolChipList.claim_fee} placement={'left'} responsivePlacement={'center'} width={450}/>
                                    </>
                                : 
                                    "売上処理料"
                                }    
                            </TableTh>
                            <TableTd>￥ <span className="font-xl">{economicInfoData[contentName].economic_requirement.sales_processing_fee}</span></TableTd>
                        </TableTr>
                    }
                    {demand_code === "system" &&
                        <>
                            <TableTr>
                                <TableTh>支払いサイト(入金サイクル)</TableTh>
                                <TableTd>
                                    <FlexBox spaceBetween>
                                        <span className="main-color font-m normal-weight">
                                            {economicInfoData[contentName].economic_requirement.payment_site}
                                        </span>
                                        <span className="main-color font-m normal-weight">
                                            {paymentSiteFee !== 0 ? '￥'+ paymentSiteFee + ' /月' : '無料'}
                                        </span>
                                    </FlexBox>
                                </TableTd>
                            </TableTr>
                        </>
                    }
                </tbody>
            </ServiceCostTable>
    )
}
export default EconomicEstimateTable