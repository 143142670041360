import axios from 'axios';

// axiosのインスタンス作成
const api = axios.create({
})

const baseURL = '/api'


// ログイン時やユーザー登録時にFlaskAPIと通信する
export const PostMethod = async (data, uri) => {
    let code, message, response_data
    return await api.post(
        baseURL + uri,
        data,
        { 'Content-Type': 'application/json' },
        { withCredentials: true },
        { jar: true }
    )
        .then(({data}) => {
            code = data.code
            message = data.message
            response_data = data.response_data
            return { code, message, response_data }
        })
        .catch((err) => {
            code = err.response.data.code
            message = err.response.data.message
            response_data = err.response.data.result_code
            return { code, message, response_data }
        })
}

// ゲットメソッド（データ受受信）
export const GetMethod = async (param_Data, uri) => {
    let code, message, response_data
    return await api.get(
        baseURL + uri,
        { params: param_Data },
        { 'Content-Type': 'application/json' },
        { withCredentials: true },
        { jar: true }
    )
        .then(({data}) => {
            code = data.code
            message = data.message
            response_data = data.response_data
            return { code, message, response_data }
        })
        .catch((err) => {
            code = err.request.status
            message = err.message
            return { code, message }
        })
}

// プットメソッド
export const PutMethod = async (param_Data, uri) => {
    let code, message, response_data
    return await api.put(
        baseURL + uri,
        param_Data,
        { 'Content-Type': 'application/json' },
        { withCredentials: true },
        { jar: true }
    )
        .then(({data}) => {
            code = data.code
            message = data.message
            response_data = data.response_data
            return { code, message, response_data }
        })
        .catch((err) => {
            code = err.request.status
            message = err.message
            return { code, message }
        })
}
