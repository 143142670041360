import { ToolChipDiv } from "../constance/constance"
//\u4E00-\u9FFF\u3005-\u3007漢字
//\u30A0-\u30FF全角カナ
//\uFF61-\uFF9F半角カナ
//\u3040-\u309Fひらがな
//\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e半角記号
//\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e\u2010-\u203E\u3001-\u3036\uFF01-\uFF0F\uFF1A-\uFF20\uFF3B-\uFF40\uFF5B-\uFF65\uFFE0-\uFFE6全角記号
// \uFF41-\uFF5A　全角アルファベット大文字	
// \uFF21-\uFF3A　全角アルファベット小文字	

//バリデーションパターン
export const validationPattern = {
    emailModel : /^[a-z0-9]{1}[a-z0-9_.-]*@{1}[a-z0-9_.-]+\.{1}[a-z0-9]+$/,
    emailOnlyUsed: /^[a-z0-9_.\-@]*$/,
    password : /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\^$*.[\]{}()?"!@#%&/,><:;|_~`=+-])[0-9A-Za-z^$*.[\]{}()?"!@#%&/,><:;|_~`=+-]{12,}$/,
    passwordCantUseSymbol: /^(?=.*[^\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309F\uFF41-\uFF5A\uFF21-\uFF3AA-Za-z0-9０-９^$*.[\]{}()?"!@#%&/,><:;|_~`=+-]).*$/ ,
    fullWidth : /^[^ -~｡-ﾟ]+$/,
    halfWidth : /^[ｦ-ﾟ]+$/,
    halfWidthPlusHyphen : /^[ｦ-ﾟ-]+$/,
    int : /^[0-9]+$/,
    municipality: /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309F]+$/,
    streetbunch: /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FF\u3040-\u309F\uFF61-\uFF9F\uFF41-\uFF5A\uFF21-\uFF3AA-Za-z0-9０-９\-‐ 　]+$/,
    streetbunchKana: /^[ｦ-ﾟ0-9\- ]+$/,
    building: /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309FーA-Za-z0-9０-９ 　]+$/,
    buildingKana: /^[Ffｦ-ﾟ0-9\- ]+$/,
    representativeName : /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FF\u3040-\u309F\uFF41-\uFF5A\uFF21-\uFF3A・]+$/,
    bankSymbol : /^[^()/. \u002D]+$/,
    bankSymbolLongVowel : /^(?=.*[\uFF70]).*$/,
    // bankName, nonSmallAlphabet, nonFullWidthの３つは口座名義バリデーションの置換で使用するため他の正規表現と少し異なる
    bankName : /[A-Z0-9ｦ-ﾟ()/. \u002D]/g,
    nonSmallAlphabet : /[a-z]/g,
    nonFullWidth : /[\u30A0-\u30FF\uFF41-\uFF5A\uFF21-\uFF3A\u3040-\u309F\u4E00-\u9FFF\u3005-\u3007０-９　]/g,
    accountId : /^[A-Za-z0-9-_]+$/,
    urlHttp : /^https?:\/\//,
    urlAlphanumeric : /^[\w!?/+\-_~=;.,*&@#$%()'[\]]+$/,
    freeText : ['その他','そのた'],
    legalPersonality : ['株式会社','有限会社','合名会社','合資会社','合同会社','医療法人','医療法人社団','医療法人財団','社会医療法人','財団法人','一般財団法人','公益財団法人','社団法人','一般社団法人','公益社団法人','宗教法人','学校法人','国立大学法人','公立大学法人','社会福祉法人','更生保護法人','相互会社','特定非営利活動法人','独立行政法人','地方独立行政法人','弁護士法人','行政書士法人','司法書士法人','税理士法人','有限責任中間法人','無限責任中間法人','農事組合法人','管理組合法人','社会保険労務士法人',],
    legalPersonalityKana : ['ｶﾌﾞｼｷｶﾞｲｼｬ','ﾕｳｹﾞﾝｶﾞｲｼｬ','ｺﾞｳﾒｲｶﾞｲｼｬ','ｺﾞｳｼｶﾞｲｼｬ','ｺﾞｳﾄﾞｳｶﾞｲｼｬ','ｲﾘｮｳﾎｳｼﾞﾝ','ｲﾘｮｳﾎｳｼﾞﾝｼｬﾀﾞﾝ','ｲﾘｮｳﾎｳｼﾞﾝｻﾞｲﾀﾞﾝ','ｼｬｶｲｲﾘｮｳﾎｳｼﾞﾝ','ｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ','ｲｯﾊﾟﾝｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ','ｺｳｴｷｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ','ｼｬﾀﾞﾝﾎｳｼﾞﾝ','ｲｯﾊﾟﾝｼｬﾀﾞﾝﾎｳｼﾞﾝ','ｺｳｴｷｼｬﾀﾞﾝﾎｳｼﾞﾝ','ｼｭｳｷｮｳﾎｳｼﾞﾝ','ｶﾞｯｺｳﾎｳｼﾞﾝ','ｺｸﾘﾂﾀﾞｲｶﾞｸﾎｳｼﾞﾝ','ｺｳﾘﾂﾀﾞｲｶﾞｸﾎｳｼﾞﾝ','ｼｬｶｲﾌｸｼﾎｳｼﾞﾝ','ｺｳｾｲﾎｺﾞﾎｳｼﾞﾝ','ｿｳｺﾞｶﾞｲｼｬ','ﾄｸﾃｲﾋｴｲﾘｶﾂﾄﾞｳﾎｳｼﾞﾝ','ﾄﾞｸﾘﾂｷﾞｮｳｾｲﾎｳｼﾞﾝ','ﾁﾎｳﾄﾞｸﾘﾂｷﾞｮｳｾｲﾎｳｼﾞﾝ','ﾍﾞﾝｺﾞｼﾎｳｼﾞﾝ','ｷﾞｮｳｾｲｼｮｼﾎｳｼﾞﾝ','ｼﾎｳｼｮｼﾎｳｼﾞﾝ','ｾﾞｲﾘｼﾎｳｼﾞﾝ','ﾕｳｹﾞﾝｾｷﾆﾝﾁｭｳｶﾝﾎｳｼﾞﾝ','ﾑｹﾞﾝｾｷﾆﾝﾁｭｳｶﾝﾎｳｼﾞﾝ','ﾉｳｼﾞｸﾐｱｲﾎｳｼﾞﾝ','ｶﾝﾘｸﾐｱｲﾎｳｼﾞﾝ','ｼｬｶｲﾎｹﾝﾛｳﾑｼﾎｳｼﾞﾝ',],
    legalPersonalityKanaCount : ['ｶﾞ','ｷﾞ','ｸﾞ','ｹﾞ','ｺﾞ','ｻﾞ','ｼﾞ','ｽﾞ','ｾﾞ','ｿﾞ','ﾀﾞ','ﾁﾞ','ﾂﾞ','ﾃﾞ','ﾄﾞ','ﾊﾞ','ﾋﾞ','ﾌﾞ','ﾍﾞ','ﾎﾞ','ﾊﾟ','ﾋﾟ','ﾌﾟ','ﾍﾟ','ﾎﾟ',],
    shopNameAlphabet : /^[A-Z0-9.\-ー ]+$/,
    shopNameKana : /^[ｦ-ﾟ ]+$/,
    shopName : /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309FA-Za-z0-9 　\-ー.]+$/,
    shopWebsiteCantUseSymbol: /^(?=.*[￥\\¥！!＃#＄$％%（(）)～~｜|\-＾^＠@[：:\]./`｛{　 *＊｝}？?＿_＋+＝=]).*$/,
    onlyFullWidth: /^(?=.*[A-Za-z0-9ｦ-ﾟ -/:-@[-´{-~]).*$/ ,
    onlyStringFullWidth: /^(?=.*[A-Za-zｦ-ﾟ]).*$/ ,
    //スペース、二点リーダー、※、シングルクォート、ダブルクォートをバリデーションにかける
    atobaraiCantUseSymbol: /^(?=.*[　‥※’”]).*$/ ,
    atobarai : /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309FA-Za-z0-9０-９]+$/,
    file : ['pdf','jpeg','jpg','png','PDF','JPEG','JPG','PNG'],
}
// メールバリデーション
export const mailValidate = (mail) => {

    const resValidate = {
        isValidate: false,
        message: '',
    }
    const pattern = validationPattern.emailModel;
    if (mail === '') {
        resValidate.message = 'メールアドレスを入力してください';
        return resValidate
    }

    if (!pattern.test(mail)) {
        resValidate.message = '正しいメールアドレスを入力してください';
        return resValidate
    }

    resValidate.isValidate = true;
    return resValidate
}

export const tempPasswordValidate = (password) => {
    const resValidate = {
        isValidate: false,
        message: '',
    }

    if (password === '') {
        resValidate.message = '仮パスワードを入力してください';
        return resValidate
    }

    resValidate.isValidate = true;
    return resValidate
}

// パスワード再入力のバリデーション
export const confirmPasswordValidate = (password, confirmPassword) => {

    const resValidate = {
        isValidate: false,
        message: '',
    }
    // const pattern = /^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$/;
    const passwordPattern = validationPattern.password;
    const passwordCantUseSymbolPattern = validationPattern.passwordCantUseSymbol

    if (password === '' || confirmPassword === '') {
        resValidate.message = '両方のパスワードを入力してください';
        return resValidate
    }

    if (password !== confirmPassword) {
        resValidate.message = '再入力されたパスワードと一致しません'
        return resValidate
    }
    if(passwordCantUseSymbolPattern.test(password)){
        resValidate.message = '使用できない記号が含まれています。';
        return resValidate
    }
    if (!passwordPattern.test(password)) {
        resValidate.message = '半角英数字,英字の大文字,記号を含む１２文字以上入力してください';
        return resValidate
    }
    

    resValidate.isValidate = true;
    return resValidate
}

export const singlePasswordValidate = (password) => {

    const resValidate = {
        isValidate: false,
        message: '',
    }

    const pattern = validationPattern.password;

    if (password === '') {
        resValidate.message = 'パスワードを入力してください';
        return resValidate
    }

    if (!pattern.test(password)) {
        resValidate.message = '半角英数字,英字の大文字,記号を含む１２文字以上入力してください';
        return resValidate
    }

    resValidate.isValidate = true;
    resValidate.message = ''
    return resValidate
}

//ここから下Entry用バリデーション
//字数制限ぴったり
const justWordCountValidate = (data,number) => {
    let message = '';
    if(data === '') return message
    if(data.length !== number){
        message = `${number}文字で入力してください`
        return message
    }
    return message
}
//字数制限ぴったり(桁バージョン)
const justCountKetaValidate = (data,number) => {
    let message = '';
    if(data === '') return message
    if(data.length !== number){
        message = `${number}桁で入力してください`
        return message
    }
    return message
}
//字数制限上限
const UpperLimitCountValidate = (data,number) => {
    let message = '';
    if(data === '') return message
    if(data.length > number){
        message = `${number}文字以内で入力してください`
        return message
    }
    return message
}
//数字バリデーション
export const intValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.int
    if(!pattern.test(data)){
        message = '半角数字で入力してください'
        return message
    }
    return message
}
//emailバリデーション
const emailValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.emailModel
    const disablePattern = validationPattern.emailOnlyUsed
    if(!pattern.test(data)){
        message = '正しいメールアドレスを入力してください。'
    }
    if(!disablePattern.test(data)){
        message = '利用できない文字が含まれています。'
    }
    return message
}
//代表者名バリデーション
const representativeNameValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.representativeName
    if(!pattern.test(data)){
        message = '数字、および、「・」（中黒点）以外の記号は使用できません。'
    }
    return message
}
//口座名義カナバリデーション(一旦全角カナ英字を許容して、後で変換)
const bankNameValidate = (data) => {
    let message = '';
    if(data === '') return message
    //口座名義の正しい正規表現
    const bankNamePattern = validationPattern.bankName
    //小文字英字が存在するか確認する正規表現
    const nonSmallAlphabetPattern = validationPattern.nonSmallAlphabet
    //全角が入っているか確認する正規表現
    const nonFullWidthPattern = validationPattern.nonFullWidth
    //口座名義の記号のみの正規表現
    const bankSymbolPattern = validationPattern.bankSymbol
    //記号の正規表現で\FF70がはじけないため、単独でバリデーションをかける
    const bankSymbolLongVowelPattern = validationPattern.bankSymbolLongVowel
    //口座名義の正しい正規表現・
    const symbolCheckData = data.replace(nonSmallAlphabetPattern,'').replace(nonFullWidthPattern, '').replace(bankNamePattern, '')
    if(nonSmallAlphabetPattern.test(data)){
        message = '英字は大文字で入力してください。'
    }
    if(nonFullWidthPattern.test(data)){
        message = <ToolChipDiv>{'・半角カナ・半角数字・半角英字（大文字）・半角スペース・半角記号（下記※参照）\n・長音「ー」は半角ハイフン「‐」で入力してください。'}</ToolChipDiv>
    }
    if(bankSymbolLongVowelPattern.test(data)){
        message = <ToolChipDiv>{'・半角カナ・半角数字・半角英字（大文字）・半角スペース・半角記号（下記※参照）\n・長音「ー」は半角ハイフン「‐」で入力してください。'}</ToolChipDiv>
    }
    if(symbolCheckData !== "" && bankSymbolPattern.test(symbolCheckData)){
        message = '使用可能な記号は「丸括弧（）、スラッシュ/、ハイフン-、.ピリオド. 」のみです。'
    }
    return message
}
//URLバリデーション
const urlHttpValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.urlHttp
    if(!pattern.test(data)){
        message = '「http://」または「https://」から入力してください。'
    }
    return message
}
//URLバリデーション
const urlAlphanumericValidate = (data) => {
    let message = '';
    if(!data.includes('http://') && !data.includes('https://')) return message
    data = data.replace('http://','').replace('https://','')
    if(data === '') return message
    const pattern = validationPattern.urlAlphanumeric
    if(!pattern.test(data)){
        message = '半角英数・半角記号で入力してください。'
    }
    return message
}
//shop_addition_product_content_freetextバリデーション
const freeTextValidate = (data) => {
    let message = '';
    let checkFlag = false;
    if(data === '') return message
    const pattern = validationPattern.freeText
    pattern.forEach((item)=>{
        if(data.indexOf(item) !== -1){
            checkFlag = true;
        }
    })
    if(checkFlag){
        message = '具体的な商品内容を入力してください'
        return message
    }
    return message
}
//法人格バリデーション
const legalPersonalityValidate = (data) => {
    let message = '';
    let checkFlag = false;
    if(data === '') return message
    const pattern = validationPattern.legalPersonality
    pattern.forEach((item)=>{
        if(data.indexOf(item) !== -1){
            checkFlag = true;
        }
    })
    if(checkFlag){
        message = '法人格は登録できませんので、削除してください。'
        return message
    }
    return message
}
//法人格カナバリデーション
const legalPersonalityKanaValidate = (data) => {
    let message = '';
    let checkFlag = false;
    if(data === '') return message
    const pattern = validationPattern.legalPersonalityKana
    pattern.forEach((item)=>{
        if(data.indexOf(item) !== -1){
            checkFlag = true;
        }
    })
    if(checkFlag){
        message = '法人格は登録できませんので、削除してください。'
        return message
    }
    return message
}
//法人格カナカウントバリデーション
const legalPersonalityKanaCountValidate = (data) => {
    let message = '';
    if(data === '') return message
    let dataCount = data.length
    const pattern = validationPattern.legalPersonalityKanaCount
    pattern.forEach((item)=>{
        dataCount += data.split(item).length-1
    })
    if(dataCount>24){
        message = '既定の文字数を超えています。'
    }
    return message
}
//半角カナバリデーション
const halfWidthKanaValidate = (data) => {
    let message = '';
    if(data === '') return message
    // const pattern = /^[ -~｡-ﾟ]+$/　記号あり
    const pattern = validationPattern.halfWidth
    if(!pattern.test(data)){
        message = '半角ｶﾅで入力してください。'
        return message
    }
    return message
}
//半角カナ+ハイフンバリデーション
const halfWidthKanaPlusHyphenValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.halfWidthPlusHyphen
    if(!pattern.test(data)){
        message = '半角ｶﾅで入力してください。'
        return message
    }
    return message
}
//全角のみバリデーション
const fullWidthValidate = (data) => {
    let message = '';
    if(data === '') return message
    //\u4E00-\u9FFF\u3005-\u3007漢字
    //\u30A0-\u30FFｦ-ﾟカナ
    //\u3040-\u309Fひらがな
    // const pattern = /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FF\u3040-\u309FA-Za-z0-9０-９]+$/
    const pattern = validationPattern.fullWidth
    if(!pattern.test(data)){
        message = '全角以外の文字が含まれています'
        return message
    }
    return message
}
//ショップwebsite名で使用できない記号
const shopWebsiteCantUseSymbolValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.shopWebsiteCantUseSymbol
    if(pattern.test(data)){
        message = '利用できない文字が含まれています。'
        return message
    }
    return message
}
//ショップwebsite名で使用できない記号
const onlyFullWidthValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.onlyFullWidth
    if(pattern.test(data)){
        message = '全角で入力してください。'
        return message
    }
    return message
}
//加盟店市区町村
const municiparityValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.municipality
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店町名番地
const streetbunchValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.streetbunch
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店建物名
const buildingValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.building
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店町名番地カナ
const streetbunchKanaValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.streetbunchKana
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店建物名カナ
const buildingKanaValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.buildingKana
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//ショップ英字バリデーション
const shopNameAlphabetValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.shopNameAlphabet
    if(!pattern.test(data)){
        message = 'ご利用いただけない文字が含まれています。'
        return message
    }
    return message
}
//ショップフリガナバリデーション
const shopNameKanaValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.shopNameKana
    if(!pattern.test(data)){
        message = 'ご利用いただけない文字が含まれています。'
        return message
    }
    return message
}
//ショップ名バリデーション
const shopNameValidate = (data) => {
    let message = '';
    if(data === '') return message
    //\u4E00-\u9FFF\u3005-\u3007漢字
    //\u30A0-\u30FFｦ-ﾟカナ
    //\u3040-\u309Fひらがな
    const pattern = validationPattern.shopName
    if(!pattern.test(data)){
        message = 'ご利用いただけない文字が含まれています。'
        return message
    }
    return message
}
//顧客通知店舗名バリデーション
const atobaraiValidate = (data) => {
    let message = '';
    if(data === '') return message
    //\u4E00-\u9FFF\u3005-\u3007漢字
    //\u30A0-\u30FFｦ-ﾟカナ
    //\u3040-\u309Fひらがな
    const pattern = validationPattern.atobarai
    if(!pattern.test(data)){
        message = '下記の規則に沿って入力してください'
        return message
    }
    return message
}
//顧客通知店舗名で使用できない記号
const atobaraiCantUseSymbolValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.atobaraiCantUseSymbol
    if(pattern.test(data)){
        message = '『 』（スペース）,『‥』（二点リーダー）,『※』（米印）,『\'』(分),『"』（秒）は使えません。'
        return message
    }
    return message
}
//顧客通知店舗名で使用できないハイフン・長音っぽいもの
const atobaraiCantUseHyphenValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.atobaraiCantUseHyphen
    if(pattern.test(data)){
        message = '利用できない文字が含まれています。'
        return message
    }
    return message
}
//顧客通知店舗名で使用できない記号
const accountIdValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.accountId
    if(!pattern.test(data)){
        message = '半角英数で入力してください。'
        return message
    }
    return message
}

//ファイルバリデーション
const fileValidate = (data) => {
    let message = '';
    if(data.length === 0) return message
    const fileTypeArray = validationPattern.file
    //fileの拡張子が'pdf','jpeg','png'以外の場合エラーメッセージ
    data.forEach((fileData)=>{
        if(!fileTypeArray.includes(fileData.file_name_origin.split('.')[1])) message = 'png,jpeg,pdf以外は登録できません'
    })
    return message
}

//銀行名プルダウン
const bankPulldownValidate = (data,state) => {
    const pulldownBankNameObj = state.entry_bank_pulldown
    let message = '';
    let existFlag = false
    if(data === "") return message
    for(let bankCode in pulldownBankNameObj){
        if(pulldownBankNameObj[bankCode].bank_name === data) existFlag = true
    }
    if(!existFlag) message = "正しい銀行名を入力・選択してください"
    return message
}

//銀行支店名プルダウン
const bankBranchPulldownValidate = (data,state) => {
    const pulldownBankNameObj = state.entry_bank_pulldown
    let message = '';
    let bankExitFlag = false
    let bankCodeData = ""
    let branchExistFlag = false
    let bankData = data.bank_account_bank_name
    let branchData = data.bank_account_bank_branch

    if(branchData === "") return message
    //銀行名が存在しない場合はリターン（ほぼない）
    for(let bankCode in pulldownBankNameObj){
        if(pulldownBankNameObj[bankCode].bank_name === bankData){
            bankExitFlag = true
            bankCodeData = bankCode
        } 
    }
    if(!bankExitFlag) return 
    //銀行支店名が存在するか確認
    const bankBranchObj = pulldownBankNameObj[bankCodeData].bank_branch
    for(let bankCode in bankBranchObj){
        if(bankBranchObj[bankCode].branch_name === branchData){
            branchExistFlag = true
        }
    }
    if(!branchExistFlag) message = "正しい支店名を入力・選択してください"
    return message
}

//フリーダイヤルバリデート
const freePhoneValidate = (data) => {
    let message = '';
    if(data.includes("0120")) message = 'フリーダイヤルは入力できません' 
    return message
}
//半角拒否バリデーション
const onlyStringFullWidthValidate = (data) => {
    let message = '';
    if(data === '') return message
    const pattern = validationPattern.onlyStringFullWidth
    if(pattern.test(data)){
        message = '全角で入力してください。'
    }
    return message
}
// 料金比較バリデーション
const comparePriceValidate = (data, shopInfoData) => {
    let message = ""
    const minPrice = shopInfoData.shop_addition_price_range_min
    const maxPrice = shopInfoData.shop_addition_price_range_max
    if((minPrice === null || maxPrice === null) || (minPrice === "" || maxPrice === "") || (intValidate(maxPrice) !== "" || intValidate(minPrice) !== "")) return message = ''
    if(maxPrice < minPrice) message = "商品の最小値が最大値を上回っています。"
    return message
}


//空欄バリデーション
export const blankValidate = (state,validateData,path) => {
    const keys = Object.keys(state)
    let propertyArray = [];
    let tempData={};
    let shop_addition_offer_place={isValidate:true, message:''};
    let shop_addition_rental_validate={isValidate:true, message:''};
    let blankData = {};
    //空欄バリデーションをかけない項目の配列
    const nonBlankValidateArray = ['shop_addition_shop_website_id','shop_addition_shop_website_password','shop_addition_rental_product_url','merchant_website_url','license']
    //加盟店住所ビルに入力があった場合カナに空欄バリデーションを、住所ビルカナに入力があった場合住所ビルに空欄バリデーションをかける
    if(state.merchant_address_building === "") nonBlankValidateArray.push('merchant_address_building_kana')
    if(state.merchant_address_building_kana === "") nonBlankValidateArray.push('merchant_address_building')
    //該当ページの空欄バリデーション
    keys.forEach((key) => {
        //空欄バリデーションをかけない項目または値がnullの場合リターン
        if(nonBlankValidateArray.includes(key)) return
        if(state[key] === null) return propertyArray.push(key)
        //ファイル以外
        typeof(state[key]) === 'string' && state[key] === '' && propertyArray.push(key)
        //ファイル用
        typeof(state[key]) === 'object' && state[key].length === 0 && propertyArray.push(key)
        //ショップセレクトボックス
        key === "shop_addition_non_infringing_product_presence_absence_flag" && state[key] === false && propertyArray.push(key)
        key === "shop_addition_available_product_flag" && state[key] === false && propertyArray.push(key)
    })
    propertyArray.forEach((key) => {
        tempData = {...tempData, [key]:{isValidate:false, message:'必須項目です'}}
    })
    //レンタル規約かファイルのどちらか入力必須
    if(state.shop_addition_rental_validate === false){
        shop_addition_rental_validate = {isValidate:false, message:'URLもしくはファイルのどちらかは必須です'}
    }
    //複数チェックボックスのバリデーション
    if(state.shop_addition_offer_place === false){
        shop_addition_offer_place = {isValidate:false, message:'１つ以上選択必須です'}
    }
    blankData = {...validateData, ...tempData}
    if(path === 'shop'){
        blankData = {...blankData, shop_addition_offer_place, shop_addition_rental_validate}
    }
    return {blankData}
}

//エントリバリデーター
export const entryValidator = (validate,data,number,state) => {
    const resValidate = {
        isValidate: false,
        message: '',
    }
    if(data === null) return resValidate
    validate.forEach((value)=>{
        switch(value){
            case 'JustCount':
                const justCountMessage = justWordCountValidate(data,number)
                if(justCountMessage !== '') resValidate.message = justCountMessage
                break;
            case 'JustCountKeta':
                const justCountKetaMessage = justCountKetaValidate(data,number)
                if(justCountKetaMessage !== '') resValidate.message = justCountKetaMessage
                break;
            case 'UpperLimitCount':
                const upperLimitCountMessage = UpperLimitCountValidate(data,number)
                if(upperLimitCountMessage !== '') resValidate.message = upperLimitCountMessage
                break;
            case 'Int':
                const intMessage = intValidate(data)
                if(intMessage !== '') resValidate.message = intMessage
                break;
            case 'Email':
                const emailMessage = emailValidate(data)
                if(emailMessage !== '') resValidate.message = emailMessage
                break;
            case 'RepresentativeName':
                const representativeNameMessage = representativeNameValidate(data)
                if(representativeNameMessage !== '') resValidate.message = representativeNameMessage
                break;
            case 'BankName':
                const bankNameMessage = bankNameValidate(data)
                if(bankNameMessage !== '') resValidate.message = bankNameMessage
                break;
            case 'URLHttp':
                const urlHttpMessage = urlHttpValidate(data)
                if(urlHttpMessage !== '') resValidate.message = urlHttpMessage
                break;
            case 'URLAlphanumeric':
                const urlAlphanumericMessage = urlAlphanumericValidate(data)
                if(urlAlphanumericMessage !== '') resValidate.message = urlAlphanumericMessage
                break;
            case 'FreeText':
                const freeTextMessage = freeTextValidate(data)
                if(freeTextMessage !== '') resValidate.message = freeTextMessage
                break;
            case 'LegalPersonality':
                const legalPersonalityMessage = legalPersonalityValidate(data)
                if(legalPersonalityMessage !== '') resValidate.message = legalPersonalityMessage
                break;
            case 'LegalPersonalityKana':
                const legalPersonalityKanaMessage = legalPersonalityKanaValidate(data)
                if(legalPersonalityKanaMessage !== '') resValidate.message = legalPersonalityKanaMessage
                break;
            case 'LegalPersonalityKanaCount':
                const legalPersonalityKanaCountMessage = legalPersonalityKanaCountValidate(data)
                if(legalPersonalityKanaCountMessage !== '') resValidate.message = legalPersonalityKanaCountMessage
                break;
            case 'FullWidth':
                const fullWidthMessage = fullWidthValidate(data)
                if(fullWidthMessage !== '') resValidate.message = fullWidthMessage
                break;
            case 'HalfWidthKana':
                const halfWidthKanaMessage = halfWidthKanaValidate(data)
                if(halfWidthKanaMessage !== '') resValidate.message = halfWidthKanaMessage
                break;
            case 'HalfWidthKanaPlusHyphen':
                const halfWidthKanaPlusHyphenMessage = halfWidthKanaPlusHyphenValidate(data)
                if(halfWidthKanaPlusHyphenMessage !== '') resValidate.message = halfWidthKanaPlusHyphenMessage
                break;
            case 'ShopNameAlphabet':
                const shopNameAlphabetMessage = shopNameAlphabetValidate(data)
                if(shopNameAlphabetMessage !== '') resValidate.message = shopNameAlphabetMessage
                break;
            case 'ShopNameKana':
                const shopNameKanaMessage = shopNameKanaValidate(data)
                if(shopNameKanaMessage !== '') resValidate.message = shopNameKanaMessage
                break;
            case 'ShopName':
                const shopNameMessage = shopNameValidate(data)
                if(shopNameMessage !== '') resValidate.message = shopNameMessage
                break;
            case 'Atobarai':
                const atobaraiMessage = atobaraiValidate(data)
                if(atobaraiMessage !== '') resValidate.message = atobaraiMessage
                break;
            case 'File':
                const fileMessage = fileValidate(data)
                if(fileMessage !== '') resValidate.message = fileMessage
                break;
            case 'BankPulldown':
                const bankPulldownMessage = bankPulldownValidate(data,state)
                if(bankPulldownMessage !== '') resValidate.message = bankPulldownMessage
                break;
            case 'BankBranchPulldown':
                const bankBranchPulldownMessage = bankBranchPulldownValidate(data,state)
                if(bankBranchPulldownMessage !== '') resValidate.message = bankBranchPulldownMessage
                break;
            case 'FreePhone':
                const freePhoneMessage = freePhoneValidate(data)
                if(freePhoneMessage !== '') resValidate.message = freePhoneMessage
                break;
            case 'Municiparity':
                const municiparityMessage = municiparityValidate(data)
                if(municiparityMessage !== '') resValidate.message = municiparityMessage
                break;
            case 'Streetbunch':
                const streetbunchMessage = streetbunchValidate(data)
                if(streetbunchMessage !== '') resValidate.message = streetbunchMessage
                break;
            case 'Building':
                const buildingMessage = buildingValidate(data)
                if(buildingMessage !== '') resValidate.message = buildingMessage
                break;
            case 'StreetbunchKana':
                const streetbunchKanaMessage = streetbunchKanaValidate(data)
                if(streetbunchKanaMessage !== '') resValidate.message = streetbunchKanaMessage
                break;
            case 'BuildingKana':
                const buildingKanaMessage = buildingKanaValidate(data)
                if(buildingKanaMessage !== '') resValidate.message = buildingKanaMessage
                break;
            case 'ShopWebsiteCantUseSymbol':
                const ShopWebsiteCantUseSymbolMessage = shopWebsiteCantUseSymbolValidate(data)
                if(ShopWebsiteCantUseSymbolMessage !== '') resValidate.message = ShopWebsiteCantUseSymbolMessage
                break;
            case 'OnlyFullWidth':
                const onlyFullWidthMessage = onlyFullWidthValidate(data)
                if(onlyFullWidthMessage !== '') resValidate.message = onlyFullWidthMessage
                break;
            case 'AtobaraiCantUseSymbol':
                const atobaraiCantUseSymbolMessage = atobaraiCantUseSymbolValidate(data)
                if(atobaraiCantUseSymbolMessage !== '') resValidate.message = atobaraiCantUseSymbolMessage
                break;
            case 'AtobaraiCantUseHyphen':
                const atobaraiCantUseHyphenMessage = atobaraiCantUseHyphenValidate(data)
                if(atobaraiCantUseHyphenMessage !== '') resValidate.message = atobaraiCantUseHyphenMessage
                break;
            case 'AccountId':
                const accountIdMessage = accountIdValidate(data)
                if(accountIdMessage !== '') resValidate.message = accountIdMessage
                break;
            case 'OnlyStringFullWidth':
                const onlyStringFullWidthMessage = onlyStringFullWidthValidate(data)
                if(onlyStringFullWidthMessage !== '') resValidate.message = onlyStringFullWidthMessage
                break;
            case 'ComparePrice':
                const comparePriceMessage = comparePriceValidate(data,state)
                if(comparePriceMessage !== '') resValidate.message = comparePriceMessage
                break;
            
            default:
                break;
        }
    })
    if(resValidate.message === '') resValidate.isValidate = true;
    return resValidate
}

// 不備連携部分のバリデーション
export const deficiencyNameValidate = (name, min = 0, max = 15) => {
    
    const resValidate = {
        isValidate: false,
        message: '',
    }

    if(name === '' ) {
        resValidate.message = "お問い合わせ名を入力してください"
        return resValidate;
    }

    if(name.length <= min || name.length >= max) {
        resValidate.message = `${min}文字以上${max}文字以内で入力してください`
        return resValidate;
    }

    resValidate.isValidate = true;
    return resValidate
}

