import React from 'react'
import { Box } from '../../components/layouts/Box';
import { Container } from "../../components/layouts/Container";
import { TitleElement } from '../../components/ui_elements';
import UseStartElement from '../../components/ui_elements/UseStart/UseStartElement';
import UseStartLinkElement from '../../components/ui_elements/UseStart/UseStartLinkElement';
import useStartHooks from '../../hooks/useStartHooks';
import { BackButton } from '../../components/ui-parts/BackButton';

const UseStart = () => {
  const { useStartData, serviceCodeList, navigate, serviceStatusData, phraseByAgent, openExamiantionFlagState, examinationOpenHandler, ContentStatus, economicInfoData, creditBrandData, serviceStopFlag } = useStartHooks();
  return (
    <Container isLogin={true}>
      <TitleElement />
        <div className="mb12 align_right display-mobile">
					<BackButton onClick={()=>navigate('/dashboard', {state:{routeTo:'dashboard'}})}>ダッシュボードに戻る</BackButton>
				</div>
      <UseStartElement
        service={'other'}
        managementData={useStartData.managementData}
        serviceData={useStartData.serviceData}
        serviceCodeList={serviceCodeList}
        serviceStatusData={serviceStatusData}
        serviceStopFlag={serviceStopFlag}
        phraseByAgent={phraseByAgent}
        examinationOpenHandler={examinationOpenHandler}
        openExamiantionFlagState={openExamiantionFlagState}
        ContentStatus={ContentStatus}
        economicInfoData={economicInfoData}
        creditBrandData={creditBrandData}
      />
      <Box mt={20} />
      <UseStartElement
        service={'atobarai'}
        serviceCodeList={serviceCodeList}
        atobaraiManagementData={useStartData.atobaraiManagementData}
        serviceData={useStartData.serviceData}
        serviceStatusData={serviceStatusData}
        phraseByAgent={phraseByAgent}
        examinationOpenHandler={examinationOpenHandler}
        openExamiantionFlagState={openExamiantionFlagState}
        ContentStatus={ContentStatus}
        serviceStopFlag={serviceStopFlag}
      />
      <Box mt={20} />
      <UseStartElement 
        service={'pdfExplain'}
      />
      <UseStartLinkElement />
    </Container>
  );
};

export default UseStart