import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';

const SelectMenuStyle = styled.div`
  display:${props => props.fullwidth ? 'flex' : 'inline-flex'};
  flex-direction: column;
  margin-top: ${props => props.mt}px;
  label{
    display: block;
    margin-bottom: 4px;
    font-size:${fonts.fontSizeRem('s')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`
const TextFieldStyle = styled.div`
  .textfield{
    background: ${colors.themeColor('background_input')};
    padding: 12px 16px;
    border-radius: 4px;
    width:100%;
    border:1px solid ${props => props.iserror ? colors.themeColor('border_error') + ' !important' : colors.themeColor('border_input')};
    outline: none;
    &::placeholder{
      color: ${colors.themeColor('text_placeholder')};
    }
    &:focus,&:active{
      border: 1px solid ${colors.themeColor('primary_light')}; ;
    }
    &:disabled{
      background: ${colors.themeColor('background_disabled')};
      color:${colors.themeColor('text-disabled')};
      border:1px solid ${colors.themeColor('border_disabled')};
    }
    &.password{
      padding-right: 48px;
      &::-ms-reveal{
        visibility: hidden;
      }
    }
  }
`
const ToolChipWrapStyle = styled.span`
  display: inline-block;
  margin-left: 8px;
  vertical-align: text-bottom;
  font-size:${fonts.fontSizeRem('xs')};
`

export const DataList = ({ id, name, label, options, defaultValue, mt, fullwidth, onChange, iserror, memo, value, disabled, explain, autocomplete }) => {
  return (
    <SelectMenuStyle mt={mt} fullwidth={fullwidth}>
      {label ? (
        <label htmlFor={id}>{label}</label>
      ) : (<></>)}
      <TextFieldStyle iserror={iserror}>
        <input list={id} type={"text"} className={"textfield"} onChange={onChange} name={name} defaultValue={defaultValue} value={value} disabled={disabled} autoComplete={autocomplete}/>
      </TextFieldStyle>
      <datalist id={id}>
        {options.map((item, index) => (
          <option key={index} value={item.value} disabled={item.disabled} ></option>
        ))}
      </datalist>
      {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
      {explain ? (<ToolChipWrapStyle>{explain}</ToolChipWrapStyle>):(<></>)}
    </SelectMenuStyle>
  );
};

DataList.defaultProps = {
  mt: 16,
  fullwidth:false
};

