import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';

const RecomendStyle = styled.p`
    margin-bottom: 0px;
    font-size: ${fonts.fontSizeRem('xs')}
`

const CvsDescriptionPop = () => {
    return(
        <RecomendStyle>
            導入までに２～３ヶ月ほどお時間をいただきます。
        </RecomendStyle>
    )
}

export default CvsDescriptionPop