import React, { useState } from 'react';
import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';
import { mixins } from '../../css/variables/mixins';

const ToolChipStyle = styled.div`
  position: relative;
  display: inline;
  height: fit-content;
`;

const ToolChipIconStyle = styled.button`
  width:20px;
  height:20px;
  background: #F0F0F0;
  border-radius: 50%;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 13px;
  color: ${colors.themeColor('gray')};
  cursor: pointer;
`
const ToolChipContentStyle = styled.div`
  position: absolute;
  bottom: 32px;
  background: rgba(0, 0, 0, 0.75);
  color: ${colors.themeColor('text_white')};
  font-size:${fonts.fontSizeRem('xxs')};
  z-index: 100;
  padding: 8px;
  border-radius: 4px;
  text-align: left;
  ${mixins.screenOver()}{
    min-width: ${props => props.width ? props.width+'px;' : '200px;'};
    ${props => props.placement === 'center' ? 'left:50%' : null};
    ${props => props.placement === 'center' ? 'transform: translateX(-50%)' : null};
    ${props => props.placement === 'left' ? 'left:0' : null};
    ${props => props.placement === 'right' ? 'right:0' : null};
  }
  ${mixins.screenUnder()}{
    min-width: 200px;
    ${props => props.responsivePlacement === 'center' ? 'left:50%' : null};
    ${props => props.responsivePlacement === 'center' ? 'transform: translateX(-50%)' : null};
    ${props => props.responsivePlacement === 'left' ? 'left:0' : null};
    ${props => props.responsivePlacement === 'right' ? 'right:0' : null};
  }
  &:after{
    content: '';
    bottom: -8px;
    border-top: 8px solid rgba(0, 0, 0, 0.75);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    ${mixins.screenOver()}{
      ${props => props.placement === 'center' ? 'left:50%' : null};
      ${props => props.placement === 'center' ? 'transform: translateX(-50%)' : null};
      ${props => props.placement === 'left' ? 'left:4px' : null};
      ${props => props.placement === 'right' ? 'right:4px' : null};
    }
    ${mixins.screenUnder()}{
      ${props => props.responsivePlacement === 'center' ? 'left:50%' : null};
      ${props => props.responsivePlacement === 'center' ? 'transform: translateX(-50%)' : null};
      ${props => props.responsivePlacement === 'left' ? 'left:4px' : null};
      ${props => props.responsivePlacement === 'right' ? 'right:4px' : null};
    }
  }
`;


export const ToolChip = ({ content, placement, width, responsivePlacement }) => {
  const [hintShow, setHintShow] = useState(false);
  const [hintShowClick, setHintShowClick] = useState(false);
  return (
    <ToolChipStyle>
      <ToolChipIconStyle onMouseEnter={() => setHintShow(true)} onMouseLeave={() => setHintShow(false)} onClick={() => setHintShowClick(!hintShowClick)}>?</ToolChipIconStyle>
      {hintShow || hintShowClick ?
        (<ToolChipContentStyle placement={placement} width={width} responsivePlacement={responsivePlacement}>{content}</ToolChipContentStyle>) :
        (<></>)
      }
    </ToolChipStyle>
  );
};

ToolChip.defaultProps = {
  placement: 'center',
  width: 400,
  responsivePlacement: 'left'
};

