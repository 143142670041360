import React from 'react';

const EntryStepBar = ({ children, data_mborder }) => {

  return (
    <li className='step_bar' data_mborder={data_mborder}>
      <div className='step_bar_inner'>
        {children}
      </div>
    </li>
  )
}

EntryStepBar.defaultProps = {
  data_mborder: 1,
}

export default EntryStepBar