import styled from 'styled-components'
import { FlexBox } from '../../layouts/FlexBox'
import InquiryItemElement from './InquiryItemElement'
import { colors } from '../../../css/variables/colors';
import noticeImg from '../../../images/inquiryNaviBar.svg';
import { mixins } from '../../../css/variables/mixins';

const InquiryCommentWrapper = styled.div`
  max-width:500px;
  position:fixed;
  right:0px;
  bottom:0px;
  background-color:${colors.themeColor('background_whitegray')};
  border-radius: 4px 4px 0px 0px;
  z-index:100;
  ${mixins.screenUnder()}{
	display: none;
  }
`
const InquiryModalWrapper = styled.div`
  padding:10px 10px 10px 20px;
  background-color:${colors.themeColor('background_whitegray')};
`
const InquiryModalP = styled.p`
  margin-bottom:0px;
  color:${colors.themeColor('primary_dark')};
`
const InquiryCountWrapper = styled.span`
  background-color:${colors.themeColor('primary_dark')};
  color:${colors.themeColor('text_white')};
  border-radius: 20px;
  width:20px;
  height:20px;
  display:inline-block;
  text-align:center;
`
const ToggleBtnStyle = styled.div`
  width: 26px;
  height: 26px;
  margin-left: auto;
  cursor: pointer;
`
const NoticeImgStyle = styled.div`
  background-color:${colors.themeColor('background_whitegray')};
  text-align:center;
`
const InquirySlideInElement = ({inquiryCount,naviToggleChangeHandler,NaviFlag,InquiryCommentData,serchInquiryData,accordionNum}) => {
	
	return(
		<InquiryCommentWrapper>
			<InquiryModalWrapper>
				<FlexBox>
					<InquiryModalP>
						お申し込みの不備をご確認ください <InquiryCountWrapper>{inquiryCount.active}</InquiryCountWrapper>
					</InquiryModalP>
					<ToggleBtnStyle onClick={naviToggleChangeHandler}>
						<NoticeImgStyle>
							<img src={noticeImg} alt='noticeImg'/>
						</NoticeImgStyle>
					</ToggleBtnStyle>
				</FlexBox>
			</InquiryModalWrapper>
			{NaviFlag && InquiryCommentData.map((data, index) => {
				// ステータスがCLOSEDの場合リターン
				if(serchInquiryData(Object.values(data)[0].ticket_id).status === "CLOSED") return <></>
				return (
					<div key={index}>
						<InquiryItemElement
							inquiryData={serchInquiryData(Object.values(data)[0].ticket_id)}
							inquiryComment={data}
							accordionOpen={accordionNum}
							navigation
						/>
					</div>  
				)
			})}
		</InquiryCommentWrapper>
	)
}

export default InquirySlideInElement