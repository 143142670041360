import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { mixins } from '../../css/variables/mixins';

const Wrapper = styled.div`
${props => props.width ? 'max-width:' + props.width + 'px' : 'max-width:580px'};
${props => props.center ? 'text-align:center' : ''};
${props => props.margin_tb ? 'margin:' + props.margin_tb+ 'px 0px ' : 'margin:auto'};
background: ${colors.themeColor('background_main')};
border-radius: 8px;
border: 1px solid ${colors.themeColor('border_box')};
${props => props.padding ? 'padding:' + props.padding + 'px ' : 'padding:40px 48px;'};
${mixins.screenUnder()}{
  padding: 28px 16px;
}

`

export const FormWrap = ({ children, width, margin_tb, padding, center, refProp }) => (
  <Wrapper width={width} margin_tb={margin_tb} padding={padding} center={center} ref={refProp}>
    {children}
  </Wrapper>
);

/*
FormWrap.defaultProps = {
  white: false
};
*/
