import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';
import { ToolChip } from './ToolChip';


const FormTitleStyle = styled.div`
  margin-top: ${props => props.mt}px;
  display: flex;
`;
const FormTitleTextStyle = styled.span`
  font-weight :bold ;
  ${props => props.size==='small'?'font-size:'+fonts.fontSizeRem('ms'):null};
`;
const RequiredStyle = styled.span`
  font-size:${fonts.fontSizeRem('xxs')};
  color: ${colors.themeColor('text_white')};
  background: ${colors.themeColor('background_required')};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: text-bottom;
  word-break: keep-all;
`;
const ToolChipWrapStyle = styled.span`
  display: inline-block;
  margin-left: 8px;
  vertical-align: text-bottom;
`;

export const FormTitle = ({ size, required, toolChip, mt, children, toolChipWidth, toolChipPlacement, responsiveToolChipPlacement }) => {
  return (
    <FormTitleStyle mt={mt}>
      <div>
        <FormTitleTextStyle size={size}>{children}</FormTitleTextStyle>
        {required ? (
          <RequiredStyle>必須</RequiredStyle>
        ) : (<></>)}
      </div>
      {toolChip ? (
        <ToolChipWrapStyle>
          <ToolChip content={toolChip} width={toolChipWidth} placement={toolChipPlacement} responsivePlacement={responsiveToolChipPlacement}/>
        </ToolChipWrapStyle>
      ) : (<></>)}
    </FormTitleStyle>
  );
};

FormTitle.defaultProps = {
  required: false,
  size:'normal',
  mt: 32,
  toolChipWidth: 400,
  toolChipPlacement: 'left',
  responsiveToolChipPlacement: 'left'
};

