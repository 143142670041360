import styled from "styled-components"
import { Container } from "../../components/layouts/Container"
import { FormWrap } from "../../components/layouts/FormWrap"
import { colors } from "../../css/variables/colors"
import { fonts } from "../../css/variables/fonts"
import { mixins } from "../../css/variables/mixins"

const PhraseWrapper = styled.div`
    background-color:${colors.themeColor('background_whitegray')};
    padding: 16px 40px;
    margin-bottom: 24px;
    p{
        margin-bottom:0px;
        font-weight:bold;
    }
`
const TitleStyle = styled.p`
    font-weight: bold;
    font-size: ${fonts.fontSizeRem('xxl')};
    ${mixins.screenUnder()}{
      font-size:${fonts.fontSizeRem('m')};
    }
`
const BodyStyle = styled.p`
    ${mixins.screenUnder()}{
        font-size:${fonts.fontSizeRem('s')};
    }
    
`
const ExplainPhrase = styled.p`
    font-size:${fonts.fontSizeRem('ms')};
    margin-bottom: 0px;
    ${mixins.screenUnder()}{
        font-size:${fonts.fontSizeRem('xxs')};
    }
`

const ResetPasswordMailCompleted = () => {
    return(
        <Container>
            <FormWrap center>
                <TitleStyle>メールをご確認ください。</TitleStyle>
                <PhraseWrapper>
                    <BodyStyle>ご入力いただいたメールアドレスが当社のデータベースにある場合、メールでパスワード再設定用のリンクを送信いたします。</BodyStyle>
                </PhraseWrapper>
                <ExplainPhrase>メールが届かない場合は、</ExplainPhrase>
                <ExplainPhrase>メールアドレスをご確認の上再度お送りいただくか、</ExplainPhrase>
                <ExplainPhrase>迷惑メールフォルダをご確認頂きますようお願いいたします。</ExplainPhrase>
            </FormWrap>
        </Container>
    )
}

export default ResetPasswordMailCompleted