import styled from "styled-components"
import { mixins } from "../../css/variables/mixins"

const LinkStyled = styled.a`
    font-size: 15px;
    ${mixins.screenUnder()}{
        font-size: 12px;
    }
`


const AnotherTabLink = ({children, href}) => {
    
    return(
        <LinkStyled target="_blank" rel="noopener noreferrer" href={href} >{children}</LinkStyled>
    )
}
export default AnotherTabLink