import { FormWrap } from '../../layouts/FormWrap';
import { TextField, Button, ErrorMessage } from '../../ui-parts';
import { Box } from '../../layouts/Box';

const ChangePasswordElement = ({ setCurrentPasswordHandler, setNewPasswordHandler, setConfirmPasswordHandler, sendPasswordHandler, ApiErrMsg, ErrMsgs }) => {
  return (
      <FormWrap>
        <div className='align_left'>
          <h2 className="mt0 mb0 ml4">パスワード変更</h2>
          <hr className="mt0 mb0" />
          <ErrorMessage ApiErrMsg={ApiErrMsg} />
          <p className="align_left bold ml4 mt40 mb0 font-s">現在のパスワード</p>
          <TextField
            iserror={ErrMsgs.currentPassword}
            id='textfield1'
            weight='bold'
            fullwidth
            type='password'
            onChange={setCurrentPasswordHandler} />
        </div>
        <p className="align_left bold ml4 mt32 mb0 font-s">新しいパスワード</p>
        <TextField
          iserror={ErrMsgs.confirmPassword}
          id='textfield2'
          label='半角英数字と英字大文字,記号を含む１２文字以上入力してください'
          margin={'none'}
          fullwidth
          type='password'
          onChange={setNewPasswordHandler} />
        <TextField
          iserror={ErrMsgs.confirmPasswordUnder}
          id='textfield3'
          label='もう一度入力してください'
          fullwidth
          type='password'
          onChange={setConfirmPasswordHandler} />
        <Box mt={32}>
          <Button variant='primary' onClick={sendPasswordHandler} fullwidth_mobile={true}>パスワードを変更する</Button>
        </Box>
      </FormWrap>
  )
}
export default ChangePasswordElement;