import styled from "styled-components"
import { colors } from "../../../css/variables/colors"

const Wrapper = styled.div`
  padding: 0 17px;
  background: ${colors.themeColor('background_menu_hover')};
  border-bottom: 1px solid #B9BECD;
`

const ContainerPopUpWrapper = ({children}) =>(
    <Wrapper>
        {children}
    </Wrapper>
)

export default ContainerPopUpWrapper