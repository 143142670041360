import { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { PostMethod } from '../api/Api.js';
import { confirmPasswordValidate, singlePasswordValidate } from "../validators/publicValidator"
import { setIsLoading } from "../function/setIsLoading.js";
import { Context } from "../stores/Provider.js";

const useResetPasswordHooks = () => {
    const { dispatch} = useContext(Context)
    const [ currentPassword, setCurrentPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ ErrMsgs, setErrMsgs ] = useState({ currentPassword: '', confirmPassword: '', confirmPasswordUnder:false});
    const navigate = useNavigate();

    // 変数返却
    const CurrentPasswordData = currentPassword;
    const NewPasswordData = newPassword;
    const ConfirmPasswordData = confirmPassword;

    //パスワード変更API実行
    const sendPassword = async (currentPassword, newPassword, confirmPassword) => {

        const validateCurrentPassword = singlePasswordValidate(currentPassword)
        const validateConfirmPassword = confirmPasswordValidate(newPassword, confirmPassword)
        setErrMsgs({ currentPassword: '', confirmPassword: '', confirmPasswordUnder:false})

        //バリデーション実行
        if (!validateCurrentPassword.isValidate || !validateConfirmPassword.isValidate) {
            const errorMessage = {}
            errorMessage.currentPassword = validateCurrentPassword.message;
            errorMessage.confirmPassword = validateConfirmPassword.message;
            errorMessage.confirmPasswordUnder = validateConfirmPassword.message === "" ? false : true
            setErrMsgs(errorMessage)
            return
        }

        const data = { 
            "proposed_password": newPassword,
            "previous_password" : currentPassword
        }

        //ローディング開始
        setIsLoading(true, dispatch)
        //API通信
        const response = await PostMethod(data, '/change_password');

        return response
    }

    //正常時遷移先
    const normal = () => {
        //ローディング終了
        setIsLoading(false, dispatch)
        return navigate('/dashboard', {state:{routeTo:'dashboard'}})
    }
    //異常時遷移先
    const abnormal = () => {
        //ローディング終了
        setIsLoading(false, dispatch)
        return navigate('/change_password', {state:{routeTo:'change_password'}})
    }

    //現在のパスワードセットハンドラ
    const setCurrentPasswordHandler = (e) => {
        return setCurrentPassword(e.target.value)
    }
    //新しいパスワードセットハンドラ
    const setNewPasswordHandler = (e) => {
        return setNewPassword(e.target.value)
    }
    //確認パスワードセットハンドラ
    const setConfirmPasswordHandler = (e) => {
        return setConfirmPassword(e.target.value)
    }

    return {
        sendPassword, CurrentPasswordData, NewPasswordData, ConfirmPasswordData, normal, abnormal,
        setCurrentPasswordHandler, setNewPasswordHandler, setConfirmPasswordHandler, ErrMsgs, setErrMsgs
    }
}

export default useResetPasswordHooks;