import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { InquiryNavItem } from '../../ui-parts';
import { Link } from 'react-router-dom';
import noticeImg from '../../../images/icon_notice.svg';
import noticeActiveImg from '../../../images/icon_notice_active.svg';
import ContainerPopUpWrapper from '../../ui-parts/ContainerPopUp/ContainerPopUpWrapper';
import ContainerPopUpTitleWrapper from '../../ui-parts/ContainerPopUp/ContainerPopUpTitleWrapper';
import ExclamationIcon from '../../ui-parts/ExclamationIcon';

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TitleStyle = styled.h1`
  font-size: ${fonts.fontSizeRem('ms')};
  text-decoration: underline;
  margin: 0;
`

const ToggleBtnStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-left: auto;
  border-radius: 4px;
  border: solid 1px ${colors.themeColor('border_box')};
  cursor: pointer;
  background: ${colors.themeColor('background_main')};
`

const NoticeImgStyle = styled.div`
  height: 15.51px;
  width: 16.5px;
  background: url(${noticeImg}) no-repeat center;
`

const NoticeActiveImgStyle = styled.div`
  width: 15px;
  height: 2px;
  background: url(${noticeActiveImg}) no-repeat center;
`

const BottomWrapperStyle = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`
const IconBottomStyle = styled.div`
  width: 22px;
  height: 22px;
  background: ${colors.themeColor('background_error')};
  color: ${colors.themeColor('text_white')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  p {
    margin: 0;
  }font-size: ${fonts.fontSizeRem('ms')};
`
const BottomTextStyle = styled.p`
  font-size: ${fonts.fontSizeRem('xxs')};
  ${props => props.exception ? 'margin: 0 0 0 0' : 'margin: 0 0 0 8px'};
`


const InquiryNavElement = ({InquiryData,Toggle,toggleChangeHandler,inquiryApiErrMsg,nonClosedCount}) => {

 
  return (
    <ContainerPopUpWrapper>
      <ContainerPopUpTitleWrapper>
        <TopWrapper>
          <Link to='/inquiry' state={{routeTo:'inquiry'}}><TitleStyle>お知らせ：申し込み内容のご確認</TitleStyle></Link>
          
          <ToggleBtnStyle onClick={toggleChangeHandler}>
            {Toggle ?  <NoticeImgStyle /> : <NoticeActiveImgStyle />}
          </ToggleBtnStyle>
        </TopWrapper>
        <BottomWrapperStyle>
          {nonClosedCount !== 0 && <ExclamationIcon><p>！</p></ExclamationIcon>}
          {(inquiryApiErrMsg === "" && nonClosedCount !== 0)&&
            <BottomTextStyle>確認項目が{nonClosedCount}件あります。</BottomTextStyle>
          }
          {(inquiryApiErrMsg === "" && nonClosedCount === 0)&&
            <BottomTextStyle>現在、返信が必要な確認項目はありません。</BottomTextStyle>
          }
        </BottomWrapperStyle>
      </ContainerPopUpTitleWrapper>
      {Toggle &&
        InquiryData.map((data, index) => (
          <InquiryNavItem
            key={index}
            inquiryItemData={data}
          />
        ))
      }
      
    </ContainerPopUpWrapper>
  );
};

export default InquiryNavElement;
