import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';

const ButtonStyle = styled.button`
  background: transparent;
  border: none;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
  img{
    display: block;
  }
  &:hover{
    background: ${colors.themeColor('background_whitegray')};
  }
`

export const IconButton = ({imgUrl}) => {
  return (
    <ButtonStyle>
      <img src={imgUrl} alt='IconButton' />
    </ButtonStyle>
  );
};

IconButton.defaultProps = {

};

