import ErrorBoundary from "../../api/ErrorBoundary";
import NotFound from "../rederPages/NotFound";
import useGTMHooks from "../../hooks/useGTMHooks";
import { useEffect } from "react";

const NotFoundBeforeRender = () => {
  const { pushDataLayerHandler } = useGTMHooks()
  const notFoundFlag = true

  useEffect(()=>{
    pushDataLayerHandler(notFoundFlag)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    return(
      <ErrorBoundary>
        <NotFound/>
      </ErrorBoundary>
    )
}

export default NotFoundBeforeRender