import React from 'react';
import styled from 'styled-components'
import { mixins } from '../../css/variables/mixins';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';

const FooterWrapper = styled.footer`
`;
const FooterBottom = styled.div`
  border-top: 1px solid ${colors.themeColor('border_box')};
  font-size:10px;
  color: ${colors.themeColor('text_gray')};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const FooterMenu = styled.div`
  border-top: 1px solid ${colors.themeColor('border_box')};
  ul{
    height: 54px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 20px;
    a{
      font-size: ${fonts.fontSizeRem('ms')};
      ${mixins.screenUnder()}{
      font-size: ${fonts.fontSizeRem('s')};
    }
    }
  }
`

export const Footer = ({ hasFooterMenu }) => (
  <FooterWrapper>
    {hasFooterMenu ? (
      <FooterMenu>
        {/* <ul>
          <li><a href="">会社概要</a></li>
          <li><a href="">利用規約</a></li>
          <li><a href="">プライバシーポリシー</a></li>
        </ul> */}
      </FooterMenu>
    ) : (
      <></>
    )}
    <FooterBottom>
      Copyright（C） ZEUS CO.,LTD.All Rights Reserved.
    </FooterBottom>
  </FooterWrapper>
);


Footer.defaultProps = {
  hasMenu: false
};
