import { useContext, useMemo, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PostMethod } from "../api/Api"
import { entryAPIForm } from "../constance/constance"
import { itemValidators } from "../validators/validationPattern"
import { getSession, setBankAccountInfoData, setEntryCheckInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setShopInfoData, setUserInfoData } from "../sessionStorage/sessionStorageMethod"
import { Context } from "../stores/Provider"
import { blankValidate } from "../validators/publicValidator"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"

const useEntryAPIHooks = () => {
	const navigate = useNavigate();
	const {state, dispatch} = useContext(Context)
	const location = useLocation()

	//セッションデータ抽出
	const userInfoData = getSession('user')
	const agentMasterInfoData = getSession('agent_master')
	const merchantSessionData = getSession('merchant')
	const merchantInfoData = state.entry.merchant
	const bankAccountInfoData = state.entry.bank_account
	const representativeInfoData = state.entry.representative
	const operationInfoData = state.entry.operation
	const economicInfoData = getSession('economic_condition')
	const fileInfoData = state.entry.files
	const shopInfoData = state.entry.shop
	const entryCheckInfoData = getSession('entry_check')
	const ObjToDevide = state.entry.review_contents_division

	//画面のパス
	const path = useMemo(()=>{
		const pathArray = location.pathname.split('/')
		return pathArray[pathArray.length-1]
	},[location.pathname])

	//確認画面からの遷移か確認
	const fromConfirmFlag = location.state ? location.state.confirm_flag : false

	//データをAPI用に成型
	//店舗情報_郵便番号
	const merchantInsert = useMemo(() => {
		// 郵便番号が上下両方入っていない時は空で送る
		const merchantPostCode = (merchantInfoData.merchant_register_post_code3 !== "" && merchantInfoData.merchant_register_post_code4 !== "") ? merchantInfoData.merchant_register_post_code3 + '-' + merchantInfoData.merchant_register_post_code4 : "" 
		const merchantAnnualBusiness = merchantInfoData.merchant_annual_business === "" ? "" : Number(merchantInfoData.merchant_annual_business)
		const merchantWebsiteUrl = economicInfoData.cvs.system_setting.select_flag ? merchantInfoData.merchant_website_url : ""
		const merchantInsert = {
			...merchantInfoData,
			merchant_register_post_code: merchantPostCode,	
			merchant_annual_business: merchantAnnualBusiness,
			merchant_website_url: merchantWebsiteUrl 		
		}

		return merchantInsert
	}, [merchantInfoData,economicInfoData])

	//代表者情報_郵便番号・西暦
	const representativeInsert = useMemo(() => {
		const representationBirthday = representativeInfoData.representative_birthday_CE + '-' + representativeInfoData.representative_birthday_month + '-' + representativeInfoData.representative_birthday_day
		const representativeInsert = {
			...representativeInfoData,
			representative_birthday: representationBirthday,
		}
		return representativeInsert
	}, [representativeInfoData])

	//全角英数字から半角英数字への変換
    const alphabetHalfWidthChangeHandler = useCallback((data) => {
        return data.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
		});
    },[])

	//店舗情報用リコイルデータまとめる（空欄バリデーション用）
	const arrangeMerchantData = useMemo(() => {
		const unionData = {
			...merchantInsert,
			...representativeInsert,
			...operationInfoData,
			identity_doc_images:fileInfoData.identity_doc_images,
		}
		return unionData
	}, [merchantInsert, representativeInsert, operationInfoData, fileInfoData])

	//口座情報用リコイルデータをまとめる（空欄バリデーション用）
	const arrangeBankAccountData = useMemo(() => {

		const unionData = {
			...bankAccountInfoData,
			passbook_images:fileInfoData.passbook_images,
		}
		return unionData
	}, [bankAccountInfoData, fileInfoData])

	//ショップ情報用リコイルデータをまとめる（空欄バリデーション用）
	const arrangeShopData = useMemo(() => {

		const unionData = {
			...shopInfoData,
			antique:fileInfoData.antique, 
			license:fileInfoData.license,
			examination_document:fileInfoData.examination_document
		}
		return unionData
	}, [shopInfoData, fileInfoData])

	//ページごとにリコイルのデータを変更（空欄バリデーション用）
	const devideRecoilByPages = (page) => {
		const devision = page !== undefined ? page : path;
		switch (devision) {
			case 'merchant':
				return arrangeMerchantData
			case 'bank_account':
				return arrangeBankAccountData
			case 'shop':
				return arrangeShopData
			default:
				return {}
		}
	}

	//商品コンテンツの出し分けで表示されない項目にnullをセット
	const setReviewContentsNullHandler = useCallback((shopData, deivdeData, merchantData) => {
		const setNameObj = {
			antique_flag: ["shop_addition_antique_flag"],
			btob_flag: ["shop_addition_btob_code"],
			estimate_flag: ["shop_addition_estimated_product_flag"],
			online_local_flag: ["shop_addition_online","shop_addition_offline"],
			prepaid_continuous_flag: ["shop_addition_prepaid_flag"],
			purchase_points_flag: ["shop_addition_point_purchase_flag"],
			real_store_flag: ["shop_addition_physical_store_flag","shop_addition_physical_store_url_flag","shop_addition_physical_store_url"],
			rental_flag: ["shop_addition_rental_flag","shop_addition_rental_product_url"],
			reserve_item_flag: ["shop_addition_reserved_product_flag","shop_addition_reservation_period_flag"],
			subscription_flag: ["shop_addition_subscription_flag","shop_addition_subscription_code"],
		}
		let resultObj = {}
		for(let flagName in setNameObj){
			const shopItemArray = setNameObj[flagName]
			//出し分けフラグがtrueの時、すでに入っているデータを入れる
			if(flagName === 'real_store_flag') continue
			if(deivdeData[flagName]){
				shopItemArray.forEach((itemName)=>{
					const shopItemValue = shopData[itemName]
					resultObj[itemName] = shopItemValue	
				})
			//falseの場合、nullを入れる。
			} else {
				shopItemArray.forEach((itemName)=>{
					resultObj[itemName] = null	
				})
			}	
		}
		//実店舗のみ条件が多いため別処理
		if(deivdeData.real_store_flag && merchantData.merchant_type === '2'){
			setNameObj.real_store_flag.forEach((itemName)=>{
				const shopItemValue = shopData[itemName]
				resultObj[itemName] = shopItemValue	
			})
		} else {
			setNameObj.real_store_flag.forEach((itemName)=>{
				resultObj[itemName] = null	
			})
		}
		return resultObj
	},[])

	//バリデートされていないデータのメッセージを空欄にする
	const novalidateMessage = (validateData, originValidateData) => {
		const validateProps = Object.keys(validateData)
		const originProps = Object.keys(originValidateData)
		const comparisonProps = originProps.filter(i => validateProps.indexOf(i) === -1)
		let noValidateData = {}
		comparisonProps.forEach((key) => {
			noValidateData = { ...noValidateData, [key]: { isValidate: '', message: '' } }
		})
		const validateResult = {
			...validateData,
			...noValidateData
		}
		return validateResult
	}
	//店舗情報用バリデーション
	const merchantValidator = () => {
		const merchantValidateData = state.validateMessages.merchant
		let validateData = {}
		const merchantArray = ["merchant_name","merchant_name","merchant_name_kana","merchant_last_name","merchant_first_name","merchant_last_name_kana","merchant_first_name_kana","merchant_phone_number_before","merchant_phone_number_middle","merchant_phone_number_after","merchant_register_post_code3","merchant_register_post_code4","merchant_address_municipality","merchant_address_municipality_kana","merchant_address_streetbunch","merchant_address_streetbunch_kana","merchant_address_building","merchant_address_building_kana","merchant_website_url","merchant_annual_business","merchant_shop_category","merchant_corporate_number",]
		const representativeArray = ["representative_last_name","representative_first_name","representative_last_name_kana","representative_first_name_kana",]
		const oparatorArray = ["operator_last_name","operator_first_name","operator_last_name_kana","operator_first_name_kana","operator_email","operator_phone_number_before","operator_phone_number_middle","operator_phone_number_after","support_operator_email","support_operator_phone_number_before","support_operator_phone_number_middle","support_operator_phone_number_after",]
		const fileArray = ["identity_doc_images"]
		merchantArray.forEach((item)=>{
			validateData[item] = itemValidators("merchant",item,merchantInfoData[item])
		})
		representativeArray.forEach((item)=>{
			validateData[item] = itemValidators("merchant",item,representativeInfoData[item])
		})
		oparatorArray.forEach((item)=>{
			validateData[item] = itemValidators("merchant",item,operationInfoData[item])
		})
		fileArray.forEach((item)=>{
			validateData[item] = itemValidators("merchant",item,fileInfoData[item])
		})
		const validateResult = novalidateMessage(validateData, merchantValidateData)

		dispatch({
			type: 'set-validate-merchant',
			payload: {
				...validateResult
			}
		})
		return validateResult
	}

	//口座情報用バリデーション
	const bankAccountValidator = () => {
		const bankAccountValidateData = state.validateMessages.bank_account
		const bankArray = ["bank_account_bank_name","bank_account_bank_branch","bank_account_number","bank_account_name"]
		const fileArray = ["passbook_images"]
		let validateData = {}
		bankArray.forEach((item)=>{
			if(item === 'bank_account_bank_branch'){
				validateData[item] = itemValidators('bank_account', item, bankAccountInfoData,state)
			} else {
				validateData[item] = itemValidators('bank_account', item, bankAccountInfoData[item],state)
			}
		})
		fileArray.forEach((item)=>{
			validateData[item] = itemValidators('bank_account', item, fileInfoData[item])
		})
		const validateResult = novalidateMessage(validateData, bankAccountValidateData)
		dispatch({
			type: 'set-validate-bank_account',
			payload: {
				...validateResult
			}
		})
		return validateResult
	}

	//ショップ情報用バリデーション
	const shopValidator = () => {
		const shopValidateData = state.validateMessages.shop
		const shopArray = ["account_id","shop_name","shop_name_kana","shop_name_alphabet","shop_addition_shop_website_check_url","shop_addition_business_deal_url","shop_cvs_website_name","shop_cvs_website_name_kana","shop_website_url","shop_addition_price_range_min","shop_addition_price_range_max","shop_addition_price_validate","shop_addition_product_content_freetext","shop_addition_rental_product_url","shop_addition_physical_store_url","shop_addition_business_deal_url","shop_addition_subscription_code","shop_atobarai_customer_notice_store_name","shop_addition_shop_website_password","shop_addition_shop_website_id"]
		const fileArray = ["antique","examination_document","rental","license"]
		let validateData = {}
		shopArray.forEach((item)=>{
			validateData[item] = itemValidators('shop', item, shopInfoData[item], shopInfoData)
		})
		fileArray.forEach((item)=>{
			validateData[item] = itemValidators('shop', item, fileInfoData[item])
		})
		const validateResult = novalidateMessage(validateData, shopValidateData)
		dispatch({
			type: 'set-validate-shop',
			payload: {
				...validateResult
			}
		})
		return validateResult
	}
	//バリデーション成功判定
	const validateSuccessJudge = (validateData, page) => {
		let validateFlag =false
		const devision = page !== undefined ? page : path
		//対象外のバリデーションを無視
		let checkData = [];
		if(devision === 'merchant'){
			checkData.push(
                'merchant_type','merchant_phone_number_before','merchant_phone_number_middle','merchant_phone_number_after','merchant_register_post_code3','merchant_register_post_code4','merchant_address_prefectures','merchant_address_municipality','merchant_address_municipality_kana','merchant_address_streetbunch','merchant_address_streetbunch_kana','merchant_address_building','merchant_address_building_kana','merchant_annual_business','merchant_shop_category','operator_last_name','operator_last_name_kana','operator_first_name','operator_first_name_kana','operator_email','operator_phone_number_before','operator_phone_number_middle','operator_phone_number_after','support_operator_email','support_operator_phone_number_before','support_operator_phone_number_middle','support_operator_phone_number_after',
            )
			if(merchantInfoData.merchant_type === '1'){
				checkData.push(
					'merchant_name','merchant_name_kana','merchant_corporate_number','representative_last_name','representative_first_name','representative_last_name_kana','representative_first_name_kana','representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day',
				)
			} else if (merchantInfoData.merchant_type === '2'){
				checkData.push(
					'identity_doc_images','merchant_last_name','merchant_first_name','merchant_last_name_kana','merchant_first_name_kana','representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day'
				)
			}
			if(economicInfoData.cvs.system_setting.select_flag === true){
				checkData.push('merchant_website_url')
			}
		}
		if(devision === 'bank_account'){
			checkData.push(
				'passbook_images','bank_account_bank_name','bank_account_bank_branch','bank_account_category','bank_account_number','bank_account_name',
			)
		}
		if(devision === 'shop'){
			checkData.push(
				'shop_name','shop_name_kana','shop_name_alphabet','shop_website_url','shop_addition_site_status_code','shop_addition_conduct_start_yyyy','shop_addition_conduct_start_mm','shop_addition_product_content_code','shop_addition_major_content_code','shop_addition_medium_content_code','shop_addition_product_content_freetext','shop_addition_available_product_flag','shop_addition_non_infringing_product_presence_absence_flag','shop_addition_non_administrative_penalties_five_year_flag','shop_addition_non_losing_judgment_flag',
			)
			if(agentMasterInfoData.account_id_flag){
				checkData.push('account_id')
			}
			if(shopInfoData.shop_addition_site_status_code === 0){
				checkData.push('shop_addition_site_status_method')
				if(shopInfoData.shop_addition_site_status_method === 0){
					checkData.push('shop_addition_business_deal_url','shop_addition_shop_website_check_url')
				}else if(shopInfoData.shop_addition_site_status_method === 1){
					checkData.push('examination_document')
				}
			}
			if(economicInfoData.cvs.system_setting.select_flag){
				checkData.push('shop_cvs_website_name','shop_cvs_website_name_kana','shop_addition_price_range_min','shop_addition_price_range_max','shop_addition_price_validate')
			}
			if(economicInfoData.atobarai.system_setting.select_flag){
				checkData.push('shop_atobarai_customer_notice_store_name')
			}
			if(ObjToDevide.antique_flag){
				checkData.push('shop_addition_antique_flag',)
				if(shopInfoData.shop_addition_antique_flag){
					checkData.push('antique',)
				}
			}
			if(ObjToDevide.subscription_flag){
				checkData.push('shop_addition_subscription_flag',)
				if(shopInfoData.shop_addition_subscription_flag){
					checkData.push('shop_addition_subscription_code')
				}
			}
			if(ObjToDevide.purchase_points_flag){
				checkData.push('shop_addition_point_purchase_flag',)
			}
			if(ObjToDevide.prepaid_continuous_flag){
				checkData.push('shop_addition_prepaid_flag',)
			}
			if(ObjToDevide.reserve_item_flag){
				checkData.push('shop_addition_reserved_product_flag',)
				if(shopInfoData.shop_addition_reserved_product_flag){
					checkData.push('shop_addition_reservation_period_flag')
				}
			}
			if(ObjToDevide.estimate_flag){
				checkData.push('shop_addition_estimated_product_flag',)
			}
			if(ObjToDevide.rental_flag){
				checkData.push('shop_addition_rental_flag')
				if(shopInfoData.shop_addition_rental_flag){
					//shop_addition_rental_product_urlはAPIHooksのみ（EntrySideHooksには追加なし）
					checkData.push('shop_addition_rental_validate','shop_addition_rental_product_url')
				}
			}
			if(ObjToDevide.online_local_flag){
				checkData.push('shop_addition_offer_place')
			}
			if(ObjToDevide.btob_flag){
				checkData.push('shop_addition_btob_code',)
			}
			if(ObjToDevide.real_store_flag && merchantInfoData.merchant_type === "2"){
				checkData.push('shop_addition_physical_store_flag')
				if(shopInfoData.shop_addition_physical_store_flag){
					checkData.push('shop_addition_physical_store_url_flag')
					if(shopInfoData.shop_addition_physical_store_url_flag){
						checkData.push('shop_addition_physical_store_url')
					}
				}
			}
			if(ObjToDevide.license_flag){
				checkData.push('license')
			}
		}
		checkData.forEach((key) => {
			if(validateData[key].message !== '') validateFlag = true
		})
		if(validateFlag) return false

		return true
	}

	//先頭のスペースを削除する関数
	const topSpaceDeleteHandler = (data) => {
		let newObj = {}
		for(let item in data){
			let newData = data[item]
			if(typeof(data[item]) !== "string"){
				newObj[item] = newData
				continue
			} 
			if(data[item].indexOf(' ') === 0){
				newData = data[item].replace(' ','')
			}
			newObj[item] = newData
		}
		return newObj
	}

	//空欄バリデート
	const blankValidator = (validateData, page) => {
		const devision = page !== undefined ? page : path
		const recoilByPages = devideRecoilByPages(devision);
		const { blankData } = blankValidate(recoilByPages, validateData,devision)
		dispatch({
			type: `set-validate-${devision}`,
			payload: {
				...blankData
			}
		})
		return { blankData }
	}
	//項目バリデート
	const itemValidator = (page) => {
		const devision = page !== undefined ? page : path
		switch (devision) {
			case 'merchant':
				return merchantValidator();
			case 'bank_account':
				return bankAccountValidator();
			case 'shop':
				return shopValidator();
			default:
				return
		}
	}
	// 申し込みショップデータ成型
	const shopInsert = useMemo(()=>{
		const shopNameAlphabet = alphabetHalfWidthChangeHandler(shopInfoData.shop_name_alphabet)
		const deivdeItemObj = setReviewContentsNullHandler(shopInfoData,ObjToDevide,merchantSessionData)
		const shopNameAtobarai = economicInfoData.atobarai.system_setting.select_flag ? (shopInfoData.shop_atobarai_customer_notice_store_name !== "" ? shopInfoData.shop_atobarai_customer_notice_store_name.replaceAll('‐','－') : "") : ""
		const shopCvsWebsiteName = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name : ""
		const shopCvsWebsiteNameKana = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name_kana : ""
		const shopAdditionPriceRangeMax = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_max : null
		const shopAdditionPriceRangeMin = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_min : null

		const shopInsert = {
			...shopInfoData,
			...deivdeItemObj,
			shop_name_alphabet:shopNameAlphabet,
			shop_atobarai_customer_notice_store_name:shopNameAtobarai,
			shop_cvs_website_name:shopCvsWebsiteName,
			shop_cvs_website_name_kana:shopCvsWebsiteNameKana,
			shop_addition_price_range_max:shopAdditionPriceRangeMax,
			shop_addition_price_range_min:shopAdditionPriceRangeMin,
		}

		return shopInsert
	},[shopInfoData,setReviewContentsNullHandler,alphabetHalfWidthChangeHandler,merchantSessionData,ObjToDevide,economicInfoData])

	//申し込みAPI送信用データをまとめる
	const setMainData = () => {
		let mainData = {
			// ...entryAPIForm,
			...userInfoData,	
			status:state.user_status.status,
		};
		switch(path){
			case 'merchant':
				let preInsertMerchantData = {
					// ...entryAPIForm.merchant,
					...merchantInsert,
					...representativeInsert,
					...operationInfoData,

				}
				let insertMerchantData = topSpaceDeleteHandler(preInsertMerchantData)
				mainData = {
					...mainData,
					merchant:{
						merchant:insertMerchantData
					},
				}
				break;
			case 'bank_account':
				let insertBankData = topSpaceDeleteHandler(bankAccountInfoData)
				mainData = {
					...mainData,
					merchant:{
						bank:insertBankData
					},
				}
				break;
			case 'shop':
				let preInsertShopData = {
					...entryAPIForm.shop,
					...shopInsert
				}
				let insertShopData = topSpaceDeleteHandler(preInsertShopData)
				mainData = {
					...mainData,
					shop:insertShopData,
				}
				break;
			default: return mainData
		}

		return mainData
	}

	// レスポンスデータを加盟店、銀行、ショップ情報にセット
	const setRecoilDataHandler = (response) => {
		switch(path){
			case 'merchant':
				let merchantObj = {}
				let representativeObj = {}
				let operatorObj = {}
				let representative_birthday_CE = ""
				let representative_birthday_month = ""
				let representative_birthday_day = ""
				for(let merchantItem in response.response_data.merchant){
					if(merchantItem.split('_')[0] === "merchant"){
						merchantObj = {...merchantObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
					if(merchantItem.split('_')[0] === "representative"){
						if(merchantItem === "representative_birthday"){
							representative_birthday_CE = response.response_data.merchant.representative_birthday.split('-')[0]
							representative_birthday_month = response.response_data.merchant.representative_birthday.split('-')[1]
							representative_birthday_day = response.response_data.merchant.representative_birthday.split('-')[2]
						}
						representativeObj = {...representativeObj,[merchantItem]:response.response_data.merchant[merchantItem], representative_birthday_CE:representative_birthday_CE,representative_birthday_month:representative_birthday_month, representative_birthday_day:representative_birthday_day}
					}
					if(merchantItem.split('_')[0] === "operator" || merchantItem.split('_')[0] === "support"){
						operatorObj = {...operatorObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
				}
				setMerchantInfoData({...merchantInfoData,...merchantObj})
				setRepresentativeInfoData({...representativeInfoData,...representativeObj})
				setOperationInfoData({...operationInfoData,...operatorObj})
				dispatch({
					type: 'set-entry-data',
					payload: {
						merchant: {...merchantInfoData,...merchantObj},
						representative: {...representativeInfoData,...representativeObj},
						operation: {...operationInfoData,...operatorObj}
					}
				})
				break;
			case 'bank_account':
				let bankObj = {}
				for(let merchantItem in response.response_data.merchant){
					if(merchantItem.split('_')[0] === "bank"){
						bankObj = {...bankObj,[merchantItem]:response.response_data.merchant[merchantItem]}
					}
				}
				setBankAccountInfoData({...bankAccountInfoData,...bankObj})
				dispatch({
					type: 'set-entry-bank-account',
					payload: {...bankAccountInfoData,...bankObj}
				})
				break;
			case 'shop':
				setShopInfoData({...shopInfoData,...response.response_data.shop})
				dispatch({
					type: 'set-entry-shop',
					payload: {...shopInfoData,...response.response_data.shop}
				})
				break;
			default: return
		}
		const insertUserData = {...userInfoData}
		if(response.response_data.entry_id !== undefined) insertUserData.entry_id = response.response_data.entry_id
		
		setUserInfoData(insertUserData)
	}

	// バリデーションをかけて、モーダルメッセージを表示
	const validateAndSetMessageHandler = () => {
		const pagesArray = ["merchant", "bank_account", "shop"]
		let validateSuccessObj = {
			merchant:false,
			bank_account:false,
			shop:false,
		};
		//全ページにバリデーション
		pagesArray.forEach((page)=>{
			let validateData = itemValidator(page);
			const { blankData } = blankValidator(validateData, page);
			if (validateSuccessJudge(blankData, page)) validateSuccessObj = {...validateSuccessObj, [page]:true}
		})

		//エラーメッセージ表示
		let errMsg = ["に入力エラーがあります。"];
		for(let key in validateSuccessObj){
			if(!validateSuccessObj[key]){
				switch(key){
					case 'shop':
						errMsg.unshift('ショップ情報')
						break;
					case 'bank_account':
						errMsg.unshift('口座情報')
						break;
					case 'merchant':
						errMsg.unshift('お客様情報')
						break;
					default:
						return
				}
			} 
		}
		let count = errMsg.length
		if(count > 1){
			if(count === 3) errMsg.splice(1,0,'・')
			if(count === 4){ 
				errMsg.splice(1,0,'・')
				errMsg.splice(3,0,'・')
			}
			let insertErrMsg = errMsg.join('')
			//コンテキストに保存
			dispatch({
				type : 'set-modal-flag',
				payload : {
						path : path,
						type : 'error',
						flag : true,
						message :insertErrMsg
					}
			})
		}
		return validateSuccessObj
	}

	//登録時のモーダル表示ハンドラ
	const setModalFlagHandler = (messageType,path,type) => {
		let message = ""
		if(messageType === 'entry'){
			if(path === 'merchant' && type === 'success') message = "お客様情報を保存しました"
			if(path === 'merchant' && type === 'error') message = "お客様情報の保存に失敗しました"
			if(path === 'bank_account' && type === 'success') message = "口座情報を保存しました"
			if(path === 'bank_account' && type === 'error') message = "口座情報の保存に失敗しました"
			if(path === 'shop' && type === 'success') message = "ショップ情報を保存しました"
			if(path === 'shop' && type === 'error') message = "ショップ情報の保存に失敗しました"
		} else if(messageType === 'validate'){
			if(path === 'merchant') message = 'お客様情報に入力エラーがあります。'
			if(path === 'bank_account') message = '口座情報に入力エラーがあります。'
			if(path === 'shop') message = 'ショップ情報に入力エラーがあります。'
		}
		dispatch({
			type : 'set-modal-flag',
			payload : {
					path : path,
					type : type,
					flag : true,
					message :message
				}
		})
	}

	//申し込み完了チェックマーク
	const setEntryCheckHandler = (path,status) => {
		if(path === "shop"){
			setEntryCheckInfoData({...entryCheckInfoData, merchant:status, bank_account:status, shop:status})
		} else {
			setEntryCheckInfoData({...entryCheckInfoData, [path]:status})
		}
	}

	//エントリデータ一時保存
	const EntryTemporaryPostMethod = async () => {
		//全体バリデーションエラーコメント消去
		dispatch({
			type: 'set-entry-validate',
			payload: {
				entryValidateMessage:""
			}
		})
		//一時保存用バリデーションをかける
		let itemValidateData = itemValidator();
		//バリデーションの判定
		if (!validateSuccessJudge(itemValidateData)){
			return setModalFlagHandler('validate',path,'error')
		} 
		const mainData = setMainData();
		//ローディング画面開始
        setIsLoading(true, dispatch)
		const response = await PostMethod(mainData, '/entry_info')
		if(response.code !== 200){
			if(response.code === 401){
				logoutAbnormalFunction(response,dispatch,navigate)
			} else {
				setModalFlagHandler('entry',path,'error')
			}
			//ローディング画面を非表示
			return setIsLoading(false,dispatch)	
		} 
		setModalFlagHandler('entry',path,'success')
		setRecoilDataHandler(response)
		//ローディング画面を非表示
        setIsLoading(false,dispatch)
	}

	//エントリデータ保存
	const EntryPostMethod = async () => {
		let navigateTo = ""
		
		//全体バリデーションエラーコメント消去
		dispatch({
			type: 'set-entry-validate',
			payload: {
				entryValidateMessage:""
			}
		})
		if(path === 'shop'){
			//バリデーション＆メッセージをコンテキストにセット
			const validateSuccessObj = validateAndSetMessageHandler()
			//バリデーション判定
			if(!validateSuccessObj.merchant || !validateSuccessObj.bank_account || !validateSuccessObj.shop){
				return
			} 
		} else {
			//本保存用バリデーションをかける
			let validateData = itemValidator();
			const { blankData } = blankValidator(validateData);
			//バリデーションの判定
			if (!validateSuccessJudge(blankData)){ 
				return setModalFlagHandler('validate',path,'error')
			}
		}
		const mainData = setMainData();

		//ローディング画面開始
        setIsLoading(true, dispatch)
		const response = await PostMethod(mainData, '/entry_info')
		if(response.code !== 200){
			if(response.code === 401){
				logoutAbnormalFunction(response,dispatch,navigate)
			} else {
				setModalFlagHandler('entry',path,'error')
			}
			//ローディング画面を非表示
			return setIsLoading(false,dispatch)	
		} 
		//成功時成功モーダルを出す
		setModalFlagHandler('entry',path,'success')
		//申し込み完了チェックマーク表示
		setEntryCheckHandler(path,'checked')
		//データをセットするハンドラ
		setRecoilDataHandler(response)
		
		switch (path) {
			case 'merchant':
				navigateTo = '/entry/bank_account'
				break;
			case 'bank_account':
				navigateTo = '/entry/shop'
				break;
			case 'shop':
				//遷移先は確認画面
				navigateTo = '/entry_confirm'
				break;
			default:
				break
		}
		if(location.state && location.state.confirm_flag) navigateTo = '/entry_confirm'
		const navigateState = navigateTo.substring(1)
		//ローディング画面を非表示
        setIsLoading(false,dispatch)

		return navigate(navigateTo,{state:{routeTo:navigateState}})
	}
	
	return { EntryPostMethod, EntryTemporaryPostMethod, fromConfirmFlag, path }

}
export default useEntryAPIHooks