import signinImg from '../../../images/pct_signin.svg';
import { Link } from 'react-router-dom';
import { FormWrap } from '../../layouts/FormWrap';
import { TextField, Button } from '../../ui-parts';
import { ErrorMessage } from '../../ui-parts';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import EntryGuideIcon from '../../../images/EntryGuide.svg'
import AnotherTabLink from '../../ui-parts/AnotherTabLink';
import { toolChipList } from '../../../constance/constance';
import { mixins } from '../../../css/variables/mixins';

const WholeWrapper = styled.div`
  ${mixins.screenOver()}{
    display: flex;
    justify-content: space-evenly;
  }
  padding: auto;
`
const HalfWrapper = styled.div`
  max-width:460px;
  margin: auto;
`
const WebEntryGuide = styled.div`
  display:inline-block;
  padding: 12px 20px;
  border: 1px solid ${colors.themeColor('border_box')};
  font-size:${fonts.fontSizeRem('s')};
  border-radius: 4px;

`
const EntryGuideWrappedFlex = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`
const TermsOfUsed = styled.div`
  overflow-y: scroll;
  word-break: break-all;
  width: 100%;
  height: 100px;
  background-color: ${colors.themeColor('background_whitegray')};
  margin-bottom: 32px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${colors.themeColor('border_box')};
  ${mixins.screenUnder()}{
    height:200px;
  }
`
const PassCheckMsgStyle = styled.p`
  color:${props => (props.score === 0 || props.score === 1) ? colors.themeColor('text_error') : colors.themeColor('success') };
  margin-top: 4px;
`

const UserRegisterElement = ({ UserRegisterHandler, setEmailHandler, setPasswordHandler, setConfirmPasswordHandler, passCheckState, ErrMsgs, ApiErrMsg }) => {
  return (
    <FormWrap width={1000} padding={40}>
      <WholeWrapper>
        <HalfWrapper >
          <div className='align_center'>
            <img src={signinImg} alt='SignInImg'/>
            <p className='bold font-xl mt20 mb0'>ゼウス決済サービス<br className='display-mobile' />お申し込みアカウント</p>
            <h1 className='mt4 mb24'>新規作成</h1>
            <WebEntryGuide>
              <EntryGuideWrappedFlex>
                <img src={EntryGuideIcon} alt='EntryGuideIcon' />
                <AnotherTabLink href={'https://www.cardservice.co.jp/partner/ocnk02/guide/'}>ゼウス決済サービスWeb申し込みガイド</AnotherTabLink>
              </EntryGuideWrappedFlex>
            </WebEntryGuide>
          </div>
        </HalfWrapper>
        <HalfWrapper>
          <ErrorMessage ApiErrMsg={ErrMsgs.agentMasterId} />
          <ErrorMessage ApiErrMsg={ApiErrMsg} />
          <p className='mb0 mt12 font-ms'>メールアドレス</p>
          <TextField
            onChange={setEmailHandler}
            iserror={ErrMsgs.email}
            type='email'
            id='textfield1'
            placeholder='user@cardservice.co.jp'
            fullwidth
            mt={4}
          />
          <p className='mb0 mt12 font-ms'>パスワード</p>
          <TextField
            onChange={setPasswordHandler}
            iserror={ErrMsgs.confirmPassword}
            type='password'
            id='textfield2'
            label='半角英数字と英字大文字,記号を含む１２文字以上入力してください'
            toolChip={toolChipList.user_regist_usabled_symbol}
            toolChipPlacement={'right'}
            toolChipWidth={280}
            fullwidth
            mt={0}
          />
          <PassCheckMsgStyle score={passCheckState.score}>{passCheckState.msg}</PassCheckMsgStyle>
          <p className='mb0 mt12 font-ms'>パスワードを再度入力してください</p>
          <TextField
            onChange={setConfirmPasswordHandler}
            type='password'
            id='textfield3'
            fullwidth
            mt={4}
          />
          <p className='align_center mt16 mb8'>本サービスに登録することで以下に同意したものとします</p>
          <h4 className='mt8 mb4'>【個人情報の取扱いについて】</h4>
          <TermsOfUsed>{toolChipList.terms_of_used}</TermsOfUsed>
          <Button
            onClick={UserRegisterHandler}
            variant='primary'
            fullwidth
          >
            登録する
          </Button>
          <hr />
          <p className='align_center mb0'><Link to="/login">ログインはこちら</Link></p>
        </HalfWrapper>
      </WholeWrapper>
    </FormWrap>
  )
}

export default UserRegisterElement