import { Box } from '../../layouts/Box';
import { EntryStep, EntryStepList } from '../../style/DashboardStyle';

const DashboardLowerWrapper = ({ children }) => {
  return (
    <section>
      <Box mt_desktop={32} mt_mobile={24}>
        <EntryStep>
          <div>
            <EntryStepList data_mborder={'2'}>
              {children}
            </EntryStepList>
          </div>
        </EntryStep>
      </Box>
    </section>
  )
}

export default DashboardLowerWrapper