import React from 'react';
import { EntryStepBar } from '../../ui-parts';
import PdfDownloadButton from '../../ui-parts/PdfDownloadButton';
import { Box } from '../../layouts/Box';


const DashboardBarElement = ({pdfDownloadHandler, userStatus}) => {

  return (
    <>
      <EntryStepBar data_mborder={'1'}>
        <div className='title bold font-xl'>お申し込み</div>
        <div className='body font-xs'>お申し込み内容に不備があった場合は本ページにて修正依頼をいたします。</div>
      </EntryStepBar>
      <EntryStepBar data_mborder={'3'}>
        <div className='title bold font-xl'>審査</div>
        <div className='body font-xs'>
          <div className='alert bold mb4'>
            メールのご確認をお願いします</div>
          <span className='font_xxs'>ゼウスより以下のメールアドレスにてお問い合わせさせていただくことがございます。</span>
          <p className='mb0 font_xxs mt4'>mmzeus@cardservice.co.jp</p>
          <p className='mb0 font_xxs mt0'>web_entry@cardservice.co.jp</p>
        </div>
      </EntryStepBar>
      {userStatus === "CUTOVER" &&
        <Box mt={16} mb_mobile={12}>
          <PdfDownloadButton pdfDownloadHandler={pdfDownloadHandler}/>
        </Box>
      }
    </>
  )
}

export default DashboardBarElement