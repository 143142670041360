import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';

const Wrapper = styled.div`
  width: ${props => props.navigation ? "8px" : "72px"};
  padding: 4px 0;
  text-align: center;
  border-radius: 20px;
  font-size: ${fonts.fontSizeRem('xs')};
  color: ${colors.themeColor('text_white')};
  background: ${props => props.status === 'ACTIVE' ? colors.themeColor('success') : colors.themeColor('background_reject')}
`

const InquiryStatusIcon = ({ status, navigation }) => {
  const statusText = (status) => {
    switch (status) {
      case 'ACTIVE':
        return '要返信'
      case 'CLOSED':
        return '完了'
      default :
        return '不正'
    }
  };
  
  return (
    <Wrapper status={status} navigation={navigation}>
      {!navigation && statusText(status)}
    </Wrapper>
  );
};

export default InquiryStatusIcon;

InquiryStatusIcon.defaultProps = {
  status: false,
};