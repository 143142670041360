import styled from "styled-components";
import { colors } from "../../../css/variables/colors";
import { fonts } from "../../../css/variables/fonts";
import { FlexBox } from "../../layouts/FlexBox";
import { FormWrap } from "../../layouts/FormWrap";
import { Checkbox } from "../../ui-parts/Checkbox";
import { Button, SelectMenu } from "../../ui-parts";
import useEconomicEstimateElementHooks from "../../../hooks/useEconomicEstimateElementHooks";
import EconomicEstimateTable from "../../ui-parts/EconomicEstimateTable";
import { ErrorHandler } from "../../../api/ErrorHandler";
import { Box } from "../../layouts/Box";
import useFinalAgreementHooks from "../../../hooks/useFinalAgreementHooks";
import AnotherTabLink from "../../ui-parts/AnotherTabLink";
import FinalAgreementTable from "../../ui-parts/FinalAgreement/FinalAgreementTable";
import { mixins } from "../../../css/variables/mixins";

const ServiceCostTable = styled.table`
	width:100%;
	table-layout: fixed;
` 
const TableTr = styled.tr`
	p{
		margin-bottom:0px;
		font-size:13px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.credit-settle-fee{
		width:300px;
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee{
		padding:4px;
		width:48%;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
`

const TableTh = styled.th`
	width:220px;
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:normal;
	background: ${colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:10px;
    position:relative;
`

const TableTd = styled.td`
	color:${(props) => props.black ? colors.themeColor('text_main') : colors.themeColor('primary_dark')};
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:bold;
	padding:9px;
    
`
const BlueWraper = styled.div`
	background-color:${colors.themeColor('background_whitegray')};
	border: solid 1px ${colors.themeColor('border_box')};
	border-radius: 8px;
	padding:20px;
`
const TitleWrapper = styled.div`
	display:flex;
	justify-content:space-between;
	align-items: center;
  .toggleArrow {
    width: 60px;
    height: 28px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s linear;
    ${props => props.toggle ? 'transform: rotate(180deg)' : null};
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -15%) rotate(-45deg);
      width: 12px;
      height: 12px;
      border-top: solid 2px ${colors.themeColor('text_lightgray')};
      border-right: solid 2px ${colors.themeColor('text_lightgray')};
    }
  }
`
const ContentWrapper = styled.div`
  transition: all 0.3s linear;
  .inner {
    padding: 28px;
  }
`
const NoChangePhrase = styled.span`
	font-size:${fonts.fontSizeRem('m')};
	color:${colors.themeColor('text_error')};
`

const FinalAgreementAttention = styled.div`
	width:100%;
	border: solid 1px ${colors.themeColor('pale_pink')};
	background-color:${colors.themeColor('background_pink')};
	color:${colors.themeColor('text_error')};
	padding:10px 16px;
	border-radius: 4px;
	${mixins.screenUnder()}{
		font-size: ${fonts.fontSizeRem('s')}
	}
`

const Guidance = styled.p`
	margin-top:24px;
	font-size:${fonts.fontSizeRem('xs')}
`
const RequiredStyle = styled.span`
  font-size:${fonts.fontSizeRem('xxs')};
  color: ${colors.themeColor('text_white')};
  background: ${colors.themeColor('background_required')};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: text-bottom;
`

const EconomicEstimateElement = ({page}) => {

    const {economicConditionMasterInfoData,feeArray,EconomicInfoData,systemPaymentMethodPhrase,sumFeeInfoData,vacantCheckState,creditPaymentSiteFeeState,normal,abnormal,setCheckBoxStateHandler,navigateBack,entryEconomicData,vacantCheckAndSetMessage,originFeeArrayHandler,demandCode} = useEconomicEstimateElementHooks()

	const {finalAgreementNormal, agreementHandler,finalAgreementResponseData,shopInfoData,productContentPhrase,compareData,newSystemUsageFeeData,selectItemsPaymentMethod,paymentMethod,setMonthlyPaymentHandler,validateMessage,paymentShowFlags,creditDifferenceFlag,finalAgreementAbnormal} = useFinalAgreementHooks(vacantCheckAndSetMessage,EconomicInfoData,economicConditionMasterInfoData,demandCode,page)

	const {ErrorHandle} = ErrorHandler()
	
	//経済条件画面のエラーハンドラー
	const navigateEntryMerchantHandler = async() => {
		const response = await entryEconomicData()
		//チェックボックスバリデーションに引っかかった時はリターン
		if(response === null) return
		ErrorHandle(response,normal,abnormal)
	}

	//二次審査合意画面のエラーハンドラー
	const navigateFinalAgreementCompletedHandler = async() => {
		const response = await agreementHandler()
		//チェックボックスバリデーションに引っかかった時はリターン
		if(response === null) return
		ErrorHandle(response,finalAgreementNormal,finalAgreementAbnormal)
	}
	const formWidth = page === 'final_agreement' ? 1050 : 700;
	const agreePhrase = page === 'final_agreement' ? '合意' : '同意';

    return(
        <FormWrap width={formWidth} padding={36}>
			<>
				{page === "final_agreement" &&
					<>
						<FinalAgreementAttention>
							・審査結果に伴い、お申し込み時のお見積り内容を次のように変更させていただきます。
							<br className="all"/>
							変更箇所は、「変更後」の欄に赤字で表示されます。
							<br className="all"/>
							<br className="all"/>
							・「変更後」のお見積り内容がご契約条件となります。
							<br className="all"/>
							ご確認の上、よろしければ「確認しました」ボタンを押してください。
							<br className="all"/>
							ご確認後は、システム設定を行いサービス利用開始となります。
						</FinalAgreementAttention>
						{(compareData.shop.shop_addition_product_content_freetext||compareData.shop.shop_addition_product_content_code) &&
							<h2 className="mt40 mb20">ショップ情報</h2>
						}
						<FinalAgreementTable
							contentName={'shop'}
							shopData={shopInfoData}
							productContentPhrase={productContentPhrase}
							responseData={finalAgreementResponseData}
							compareData={compareData}
							
						/>
					</>
				}
				
				{!page &&
					<>
						<h1 className="mt0">ご利用料金</h1>
						<p>ご利用料金は以下の通りです。</p>
					</>
				}
				<h2 className="mt40 mb20">クレジットカード決済</h2>
				{!page &&
					<EconomicEstimateTable 
						contentName={'credit'} 
						systemPaymentMethod={systemPaymentMethodPhrase.credit}
						sumSystemCost={page === "final_agreement" ? newSystemUsageFeeData : sumFeeInfoData.credit} 
						options={feeArray.credit}
						salesHandleFee
						paymentSiteFee={creditPaymentSiteFeeState}
						compareData={compareData}
						responseData={finalAgreementResponseData}
						page={page}
						originFeeArrayHandler={originFeeArrayHandler}
					/>
				}
				{page === 'final_agreement' && 
					<>
						<FinalAgreementTable 
							contentName={'credit'}
							options={feeArray.credit}
							originPaymentSiteFee={sumFeeInfoData.credit}
							paymentSiteFee={creditPaymentSiteFeeState}
							sumSystemCost={newSystemUsageFeeData}
							compareData={compareData}
							responseData={finalAgreementResponseData}
							originFeeArrayHandler={originFeeArrayHandler}
							page={page}
							creditDifferenceFlag={creditDifferenceFlag}
						/>
					</>
				}
				{(EconomicInfoData.atobarai.system_setting.select_flag && !(page === "final_agreement")) && 
					<>
						<h2 className="mt40 mb20">コンビニあと払い{page === "final_agreement" && <NoChangePhrase>　　変更はありません</NoChangePhrase>}</h2>
						<EconomicEstimateTable 
							contentName={'atobarai'}
							settlementFee={economicConditionMasterInfoData.atobarai.fee} 
							businessOperatorFee={economicConditionMasterInfoData.atobarai.business_operator_fee} 
							systemPaymentMethod={systemPaymentMethodPhrase.atobarai}
							sumSystemCost={sumFeeInfoData.atobarai} 
							options={feeArray.atobarai}
							salesHandleFee
							paymentSiteFee={0}
						/>
					</>
				}
				{(EconomicInfoData.bank.system_setting.select_flag && !(page === "final_agreement")) &&
					<>
						<h2 className="mt40 mb20">ネットバンク決済（入金おまかせサービス）{page === "final_agreement" && <NoChangePhrase>　　変更はありません</NoChangePhrase>}</h2>
						<EconomicEstimateTable 
							contentName={'bank'}
							settlementFee={economicConditionMasterInfoData.bank.fee} 
							systemPaymentMethod={systemPaymentMethodPhrase.bank}
							sumSystemCost={sumFeeInfoData.bank} 
							options={feeArray.bank}
							salesHandleFee
							paymentSiteFee={0}
						/>
					</>
				}
				{(EconomicInfoData.cvs.system_setting.select_flag && !(page === "final_agreement")) && 
					<>
						<h2 className="mt40 mb20">コンビニ決済{page === "final_agreement" && <NoChangePhrase>　　変更はありません</NoChangePhrase>}</h2>
						<EconomicEstimateTable 
							contentName={'cvs'} 
							systemPaymentMethod={systemPaymentMethodPhrase.cvs}
							sumSystemCost={sumFeeInfoData.cvs} 
							options={feeArray.cvs}
							paymentSiteFee={0}
						/>
					</>
				}
				
				{ (page !== "final_agreement" && demandCode === "system") &&
					<>
						<h3 className="mt40 mb20">支払い方法</h3>
							<ServiceCostTable>
								<tbody>
									<TableTr>
										<TableTh>月次支払い方法</TableTh>
										<TableTd black>{systemPaymentMethodPhrase.credit}</TableTd>
									</TableTr>
								</tbody>
							</ServiceCostTable>
					</>
				}
				{ (page === "final_agreement" && demandCode === "system" && (paymentShowFlags.zeroTo || paymentShowFlags.toZero)) && 
					<FormWrap margin_tb={12} padding={16} width={594}>
						<TitleWrapper>
							<h3 className='mt0 mb0 font-m'>支払い方法{paymentShowFlags.zeroTo && <RequiredStyle>必須</RequiredStyle>}</h3>
						</TitleWrapper>
						<ContentWrapper>
							{paymentShowFlags.toZero && 
								<p className="mb0 font-m">無料</p>
							}
							{paymentShowFlags.zeroTo &&
								<SelectMenu id='monthly_payment_method' name='monthly_payment_method' options={selectItemsPaymentMethod} defaultValue={paymentMethod} onChange={setMonthlyPaymentHandler} iserror={validateMessage}></SelectMenu>
							}
						</ContentWrapper>
					</FormWrap>
				}

				<h2 className="mt40 mb20">お申し込みの前にご確認ください</h2>
				<BlueWraper>
					<FlexBox column gap={4} >
						<AnotherTabLink href={"https://www.cardservice.co.jp/guide/preparation.html#anchor-08"}>取扱不可商材について</AnotherTabLink>
						{(EconomicInfoData.cvs.system_setting.select_flag && !(page === "final_agreement")) && 
							<AnotherTabLink href={"https://www.cardservice.co.jp/guide/cvs.html"}>コンビニサービス導入基準</AnotherTabLink>
						}
					</FlexBox>
				</BlueWraper>

				<h2 className="mt40 mb0">規約等のご確認と{agreePhrase}</h2>
				<p className="font-ms mt16">お申し込みには各種利用規約等への{agreePhrase}が必要です。</p>
				<BlueWraper>
					<FlexBox column gap={4}>
					<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_privacy.pdf"}>個人情報の取扱いについて</AnotherTabLink>
						<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_kyotsu.pdf"}>ゼウス決済サービス共通規約</AnotherTabLink>
						{EconomicInfoData.credit.system_setting.select_flag &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_creditcard.pdf"}>クレジット決済サービス利用規約（包括加盟型 ）</AnotherTabLink>
						}
						{(EconomicInfoData.atobarai.system_setting.select_flag && !(page === "final_agreement")) &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_atobarai.pdf"}>後払い決済サービス加盟店規約・後払い決済サービス利用規約</AnotherTabLink>
						}
						{(EconomicInfoData.bank.system_setting.select_flag && !(page === "final_agreement")) &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_omakase.pdf"}>入金おまかせサービスゼウス加盟店規約</AnotherTabLink>
						}
						{(EconomicInfoData.cvs.system_setting.select_flag && !(page === "final_agreement")) &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_cvs.pdf"}>コンビニ決済サービス利用規約</AnotherTabLink>
						}
					</FlexBox>
					<FlexBox column gap={4} mt={30}>
						<Checkbox id='agreement' label={'規約等へ'+agreePhrase +'します'} onChange={setCheckBoxStateHandler} iserror={vacantCheckState.agreement_msg}/>
						<Checkbox id='charges_check' label="料金及び取引手数料を確認しました"  onChange={setCheckBoxStateHandler} iserror={vacantCheckState.charges_check_msg}/>
					</FlexBox>
				</BlueWraper>
				{!page &&
					<FlexBox mt="24" gap="16">
						<Button variant="secoundary" onClick={navigateBack}>戻る</Button>
						<Button onClick={navigateEntryMerchantHandler} >申し込み情報入力に進む</Button>
					</FlexBox>
				}
				{page === "final_agreement" &&
					<>
						<Box mt={40}/>
						<Button size={'large'} onClick={navigateFinalAgreementCompletedHandler}>確認しました</Button>
						<Guidance>ご利用料金についてご質問等ございましたら ゼウスまでお問い合わせください</Guidance>
					</>
				}
			</>
		</FormWrap>
    )
}

export default EconomicEstimateElement