import React from 'react';

export const EntryStepBox = ({ children, step, content_number, data_mborder, errorStep, canceledFlag}) => {
  return (
    <li className={`${content_number === step ? 'current' : ''} ${content_number < step ? 'checked' : ''} ${errorStep === content_number ? 'error' : ''} step`} data_mborder={data_mborder} >
      <div className='box'>
        <div className={`box_inner ${canceledFlag ? 'relative' : '' }`}>
          {children}
          <div className={canceledFlag ? 'grayout' : '' }/>
        </div>
      </div>
    </li>
  )
}

EntryStepBox.defaultProps = {
  status: "",
  content_number: 1,
  data_mborder: 1,
  canceledFlag: false
}