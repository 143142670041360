import React, { useContext } from "react";
import useResetPasswordMailHooks from "../../hooks/useResetPasswordMailHooks";
import { ResetPasswordMailElement } from "../../components/ui_elements";
import { Container } from "../../components/layouts/Container";
import { ErrorHandler } from "../../api/ErrorHandler";
import { Context } from '../../stores/Provider';

const ChangePasswordMail = () => {
  const { sendEmail, setEmailHandler, EmailData, ErrMsg, normal, abnormal } = useResetPasswordMailHooks();
  const { state } = useContext(Context);
  const ApiErrMsg = state.auth.err_message
  const { ErrorHandle } = ErrorHandler();

  //エラーハンドリング実行
  const sendEmailHandler = async () => {
    const response = await sendEmail(EmailData);
    ErrorHandle(response, normal, abnormal, true)
  }

  return (
    <Container>
      <ResetPasswordMailElement
        ApiErrMsg={ApiErrMsg}
        setEmailHandler={setEmailHandler}
        sendEmailHandler={sendEmailHandler}
        ErrMsg={ErrMsg}
        changePassword
      />
    </Container>
  )
}

export default ChangePasswordMail