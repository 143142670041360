import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';

const FormChildBlockskinStyle = styled.div`
  margin-top: ${props=>props.mt}px;
  padding: 20px 12px;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid ${colors.themeColor('border_box')};
  border-radius: 8px;
  ${props => props.sx ? props.sx : null};
`;

export const FormChildBlockskin = ({ mt, sx, children }) => (
  <FormChildBlockskinStyle mt={mt} sx={sx}>
    {children}
  </FormChildBlockskinStyle>
);


FormChildBlockskin.defaultProps = {
  mt: 16,
};
