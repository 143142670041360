export const setSession = (key,data) => {
    return sessionStorage.setItem(key, JSON.stringify(data))
}

export const getSession = (key) => {
    return JSON.parse(sessionStorage.getItem(key))
}
//session操作,セッションの値抽出
//ログイン情報
export const setLoginInfoData = (data) => {
    setSession('login', data)
}
//ユーザ情報
export const setUserInfoData = (data) => {
    setSession('user', data)
}
//システム料金合計金額
export const setSumFeeInfoData = (data) => {
    setSession('sum_fee', data)
}
//審査コンテンツ出し分け
export const setReviewContentsDivisionInfoData = (data) => {
    setSession('review_contents_division', data)
}
//代理店情報マスタ
export const setAgentMasterInfoData = (data) => {
    setSession('agent_master', data)
}
//経済条件マスタ
export const setEconomicConditionMasterInfoData = (data) => {
    setSession('economic_condition_master', data)
}
//システム設定マスタ
export const setSystemSettingMasterInfoData = (data) => {
    setSession('system_setting_master', data)
}
//審査コンテンツマスタ
export const setReviewContentMasterInfoData = (data) => {
    setSession('review_contents_master', data)
}
// 申し込み用経済条件
export const setEconomicInfoData = (data) => {
    setSession('economic_condition', data)
}
//申し込み用お客様情報
export const setMerchantInfoData = (data) => {
    setSession('merchant', data)
}
//申し込み用代表者情報
export const setRepresentativeInfoData = (data) => {
    setSession('representative', data)
}
//申し込み用担当者情報
export const setOperationInfoData = (data) => {
    setSession('operation', data)
}
//申し込み用口座情報
export const setBankAccountInfoData = (data) => {
    setSession('bank_account', data)
}
// 申し込み用ファイル情報
export const setFileInfoData = (data) => {
    setSession('files', data)
}
//申し込み用ショップ情報
export const setShopInfoData = (data) => {
    setSession('shop', data)
}
//マスタによる経済条件出し分け情報
export const setEconomicDevidedInfoData = (data) => {
    setSession('economic_devided', data)
}
//申し込み完了チェックマーク情報
export const setEntryCheckInfoData = (data) => {
    setSession('entry_check', data)
}
