import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useLogoutHooks from '../../hooks/useLogoutHooks';
import useMenuHooks from '../../hooks/useMenuHooks';
import styled from 'styled-components';
import { colors } from '../../css/variables/colors';
import { ErrorHandler } from '../../api/ErrorHandler';
import { getSession } from '../../sessionStorage/sessionStorageMethod';
import { FlexBox } from '../layouts/FlexBox';
import { Box } from '../layouts/Box';
import userInfoIcon from '../../images/userInfoIcon.svg';
import { mixins } from '../../css/variables/mixins';

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  padding-top:auto;
  padding-bottom:auto;
`

const NavWrapper = styled.div`
  background: ${colors.themeColor('background_button_secoundary')};
  border: 1px solid ${colors.themeColor('border_box')};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: ${colors.themeColor('primary')};
  padding: 24px 12px 24px 12px;
  position: absolute;
  right: 0;
  width: 200px;
  z-index:300;
  overflow-wrap: break-word;
`

const LogoutButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
`

const NavItem = styled.p`
  color: ${colors.themeColor('primary')};
  ${mixins.screenOver()}{
    ${props => props.responsive && props.overDisplayNone &&
      'display: none;'
    }
  }
  ${mixins.screenUnder()}{
    ${props => props.responsive && props.underDisplayNone &&
      'display: none;'
    }
  }
`

const Icon = styled.label`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Menu = () => {
  const {IsOpen, ChangeOpen} = useMenuHooks();
  const { Logout, normal, abnormal } = useLogoutHooks();
  const { ErrorHandle } = ErrorHandler();
  const navigate = useNavigate()

  //セッションストレージ抽出
  const userInfoData = getSession('user')
  
  const logoutHandler = async () => {
    const response = await Logout();
		ErrorHandle(response, normal, abnormal)
    navigate('/login')
  }

  return (
    <Wrapper>
      <Box mt={16}/>
      <FlexBox align={'center'}>
        <NavItem responsive underDisplayNone className='mb0'>{userInfoData.email}</NavItem>
        <Icon
          className='mb0'
          onClick={ChangeOpen}
        >
          <img src={userInfoIcon} alt='userInfoIcon' />
        </Icon>
      </FlexBox>
      {IsOpen &&
        <NavWrapper  
          onBlur={() => setTimeout(() => ChangeOpen, 100)} 
        >
          <NavItem responsive overDisplayNone>{userInfoData.email}</NavItem>
          <NavItem>申込ID:{userInfoData.entry_id}</NavItem>
          <NavItem className='font-s'><Link to="/change_password_mail"  state={{routeTo:'change_password_mail'}}>パスワード変更</Link></NavItem>
          <NavItem className='font-s mb0'><LogoutButton
            onClick={logoutHandler}
          >
            ログアウト
          </LogoutButton></NavItem>
          
        </NavWrapper>
      }
    </Wrapper>
  )
}
