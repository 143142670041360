import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import UserIcon from '../../../images/icon_user.svg';
import ZeusIcon from '../../../images/icon_zeus.svg';
import { Box } from '../../layouts/Box';
import InquiryFileItem from './InquiryFileItem';
import { FlexBox } from '../../layouts/FlexBox';
import React from 'react';

const InquiryCommentStyle = styled.div`
  padding: ${props =>props.navigation ? '8px 0 0 0;' : '28px 0;'} 
  ${props => props.index === 0 ? 'padding-top:0px;' : ''}
  ${props => props.index === 0 ? 'padding-bottom:0px;' : ''}
  ${props =>props.navigation ? '' : `border-bottom: solid 1px ${colors.themeColor('border_box')};`}
  width:100%;
`

const UserDataStyle = styled.div`
  display: flex;
  .right {
    margin-top: 7px;
  }
`

const IconWrapperStyle = styled.div`
  width:  ${props =>props.navigation ? '36px' : '60px'};
  height:  ${props =>props.navigation ? '36px' : '60px'} ;
  border-radius: ${props =>props.navigation ? '18px':'30px'};
  background: ${colors.themeColor('background_whitegray')};
  border: solid 1px ${colors.themeColor('border_box')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const ZeusIconStyle = styled.img`
  width: 30px;
  height: 24px;
  overflow: visible;
`

const UserIconStyle = styled.img`
  width: 30px;
  height: 37.49px;
  overflow: visible;
`

const DateStyle = styled.p`
  margin-top: ${props => props.navigation ? '20' : '0'}px;
  margin-bottom: 0px;
  color: ${colors.themeColor('text_gray')};
  font-size: ${props => props.navigation ? fonts.fontSizeRem('xxs') : fonts.fontSizeRem('s') };
`
const TextStyle = styled.p`
  margin-top: 6px;
  ${props => props.navigation ? '' : 'margin-bottom: 0px;'}
  font-size: ${props => props.navigation ? fonts.fontSizeRem('s') : fonts.fontSizeRem('ms')};
  white-space: pre-line;
  word-wrap: break-word;
`
const CommentWrapper = styled.div`
  margin-left: ${props =>props.navigation ?  '12px' : '76px'};
`
const LeftFlexBox = styled.div`
  display:flex;
  gap: 0px;
  flex-direction: column;
  height:100%;
`
const HeightLine = styled.div`
  margin-top:${props => props.titleProps ? '0' : '8'}px;
  height: 100%;
  width: 36px;
  padding-left: 16.5px;
`
const HeightLineStyle = styled.div`
  height:100%;
  width:1px;
  border: 1px solid ${colors.themeColor('border_box')};
`
const UserDataWrapper = styled.div`
  margin-left:16px;
`
const UserNameStyle = styled.p`
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: bold;
  font-size : ${fonts.fontSizeRem('ms')};
  word-break: break-all;
`
const InquiryCommentItems = ({commentData, navigation}) => {

  // 日付の抽出関数
  const createdDate = (date) => {
    const tSplitDate = date.split('T')
    const dateText = tSplitDate[0].replace(/-/g, '/');
    const timeText = tSplitDate[1].substring(0,5);
    return dateText + ' ' + timeText
  };
  return (
    <>
      {!navigation && commentData.map((comment, i) => (
        <InquiryCommentStyle key={i}>
          <UserDataStyle>
            <div className='left'>
              <IconWrapperStyle>
                {comment.created_by.name === 'merchant' ? <UserIconStyle src={UserIcon}/> : <ZeusIconStyle src={ZeusIcon} />}
              </IconWrapperStyle>
            </div>
            <UserDataWrapper>
              <UserNameStyle>{comment.created_by.name === 'merchant' ? comment.created_by.email : 'ZEUS'}</UserNameStyle>
              <DateStyle>{createdDate(comment.created_at)}</DateStyle>
            </UserDataWrapper>
            
          </UserDataStyle>
          <CommentWrapper navigation={navigation} index={i}>
            {/* アップロードしたファイルを表示する部分 */}
            {comment.files.map((value, index) => (
              <InquiryFileItem
                key={index}
                itemNum={index}
                file={value}
              />
            ))}
            <Box mt={20}/>
            <TextStyle>{comment.comment}</TextStyle>
          </CommentWrapper>
        </InquiryCommentStyle>
      ))}
      {navigation && commentData.map((comment, i) => (
        <InquiryCommentStyle key={i} navigation={navigation} index={i}>
          <UserDataStyle>
            <FlexBox gap={0}>
              <LeftFlexBox>
                {i !== 0 &&
                  <>
                    <div className='left'>
                      <IconWrapperStyle navigation={navigation}>
                        {comment.created_by.name === 'merchant' ? <UserIconStyle src={UserIcon} navigation={navigation}/> : <ZeusIconStyle src={ZeusIcon} navigation={navigation}/>}
                      </IconWrapperStyle>
                    </div>
                    <HeightLine>
                      <HeightLineStyle/>
                    </HeightLine>
                  </>
                }
                {i === 0 &&
                  <HeightLine titleProps>
                    <HeightLineStyle/>
                  </HeightLine>
                }
              </LeftFlexBox>
              <CommentWrapper navigation={navigation} index={i}>
                  <TextStyle>{comment.comment}</TextStyle>
                  {/* アップロードしたファイルを表示する部分 */}
                  <Box mt={10}>
                    {comment.files.map((value, index) => (
                      <InquiryFileItem
                        key={index}
                        itemNum={index}
                        file={value}
                      />
                    ))}
                  </Box>
                  <DateStyle navigation={navigation}>{createdDate(comment.created_at)}</DateStyle>
              </CommentWrapper>
            </FlexBox>
          </UserDataStyle>
          
        </InquiryCommentStyle>
      ))}
    </>
    
    
  );
};

export default InquiryCommentItems;