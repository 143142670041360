import styled from "styled-components"

const Wrapper = styled.div`
    padding: 12px 0;
    ${props => props.color ? `background-color:${props.color};`:''}
`

const ContainerPopUpTitleWrapper = ({children, color}) =>(
    <Wrapper color={color}>
        {children}
    </Wrapper>
)

ContainerPopUpTitleWrapper.defaultProps = {
    color: false
}

export default ContainerPopUpTitleWrapper