import { ButtonBack } from "../../ui-parts";
import styled from "styled-components";
import { Link, useLocation } from 'react-router-dom';
import { mixins } from '../../../css/variables/mixins';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrapper = styled.div`
  ${mixins.screenOver()}{
    display: block;
  }
  ${mixins.screenUnder()}{
    display: none;
  }
`

const TitleElement = () => {
  const { pathname } = useLocation();

  const titleElement = (pathname) => {
    switch (pathname) {
      case '/examination_status':
        return '審査状況';
      case '/use_start':
        return 'ご利用開始の手順';
      default:
        return ''
    }
  }
  return (
    <>
      <Wrapper>
        <h1>{titleElement(pathname)}</h1>
        <ButtonWrapper>
          <ButtonBack><Link to='/dashboard' state={{routeTo:'dashboard'}}>ダッシュボードに戻る</Link></ButtonBack>
        </ButtonWrapper>
      </Wrapper>
      {pathname === '/use_start' &&
        <h3 className="mt0 mb20">決済サービスがご利用可能な状態となりました。</h3>
      }
    </>
  )
}

export default TitleElement