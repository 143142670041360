import { useLocation, useNavigate } from "react-router-dom"
import { useCallback, useContext, useEffect, useMemo } from "react"
import { Context } from "../stores/Provider"
import { getSession } from "../sessionStorage/sessionStorageMethod"
import { GetMethod } from "../api/Api"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"

const useEntryHooks = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {state, dispatch} = useContext(Context)
    const entryCheckInfoData = getSession('entry_check')

    const ErrMsgState = state.entryValidateMessage
    const snackbarIsOpen = state.is_open_modal
    const pathName = location.pathname;
    const checkStatus = {
      merchant:entryCheckInfoData !== undefined ? entryCheckInfoData.merchant : "default",
      bank:entryCheckInfoData !== undefined ? entryCheckInfoData.bank_account : "default",
      shop:entryCheckInfoData !== undefined ? entryCheckInfoData.shop : "default",
    }

    //申し込みボタンに申し込みデータを渡すためにコンテキストに保存
    const setEntryContext = useCallback(() => {
      dispatch({
        type : "set-entry-merchant",
        payload : getSession('merchant')
      })
      dispatch({
          type : "set-entry-representative",
          payload : getSession('representative')
      })
      dispatch({
          type : "set-entry-operation",
          payload : getSession('operation')
      })
      dispatch({
        type : "set-entry-bank-account",
        payload : getSession("bank_account")
      })
      dispatch({
        type : "set-entry-shop",
        payload : getSession('shop')
      })
      dispatch({
        type : "set-entry-files",
        payload : getSession('files')
      })
      dispatch({
        type : "set-review-content-division",
        payload : getSession('review_contents_division')
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])

    //銀行リスト取得
    const bankGetHandler = useCallback(async() => {
      const data = ""
      const {response_data, code, message} = await GetMethod(data,'/bank_data_format')
      const response = {code: code}
      if(code !== 200){
        if(code === 401) return logoutAbnormalFunction(response,dispatch,navigate) 
        dispatch({
          type: 'set-context-data',
          payload: {
            auth : message
          }
        })
        return navigate('/system_error')
      } else {
        dispatch({
          type: 'set-context-data',
          payload:{
            entry_bank_pulldown: response_data
          } 
        })
      }
      setIsLoading(false,dispatch)
    },[dispatch,navigate])

    //画面読み込み時処理
    useEffect(()=>{
      setIsLoading(true,dispatch)
      setEntryContext()
      //銀行リスト取得
      bankGetHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //ページタイトル表示
    const pageTitle = useMemo(() => {
        switch(location.pathname){
          case '/entry/merchant':
            return 'お客様情報'
          case '/entry/bank_account':
            return '口座情報'
          case '/entry/shop':
            return 'ショップ情報'
          default:
            return ''
        }
    },[location])

    //dfashboardボタン
    const moveToDashboard = () => {
      return navigate('/dashboard', {state:{routeTo:'dashboard'}})
  }

    return{ pageTitle, checkStatus, moveToDashboard, navigate, ErrMsgState, snackbarIsOpen, pathName }
}

export default useEntryHooks