import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';
import { mixins } from '../../css/variables/mixins';
import useFileInputHooks from '../../hooks/useFileInputHooks';

const FileInputStyle = styled.div`
  margin-top: ${props => props.mt}px;
  input{
    display: none;
  }
`;
const FileDropZoneStyle = styled.label`
  margin-top:${props => props.mt}px;
  padding: 32px 16px;
  ${mixins.screenUnder()}{
    padding: 24px 16px;
  }
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.themeColor('background_whitegray')};
  cursor: pointer;
  border:1px solid ${props => props.iserror ? colors.themeColor('border_error') + ' !important' : colors.themeColor('border_input')};
  border-radius: 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.themeColor('border_box')};
  ${props => props.dragOverFlg ? 'border-color:' + colors.themeColor('primary') : null};
`;
const DescriptionStyle = styled.p`
  font-size: ${fonts.fontSizeRem('s')};
  margin-bottom: 0;
  ${mixins.screenUnder()}{
    span{
      display: none;
    }
  }
`;
const MultipleDescStyle = styled.p`
  font-size: ${fonts.fontSizeRem('xs')};
  margin-top: 10px;
  margin-bottom: 0;
  color:${colors.themeColor('text_gray')};
  ${mixins.screenUnder()}{
    display: none;
  }
`;
const FileTypeDescStyle = styled.p`
  font-size: ${fonts.fontSizeRem('xs')};
  margin-top: 0;
  margin-bottom: 0;
  color:${colors.themeColor('text_gray')};
`;
const AsideStyle = styled.aside`
  margin-top: 8px;
  ul{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap:12px;
  }
`;
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`;
const FileToolChip = styled.div`
  font-size:${fonts.fontSizeRem('xs')};
  margin-top:20px;
  color:${colors.themeColor('text_gray')};
`

export const FileInput = ({ id, name, mt, accept, maxsize, multiple, iserror, page, toolChip }) => {
  //ファイルの情報

  const {dragOverFlg, thumbs, DragOverHandler, DragLeaveHandler, FileDropHandler, LoadFile, inputFileRef, fileContextState} = useFileInputHooks({maxsize, id, multiple, page});

  return (
    <FileInputStyle mt={mt}>
      <FileDropZoneStyle onDragOver={DragOverHandler} onDragLeave={DragLeaveHandler} onDrop={FileDropHandler} dragOverFlg={dragOverFlg} iserror={iserror} >
        <input type='file' id={id} accept={accept.map((value) => `.${value}`)} name={name} ref={inputFileRef} onChange={LoadFile} multiple={multiple} />
        <DescriptionStyle>ファイルを選択<span>またはドラッグ＆ドロップ</span>してください</DescriptionStyle>
        {multiple ? (
          <MultipleDescStyle>Shiftキーを押しながらファイルを複数選択可能</MultipleDescStyle>
        ) : <></>}
        {
          (() => {
            if (accept || maxsize) {
              return (
                <FileTypeDescStyle>
                  {accept ? (
                    accept.join(' / ')
                  ) : (<></>)}
                  {maxsize ? (
                    `（${maxsize}MB以下）`
                  ) : (<></>)}
                </FileTypeDescStyle>
              )
            }
          })()
        }
        <FileToolChip>{toolChip}</FileToolChip> 
      </FileDropZoneStyle>
      {Object.keys(fileContextState[id]).length > 0 ?
        <AsideStyle>
          <ul>
            {thumbs}
          </ul>
        </AsideStyle>
        : <></>}
        {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
    </FileInputStyle>
  );
};

FileInput.defaultProps = {
  mt: 16,
  multiple: false,
  maxsize: 30,
  accept: ['jpeg', 'jpg', 'png', 'gif']
};

