import { useNavigate } from "react-router-dom";
import { Container } from "../../components/layouts/Container";
import { BackButton } from "../../components/ui-parts/BackButton";
import EconomicEstimateElement from "../../components/ui_elements/Economic/EconomicEstimateElement";

const FinalAgreement = () => {

	const navigate = useNavigate()

	return (
		<Container isLogin={true} background={'default'} hasFooterMenu={false} align={'top'}>
				<div className="mb4 align_right">
					<BackButton onClick={()=>navigate('/dashboard', {state:{routeTo:'dashboard'}})}>ダッシュボードに戻る</BackButton>
				</div>
			<EconomicEstimateElement page={"final_agreement"} />
		</Container>
	)
}

export default FinalAgreement