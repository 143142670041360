import { useState, useRef, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { countAllKeys, setCompleteCount, setCountBankAccountData, setCountMerchantData, setCountShopAccountData } from "../function/setCountData";
import { getSession } from "../sessionStorage/sessionStorageMethod";

const useEntrySideHooks = () => {
    /**
       * モバイルサイズの時ボタンのスクロール位置に応じてボタンのFixed切り替え
       */
    // const bottomMenuH = 67;
    const fixedContents = useRef(null);
    const [fixedPosition] = useState(true);
    const toggleButtonPosition = () => {
        // const windowH = window.innerHeight;
        // const elmPos = fixedContents.current.getBoundingClientRect().top;
        // elmPos - windowH + bottomMenuH > 0
        //     ? setFixedPosition(true)
        //     : setFixedPosition(false)
    }
    const location = useLocation()
    //セッションデータ抽出
    const agentMasterInfoData = getSession('agent_master')
    const merchantInfoData = getSession('merchant')
    const bankAccountInfoData = getSession('bank_account')
    const representativeInfoData = getSession('representative')
    const operationInfoData = getSession('operation')
    const economicInfoData = getSession('economic_condition')
    const fileInfoData = getSession('files')
    const shopInfoData = getSession('shop')
    const reviewContentsDivisionInfoData = getSession('review_contents_division')

    //確認画面から遷移したかどうかを判定するフラグ
    useEffect(() => {
        window.addEventListener('scroll', toggleButtonPosition)
        return () => window.removeEventListener('scroll', toggleButtonPosition)
    }, [])

    const ObjToDevide = reviewContentsDivisionInfoData

    //入力数カウント用店舗情報データ
    const arrangeMerchantData = useMemo(() => {
        return setCountMerchantData(merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData)
    },[merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData])

    //入力数カウント用口座情報データ
    const arrangeBankAccountData = useMemo(()=>{
        return setCountBankAccountData(bankAccountInfoData,fileInfoData)
    },[bankAccountInfoData,fileInfoData])
    
    //入力数カウント用ショップ情報データ
    const arrangeShopData = useMemo(() => {
        return setCountShopAccountData(shopInfoData,fileInfoData,agentMasterInfoData,economicInfoData,ObjToDevide,merchantInfoData)
    },[shopInfoData,fileInfoData,ObjToDevide,agentMasterInfoData,economicInfoData,merchantInfoData])

    //ページごとにカウントするオブジェクトを分ける
    const entryDataByPages = useMemo(() => {
        switch (location.pathname) {
            case '/entry/merchant':
                return arrangeMerchantData
            case '/entry/bank_account':
                return arrangeBankAccountData
            case '/entry/shop':
                return arrangeShopData
            default:
                return {}
        }
    }, [location.pathname,arrangeMerchantData,arrangeBankAccountData,arrangeShopData])
    //入力欄全体の数のカウント
    const countPropaty = useMemo(() => {
        return countAllKeys(entryDataByPages)
    }, [entryDataByPages])

    //入力完了の数のカウント
    const completeCount = useMemo(() => {
        return setCompleteCount(entryDataByPages)
    }, [entryDataByPages])
    
    return{ fixedContents, fixedPosition, completeCount, countPropaty}
}

export default useEntrySideHooks