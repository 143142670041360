import { Box } from "../../components/layouts/Box";
import { Container } from "../../components/layouts/Container";
import { InquiryItemElement } from "../../components/ui_elements";
import useInquiryNavHooks from "../../hooks/useInquiryNavHooks";
import { Link } from "react-router-dom";
import { BackButton } from "../../components/ui-parts/BackButton";

const Inquiry = () => {
  // 不備連携お知らせ処理
  const { InquiryCommentData, accordionNum, serchInquiryData } = useInquiryNavHooks();
  return (
    <Container
      isInquiry
      isLogin
    >
      <div align="right">
        <BackButton><Link to={'/dashboard'} state={{ routeTo: 'dashboard' }} >ダッシュボードへ戻る</Link></BackButton>
      </div>
      <div className="mt32">
        {InquiryCommentData.map((data, index) => {
          return (
            <div key={index}>
              {index === 0 ? <></> : <Box mt={20} />}
              <InquiryItemElement
                inquiryData={serchInquiryData(Object.values(data)[0].ticket_id)}
                inquiryComment={Object.values(data)}
                accordionOpen={accordionNum}
              />
            </div>
          )
        })}
      </div>
    </Container>
  );
};

export default Inquiry