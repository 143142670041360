/**
 * ダッシュボード
 */
import { Container } from '../../components/layouts/Container';
import { DashboardBarElement, DashboardMessageElement, DashboardBoxElement, DashboardLowerWrapper } from '../../components/ui_elements';
import useDashboardHooks from '../../hooks/useDashboardHooks';
import ErrorBoundary from '../../api/ErrorBoundary';
import usePdfDownloadHooks from '../../hooks/usePdfDownloadHooks';


export const DashBoard = () => {
  const hooksResult = useDashboardHooks();
  const {pdfDownloadHandler, userStatus} = usePdfDownloadHooks()
  //ログイン確認+認証確認
  const { step, status, errorStep, buttonPhrase, moveToStart, moveToUseStart, moveToExamination, canceledFlag, moveToUseStartPrecautions } = hooksResult
  /*
  * containerの設定
  */
  const background = 'white';  //背景色
  const align = 'top' //コンテンツ配置
  const fullwidth = true; //幅マックス

  return (
    <ErrorBoundary>
      <Container
        background={background}
        align={align}
        fullwidth={fullwidth}
        isLogin
        isInquiry
      >
        <DashboardMessageElement status={status} step={step} canceledFlag={canceledFlag} />
        <DashboardLowerWrapper>
          <DashboardBoxElement step={step} status={status} moveToStart={moveToStart} moveToUseStart={moveToUseStart} errorStep={errorStep} buttonPhrase={buttonPhrase} moveToExamination={moveToExamination} moveToUseStartPrecautions={moveToUseStartPrecautions} canceledFlag={canceledFlag}/>
          <DashboardBarElement pdfDownloadHandler={pdfDownloadHandler} userStatus={userStatus}/>
        </DashboardLowerWrapper>
      </Container>
    </ErrorBoundary>
  );
};
