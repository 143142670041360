import { initAgentMasterSessionData, initBankAccountSessionData, initEconomicConditionMasterSessionData, initEconomicDividedSessionData, initEconomicSessionData, initEntryCheckData, initFileSessionData, initLoginSessionData, initMerchantSessionData, initOperationSessionData, initRepresentativeSessionData, initReviewContentsDivisionSessionData, initShopSessionData, initSumFeeSessionData, initSystemSettingMasterSessionData, initUserSessionData } from "../constance/sessionData"
import { setSession } from "../sessionStorage/sessionStorageMethod"

//すべてのセッションストレージを初期値で設定
export const setSessionAllData = () => {
    setSession('login', initLoginSessionData)
    setSession('user', initUserSessionData)
    setSession('sum_fee', initSumFeeSessionData)
    setSession('review_contents_division', initReviewContentsDivisionSessionData)
    setSession('agent_master', initAgentMasterSessionData)
    setSession('system_setting_master', initSystemSettingMasterSessionData)
    setSession('economic_condition_master', initEconomicConditionMasterSessionData)
    setSession('merchant', initMerchantSessionData)
    setSession('representative', initRepresentativeSessionData)
    setSession('bank_account', initBankAccountSessionData)
    setSession('operation', initOperationSessionData)
    setSession('files', initFileSessionData)
    setSession('shop', initShopSessionData)
    setSession('economic_condition', initEconomicSessionData)
    setSession('economic_devided', initEconomicDividedSessionData)
    setSession('entry_check', initEntryCheckData)
}