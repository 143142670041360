import styled from "styled-components"
import { colors } from "../../../css/variables/colors"
import { useMemo } from "react"
import { fonts } from "../../../css/variables/fonts"
import { mixins } from "../../../css/variables/mixins"

const TagWholeWrapper = styled.div`
    width: 100%;
    text-align: left;
    ${mixins.screenOver()}{
        display: none;
    }
`
const TagWrapper = styled.div`
    display: inline-box;
    background-color: ${colors.themeColor('background_gray')};
    border-radius: 2px;
    padding: 0 3px;
`

const TagText = styled.p`
    font-size: ${fonts.fontSizeRem('xxs')};
    ${props => (!(props.afterFlag) || (props.changeDetail !== 'disabled' && props.changeDetail !== 'usabled')) ? "color:" + colors.themeColor('text_gray') + " !important;" : ""}
    ${props => (props.afterFlag && props.changeDetail === 'disabled') ? "color:" + colors.themeColor('text_error') + " !important;" : ""}
    ${props => (props.afterFlag && props.changeDetail === 'usabled') ? "color:" + colors.themeColor('primary') + " !important;" : ""}
`

export const ChangeTag = ({afterFlag, changeDetail}) => {

    const tagText = useMemo(()=>{
        let tagText = ""
        if(!afterFlag) tagText = '変更前'
        if(afterFlag && changeDetail === '') tagText = '変更なし'
        if(afterFlag && (changeDetail === 'disabled' || changeDetail === 'usabled')) tagText = '変更あり'
        if(afterFlag && changeDetail === 'after') tagText = '変更後'

        return tagText
    },[afterFlag, changeDetail])
    return(
        <TagWholeWrapper>
            <TagWrapper>
                <TagText afterFlag={afterFlag} changeDetail={changeDetail}>
                    {tagText}
                </TagText>
            </TagWrapper>
        </TagWholeWrapper>
    )
}

ChangeTag.defaultProps = {
    afterFlag: false,
    changeDetail: ""
}