import React from 'react';
import styled from 'styled-components';
import { mixins } from '../../css/variables/mixins';


const EntryNavStyles = styled.div`
  ${mixins.screenUnder()}{
    margin-left: -16px;
    margin-right: -16px;
    overflow-x: auto;
    padding-top: 8px;
    &::-webkit-scrollbar{
    display:none;
  }
  }
`
const EntryNavInnerStyles = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  ${mixins.screenOver()}{
    display: flex;
  }
  ${mixins.screenUnder()}{
    display: inline-flex;
    width:100%;
  }
`


export const EntryNav = ({ children }) => {

  return (
    <EntryNavStyles>
      <EntryNavInnerStyles>
        {children}
      </EntryNavInnerStyles>
    </EntryNavStyles>
  );
};


EntryNav.defaultProps = {

};

