import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { ErrorMessage, InquiryStatusIcon, InquiryCommentItems } from '../../ui-parts'; 
import useInquiryItemHooks from "../../../hooks/useInquiryItemHooks";
import InquiryFileElement from './InquiryFileElement';
import ZeusIcon from '../../../images/icon_zeus.svg';
import { mixins } from '../../../css/variables/mixins';

const WholeWrapperStyle = styled.div`
  background: ${colors.themeColor('background_main')};
  ${props => !props.navigation ? "border-radius: 8px;" : ""} 
  border: solid 1px ${colors.themeColor('border_box')};
`

const TitleWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  padding: ${props=>props.navigation ? "8px 0px 8px 20px" : "16px 0px 16px 28px"};
  cursor: pointer;
  ${props => (!props.toggle || !props.navigate) ? 'border-bottom: solid 1px' + colors.themeColor('border_box') : null};
  .toggleArrow {
    width: 60px;
    height: 28px;
    position: relative;
    transition: all 0.3s linear;
    ${props => props.toggle ? 'transform: rotate(180deg)' : null};
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -15%) rotate(-45deg);
      width: 12px;
      height: 12px;
      border-top: solid 2px ${colors.themeColor('text_lightgray')};
      border-right: solid 2px ${colors.themeColor('text_lightgray')};
    }
  }
`

const ContentWrapperStyle = styled.div`
  display: ${props => props.test ? 'block' : 'none'};
  ${props =>props.navigation ? 'max-height:400px;' : ''} 
  ${props =>props.navigation ? 'overflow: scroll;' : ''} 
  ${props =>props.navigation ? '-ms-overflow-style: none;' : ''} 
  ${props =>props.navigation ? 'scrollbar-width: none;' : ''} 
  ::-webkit-scrollbar{
    display: none;
  }
`

const ContentInnerStyle = styled.div`
  ${props =>props.navigation ? 'padding: 0 20px 20px 20px;' : 'padding: 0 28px 28px 28px;'} 
`

const ContentInputStyle = styled.div`
  padding: 16px;
  margin-top: 28px;
  border: 1px solid ${colors.themeColor('border_box')};
  background: ${colors.themeColor('background_whitegray')};
  border-radius: 4px;
  .textarea {
    -webkit-appearance: none;
    border: 1px solid ${colors.themeColor('border_box')};
    padding: 12px 16px;
    border-radius: 4px;
    outline: none;
    resize: vertical;
    width: 100%;
    height: ${props => props.height + 'px'};
    display: block;
    min-height: 120px;
    max-height: 300px;
    &:focus {
      border: 1px solid ${colors.themeColor('primary_light')};
    }
    ::-webkit-resizer {
      display: none;
    }
  }
`
const SlideInWrapper = styled.div`

`
const TitleP = styled.p`
  font-size: ${props =>props.navigation ? fonts.fontSizeRem('s') : fonts.fontSizeRem('xl')};
  font-weight: 600;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: 12px;
  ${props =>props.navigation ? (props.ItemToggle ? 'width:360px;' : 'width:200px;') : ''} 
  ${props =>props.navigation && "white-space: nowrap; overflow: hidden; text-overflow: ellipsis; -webkit-text-overflow: ellipsis; -o-text-overflow: ellipsis;"} 
  ${mixins.screenUnder()}{
    font-size: ${fonts.fontSizeRem('m')};
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
`
const IconWrapperStyle = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background: ${colors.themeColor('background_whitegray')};
  border: solid 1px ${colors.themeColor('border_box')};
  display: flex;
  justify-content: center;
  align-items: center;
`
const IconStyle = styled.img`
  width: 30px;
  height: 24px;
  overflow: visible;
`
const InquiryItemElement = ({inquiryData, inquiryComment, accordionOpen, navigation}) => {
  const { 
    ItemToggle, 
    itemToggleChangeHandler, 
    ToggleRef, 
    NavToggleRef,
    ScrollHeight, 
    scrollHeightChangeHandler, 
    TextareaRef, 
    commentRegisterHandler,
    isFlag,
    CommentRegisterErrMsg,
    FileDatas,
    setFileData,
    canceledFlag
  } = useInquiryItemHooks(accordionOpen, inquiryData.ticket_id,navigation, inquiryComment);
  
  return (
    <WholeWrapperStyle toggle={ItemToggle} navigation={navigation}>
      {!navigation &&
        <>
          {/* 常に表示している部分 */}
          <TitleWrapperStyle onClick={itemToggleChangeHandler} toggle={ItemToggle} navigation={navigation}>
            <InquiryStatusIcon status={inquiryData.status} navigation={navigation}/>
            <TitleP ItemToggle={ItemToggle} navigation={navigation}>{inquiryData.title}</TitleP>
            <div className='toggleArrow active'></div>
          </TitleWrapperStyle>
          {/* アクティブ時のみ表示する部分 */}
          <ContentWrapperStyle navigation={navigation} test={isFlag} toggle={ItemToggle} ref={ToggleRef}>
            <ContentInnerStyle>
              {/* コメント表示部分 */}
              <InquiryCommentItems 
                commentData={inquiryComment}
              />
              <ContentInputStyle height={ScrollHeight + 2}>
              <textarea ref={TextareaRef} id='textarea' onChange={scrollHeightChangeHandler} className='textarea' placeholder="返信" disabled={(inquiryData.status === 'CLOSED' || canceledFlag) ? true : false}></textarea>
              <ErrorMessage ApiErrMsg={CommentRegisterErrMsg} />
              {/* ファイルの表示部分 */}
              <InquiryFileElement
                registerHandler={commentRegisterHandler}
                ticket_id={inquiryData.ticket_id}
                status={inquiryData.status}
                FileDatas={FileDatas}
                setFileData={setFileData}
                canceledFlag={canceledFlag}
              />
              </ContentInputStyle>
            </ContentInnerStyle>
          </ContentWrapperStyle>
        </>
      }
      {navigation &&
        <>
        {/* 常に表示している部分 */}
        <TitleWrapperStyle onClick={itemToggleChangeHandler} toggle={ItemToggle} navigation={navigation}>
          <IconWrapperStyle>
            <IconStyle src={ZeusIcon} />
          </IconWrapperStyle>
          <TitleP ItemToggle={ItemToggle} navigation={navigation}>{inquiryData.title}</TitleP>
          <div className='toggleArrow active'></div>
        </TitleWrapperStyle>

        <ContentWrapperStyle navigation={navigation} test={isFlag} toggle={ItemToggle} ref={NavToggleRef}>
            <ContentInnerStyle navigation={navigation}>
              <InquiryCommentItems 
                commentData={inquiryComment}
                navigation={navigation}
              />
              <SlideInWrapper height={ScrollHeight + 2}>
                <ErrorMessage ApiErrMsg={CommentRegisterErrMsg} />
                {/* ファイルの表示部分 */}
                <InquiryFileElement
                  registerHandler={commentRegisterHandler}
                  ticket_id={inquiryData.ticket_id}
                  status={inquiryData.status}
                  FileDatas={FileDatas}
                  setFileData={setFileData}
                  textareaRef={TextareaRef}
                  textareaChange={scrollHeightChangeHandler}
                  inquiryData={inquiryData}
                  navigation={navigation}
                />
              </SlideInWrapper>
            </ContentInnerStyle>
          </ContentWrapperStyle>
        </>
      }
    </WholeWrapperStyle>
  );
};

export default InquiryItemElement;