const screenOver = (px = 768) => {
  return `@media (min-width: ${px}px)`;
}

const screenUnder = (px = 767) => {
  return `@media (max-width: ${px}px)`;
}

const contentsWidth = () => {
  return 'calc(1200px + 16px*2)';
}

const jadgeScreenOver = (px = 768)=>{
  let overFlag = true
  if(window.innerWidth < px) overFlag = false
  return overFlag
}

export const mixins = {
  screenOver,
  screenUnder,
  contentsWidth,
  jadgeScreenOver
}
