import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';

const Wrapper = styled.div`
  padding: 10px 0;
  border-top: solid 1px ${colors.themeColor('border_box')};
`

const TextStyle = styled.div`
  margin: 0 auto 0 16px;
  color: ${colors.themeColor('text_link')};
  font-size: ${fonts.fontSizeRem('s')};
`

const InquiryNavItem = ({ inquiryItemData}) => {
  return (
    <Wrapper>
      <Link to='/inquiry' state={{accordionNum: inquiryItemData.ticket_id, routeTo:'inquiry'}}><TextStyle>{inquiryItemData.title}</TextStyle></Link>
    </Wrapper>
  );
};

export default InquiryNavItem;

