const base = 16;

const sizes = {
  'xxxs': 11,
  'xxs': 12,
  'xs': 13,
  's': 14,
  'ms': 15,
  'm': 16,
  'l': 18,
  'xl': 20,
  'xxl': 22,
  'xxxl': 32,
  'h1_desktop':30,
  'h1_mobile':26
}

const fontFamily= `"Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif`;

const fontSizeBase = () =>{
  return base;
}

const fontSizeRem = (size) => {
  return sizes[size] / base + 'rem';
}

export const fonts = {
  fontSizeBase,
  fontSizeRem,
  fontFamily
}
