import styled from "styled-components"
import { fonts } from "../css/variables/fonts"

const CreditFeeP = styled.span`
    font-size: ${fonts.fontSizeRem('xl')};
`
const FontXLSpan = styled.span`
    font-size: ${fonts.fontSizeRem('xl')};
`


//経済条件画面、確認画面で使用するコンビニの取引手数料レンジを返す関数
export const setCvsFeeData = (economicConditionMasterInfoData) => {
    const cvsFeeArray = [
        {range:"2,000", fee:economicConditionMasterInfoData.cvs.fee_range_2000},
        {range:"3,000", fee:economicConditionMasterInfoData.cvs.fee_range_3000},
        {range:"5,000", fee:economicConditionMasterInfoData.cvs.fee_range_5000},
        {range:"7,000", fee:economicConditionMasterInfoData.cvs.fee_range_7000},
        {range:"10,000", fee:economicConditionMasterInfoData.cvs.fee_range_10000},
        {range:"30,000", fee:economicConditionMasterInfoData.cvs.fee_range_30000},
        {range:"50,000", fee:economicConditionMasterInfoData.cvs.fee_range_50000},
        {range:"100,000", fee:economicConditionMasterInfoData.cvs.fee_range_100000},
        {range:"200,000", fee:economicConditionMasterInfoData.cvs.fee_range_200000},
        {range:"300,000", fee:economicConditionMasterInfoData.cvs.fee_range_300000},
    ]
    const cvsFee = cvsFeeArray.map((key,index)=>{
        return(
           <div className="cvs-settle-fee" key={key.range+index}>
               <p className="normal-weight font-xs">￥{key.range}円未満</p>
               <p className="blue bold font-s">￥<FontXLSpan>{key.fee}</FontXLSpan></p>
           </div>
        )   
   })
   return cvsFee
}

//経済条件画面、確認画面で使用するクレジットの取引手数料を返す関数
export const setCreditFeeData = (economicConditionMasterInfoData) => {
    const creditFeeArray = [
        {name:"VISA / MasterCard", fee:economicConditionMasterInfoData.credit.vm_fee},
        {name:"JCB / AMEX", fee:economicConditionMasterInfoData.credit.jcb_fee},
        {name:"Diners", fee:economicConditionMasterInfoData.credit.diners_fee},
    ]

    const creditFee = creditFeeArray.map((key,index)=>{
        return(
            <div className="credit-settle-fee" key={key.name+index}>
                <p className="normal-weight font-xs">{key.name}</p>
                <CreditFeeP className="blue bold">{key.fee}<span className="font-s">％</span></CreditFeeP>
            </div>
        )
    })
    return creditFee
}