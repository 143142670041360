import { useEffect } from "react";
import ErrorBoundary from "../../api/ErrorBoundary";
import useGTMHooks from "../../hooks/useGTMHooks";
import UserRegister from "../rederPages/UserRegister";

const UserRegisterBeforeRender = () => {
  const { pushDataLayerHandler } = useGTMHooks()
  useEffect(()=>{
    pushDataLayerHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    return(
      <ErrorBoundary>
        <UserRegister/>
      </ErrorBoundary>
    )
}

export default UserRegisterBeforeRender