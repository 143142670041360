import { Box } from "../../layouts/Box"
import { ArrowLink } from "../../ui-parts"

const UseStartLinkElement = () => {
	return(
		<div>
			<h2 className="mt36 mb20">ご利用の手引き</h2>
			<Box mt={10} />
			<ArrowLink
				link={"https://www.cardservice.co.jp/zmc/manual/admin.html"}
				href
			>
				ゼウス決済サービス管理画面メニュー
			</ArrowLink>
			<Box mt={10} />
			<ArrowLink
				link={"https://support.cardservice.co.jp/hc/ja"}
				href
			>
				ご利用のQ&A（クレジットカード・ネットバンク決済（入金おまかせサービス）・コンビニ）
			</ArrowLink>
			<Box mt={10} />
			<ArrowLink
				link={"https://support.cardservice.co.jp/hc/ja/categories/25147993294361"}
				href
			>
				ご利用のQ&A（コンビニあと払い）
			</ArrowLink>
		</div>
	)
}

export default UseStartLinkElement