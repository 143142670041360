import { React } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// 各ページを読み込む
import { EntryColumn } from '../components/ui_elements/Entry/EntryColumn';
import DashBoardBeforeRender from '../pages/beforeRenderPages/DashBoardBeforeRender';
import ResetPasswordMailBeforeRender from '../pages/beforeRenderPages/ResetPasswordMailBeforeRender';
import ResetPasswordBeforeRender from '../pages/beforeRenderPages/ResetPasswordBeforeRender';
import ChangePasswordBeforeRender from '../pages/beforeRenderPages/ChangePasswordBeforeRender';
import EconomicSelectBeforeRender from '../pages/beforeRenderPages/EconomicSelectBeforeRender';
import EconomicEstimateBeforeRender from '../pages/beforeRenderPages/EconomicEstimateBeforeRender';
import EntryTopBeforeRender from '../pages/beforeRenderPages/EntryTopBeforeRender';
import EntryBeforeRender from '../pages/beforeRenderPages/EntryBeforeRender';
import EntryConfirmBeforeRender from '../pages/beforeRenderPages/EntryConfirmBeforeRender';
import EntryCompletedBeforeRender from '../pages/beforeRenderPages/EntryCompletedBeforeRender';
import EntryCheckBeforeRender from '../pages/beforeRenderPages/EntryCheckBeforeRender';
import ExaminationStatusBeforeRender from '../pages/beforeRenderPages/ExaminationStatusBeforeRender';
import InquiryBeforeRender from '../pages/beforeRenderPages/InquiryBeforeRender';
import FinalAgreementBeforeRender from '../pages/beforeRenderPages/FinalAgreementBeforeRender';
import FinalAgreementCompletedBeforeRender from '../pages/beforeRenderPages/FinalAgreementCompletedBeforeRender';
import UseStartBeforeRender from '../pages/beforeRenderPages/UseStartBeforeRender';
import ChangePasswordMailBeforeRender from '../pages/beforeRenderPages/ChangePasswordMailBeforeRender';
import LoginBeforeRender from '../pages/beforeRenderPages/LoginBeforeRender';
import NotFoundBeforeRender from '../pages/beforeRenderPages/NotFoundBeforeRender';
import SystemErrorBeforeRender from '../pages/beforeRenderPages/SystemErrorBeforeRender';
import UserRegisterBeforeRender from '../pages/beforeRenderPages/UserRegisterBeforeRender';
import CompletedRegisterBeforeRender from '../pages/beforeRenderPages/CompletedRegisterBeforeRender';

import UserRegisterAuthenticationBeforeRender from '../pages/beforeRenderPages/UserRegisterAuthenticationBeforeRender';
import ResetPasswordMailCompletedBeforeRender from '../pages/beforeRenderPages/ResetPasswordMailCompletedBeforeRender';
import UseStartPrecautionsBeforeRender from '../pages/beforeRenderPages/UseStartPrecautionsBeforeRender';

// ルートを通す
const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* ログイン画面 */}
                <Route path="login" element={<LoginBeforeRender />} />
                {/* ダッシュボード画面 */}
                <Route path="dashboard" element={<DashBoardBeforeRender />} />
                {/* ユーザー登録画面 */}
                <Route path="user_register" element={<UserRegisterBeforeRender />} />
                {/* ユーザー登録メール送信完了画面 */}
                <Route path="completed_register" element={<CompletedRegisterBeforeRender />} />
                {/* 認証成功リダイレクト画面 */}
                <Route path="user_register_auth" element={<UserRegisterAuthenticationBeforeRender />} />
                {/* リセットパスワードメール送信画面 */}
                <Route path="reset_password_mail" element={<ResetPasswordMailBeforeRender />} />
                <Route path="reset_password_mail_completed" element={<ResetPasswordMailCompletedBeforeRender/>} />
                {/* リセットパスワード設定画面 */}
                <Route path="reset_password" element={<ResetPasswordBeforeRender />} />
                {/* パスワード変更メール送信画面 */}
                <Route path="change_password_mail" element={<ChangePasswordMailBeforeRender />} />
                {/* パスワード変更画面 */}
                <Route path="change_password" element={<ChangePasswordBeforeRender />} />
                {/* 経済条件選択画面 */}
                <Route path="economic_select" element={<EconomicSelectBeforeRender />} />
                {/* 経済条件確認画面 */}
                <Route path="economic_estimate" element={<EconomicEstimateBeforeRender />} />
                {/* 申込TOP */}
                <Route path="entry_top" element={<EntryTopBeforeRender />} />
                {/* 申し込み画面 */}
                <Route path="entry" element={<EntryBeforeRender />} >
                    {/* 加盟店情報 */}
                    <Route path="merchant" element={<EntryColumn path='merchant'/>} />
                    {/* 口座情報 */}
                    <Route path="bank_account" element={<EntryColumn path='bank_account'/>} />
                    {/* ショップ情報 */}
                    <Route path="shop" element={<EntryColumn path='shop'/>} />
                </Route>
                {/* 申し込み確認画面 */}
                <Route path="entry_confirm" element={<EntryConfirmBeforeRender />} />
                {/* 申し込み完了画面 */}
                <Route path="entry_completed" element={<EntryCompletedBeforeRender />} />
                {/* 申し込み内容チェック画面 */}
                <Route path="entry_check" element={<EntryCheckBeforeRender />} />
                {/* 審査状況確認画面 */}
                <Route path="examination_status" element={<ExaminationStatusBeforeRender />} />
                {/* 不備確認画面 */}
                <Route path="inquiry" element={<InquiryBeforeRender />} />
                {/* 二次審査後合意画面 */}
                <Route path="final_agreement" element={<FinalAgreementBeforeRender />} />
                {/* 二次審査後合意完了画面 */}
                <Route path="final_agreement_completed" element={<FinalAgreementCompletedBeforeRender />} />
                {/* ご利用開始画面 */}
                <Route path="use_start" element={<UseStartBeforeRender />} />
                {/* ご利用開始注意事項 */}
                <Route path="use_start_precautions" element={<UseStartPrecautionsBeforeRender />} />
                {/* システムエラー */}
                <Route path="system_error" element={<SystemErrorBeforeRender />} />
                {/* NtoFound */}
                <Route path="*" element={<NotFoundBeforeRender />} />
            </Routes>
        </BrowserRouter>
    );
}
export { Routing };