import { useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PostMethod } from "../api/Api"

const useUserRegisterAuthenticationHooks = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [ clickFlag, setClickFlag ] = useState(false)
    const paramsArray = location.search.substring(1).split('&')

    //emailパラメータ
    const emailParam = useMemo(()=>{
        let result
        paramsArray.forEach((param)=>{
            if(param.includes('user_name=')) result = param.split('user_name=')[1]
        })
        return result
    },[paramsArray])
    //認証コードパラメータ
    const confirmationCodeParam = useMemo(()=>{
        let result
        paramsArray.forEach((param)=>{
            if(param.includes('confirmation_code=')) result = param.split('confirmation_code=')[1]
        })
        return result
    },[paramsArray])
    //認証ボタン押下時
    const authButtonHandler = async() => {
        let result
        const postData = {
            user_name : emailParam,
            confirmation_code : confirmationCodeParam
        }
        // ダブルクリック時はAPIを実行しない
        if(!clickFlag){
            setClickFlag(true)
            result = await PostMethod(postData, '/mainentry_dev_redirect')
            return result
        }
        return
    }
    //認証成功時実行関数
    const normal = () => {
        return navigate('/login', {state:{routeTo:'login'}})
    }
    //400番台実行関数
    const abnormal = () => {
        return navigate(location.pathname + location.search)
    }
    return { authButtonHandler, normal, abnormal }
}

export default useUserRegisterAuthenticationHooks