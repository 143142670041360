import styled from 'styled-components';
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import { mixins } from '../../css/variables/mixins';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  background-color: ${colors.themeColor('background_whitegray')};
  padding: 12px 16px;
  border-radius: 8px;
  ${mixins.screenOver()}{
    display: flex;
    align-items: center;
  }
  ${mixins.screenUnder()}{
  }
`

const Inner = styled.div`
  .date {
    font-size: 15px;
  }
  .massage {
    font-size: 14px;
  }
  ${mixins.screenOver()}{
    &:first-child {
      width: 25%;
      border-right: 1px solid #B9C0D3;
    }
    &:last-child {
      width: 75%;
      padding-left: 12px;
      display: flex;
      align-items: center;
    }
  }
`

const StatusIcon = styled.p`
  width: 72px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: ${fonts.fontSizeRem('xs')};
`

const PaymentMethodText = styled.h3`
  font-size: ${fonts.fontSizeRem('ms')};
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  &:last-child {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #B9C0D3;
  }
  ${mixins.screenOver()}{
    &:last-child {
      padding: 0;
      margin: 0;
      border: none;
    }
  }
`

export const PaymentMethodBar = ({ examination, credit, convenience, bank }) => {

  // 現在のステータスを文字列として返す関数
  const statusElement = (statusId) => {
    switch (statusId) {
      case 'approval':
        return '承認';
      case 'denial':
        return '否認';
      case 'examination':
        return '審査中'
      default:
        break;
    }
  }

  return (
    <Wrapper>
      <Inner>
        {credit && 
          examination.method.map((item, index) => (
            <StatusWrapper key={index}>
              <StatusIcon className={`${item.status} mb0`}>
                {statusElement(item.status)}
              </StatusIcon>
              <PaymentMethodText className='mt0 mb0 ml16 bold'>{item.name}</PaymentMethodText>
            </StatusWrapper>
          ))
        }
        {convenience && 
          <>
            <StatusWrapper>
              <StatusIcon className={`${examination.status} mb0`}>
                {statusElement(examination.status)}
              </StatusIcon>
              <PaymentMethodText className='mt0 mb0 ml16 bold'>{examination.name}</PaymentMethodText>
            </StatusWrapper>
          </>
        }
        {bank && 
          <>
            <StatusWrapper>
              <StatusIcon className={`${examination.status} mb0`}>
                {statusElement(examination.status)}
              </StatusIcon>
              <PaymentMethodText className='mt0 mb0 ml16 bold'>{examination.name}</PaymentMethodText>
            </StatusWrapper>
          </>
        }
      </Inner>
      <Inner>
        {examination.status === 'examination' ? <></> : <p className='mb0 date'>{examination.date}</p>}
        <p className='mb0 massage'>{examination.massage}</p>
      </Inner>
    </Wrapper>
  )
}
