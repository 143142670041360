import React from "react";
import useUserRegisterHooks from '../../hooks/useUserRegisterHooks';
import { UserRegisterElement } from "../../components/ui_elements";
import { Container } from "../../components/layouts/Container";
import { ErrorHandler } from "../../api/ErrorHandler";

const UserRegister = () => {

  const { UserRegister, EmailData, PasswordData, ConfirmPasswordData, normal, abnormal, setEmailHandler, setPasswordHandler, setConfirmPasswordHandler, ErrMsgs, passCheckState } = useUserRegisterHooks();
  const { ErrorHandle } = ErrorHandler();

  // 登録実行イベント
  const UserRegisterHandler = async () => {
    const response = await UserRegister(EmailData, PasswordData, ConfirmPasswordData);
    //エラーハンドリング実行
    ErrorHandle(response, normal, abnormal, true)
  }

  return (
    <Container hasFooterMenu={false}>
      <UserRegisterElement
        UserRegisterHandler={UserRegisterHandler}
        setEmailHandler={setEmailHandler}
        setPasswordHandler={setPasswordHandler}
        setConfirmPasswordHandler={setConfirmPasswordHandler}
        passCheckState={passCheckState}
        ErrMsgs={ErrMsgs}
      />
    </Container>
  )
}

export default UserRegister