import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import { mixins } from '../../css/variables/mixins';

const TypographyStyle = styled.span`
  ${props => props.sx};
  font-size: ${props => props.size};
  ${mixins.screenOver()}{
    ${props => props.sizeDesktop ? 'font-size:' + props.sizeDesktop : null}
  }
  ${mixins.screenUnder()}{
    ${props => props.sizeMobile ? 'font-size:' + props.sizeMobile : null}
  }
  color: ${props => props.color};
  font-weight:${props => props.bold ? 'bold' : 'normal'};
`;

export const Typography = ({ size, sizeDesktop, sizeMobile, color, bold, sx, children }) => {
  return (
    <TypographyStyle size={fonts.fontSizeRem(size)} sizeDesktop={fonts.fontSizeRem(sizeDesktop)} sizeMobile={fonts.fontSizeRem(sizeMobile)} color={colors.themeColor(color)} bold={bold} sx={sx}>
      {children}
    </TypographyStyle>
  );
};

Typography.defaultProps = {
  size: 'm',
  color: colors.themeColor('text_main'),
  bold: false
};

