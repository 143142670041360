import React from 'react';
import styled from 'styled-components';
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';


const ButtonStyle = styled.button`
  border-radius: 4px;
  padding: 8px 12px 8px 32px ;
  line-height: 1.4;
  background: ${colors.themeColor('background_button_secoundary')};
  border: 1px solid ${colors.themeColor('border_box')};
  font-size: ${fonts.fontSizeRem('xxs')};
  color: ${colors.themeColor('text_main')};
  position: relative;
  cursor: pointer;
  &::after{
    content: "";
    position: absolute;
    display: block;
    left: 8%;
    width: 10px;
    height: 10px;
    border-top: solid 2px ${colors.themeColor('text_disabled')};
    border-left: solid 2px ${colors.themeColor('text_disabled')};
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
`

export const ButtonBack = ({ children }) => {
  return (
    <ButtonStyle>
      {children}
    </ButtonStyle>
  )
}