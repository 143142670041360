import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../css/variables/fonts';
import { colors } from '../../css/variables/colors';
import ImgSelectArrowDown from '../../images/icon_select_arrow_bottom.svg';
import { mixins } from '../../css/variables/mixins';

const SelectMenuStyle = styled.div`
  display:${props => props.fullwidth ? 'flex' : 'inline-flex'};
  flex-direction: column;
  margin-top: ${props => props.mt}px;
  label{
    display: block;
    margin-bottom: 4px;
    font-size:${fonts.fontSizeRem('s')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const SelectStyle = styled.select`
  background-color: ${props => props.backGroundColor ? colors.themeColor(props.backGroundColor) : colors.themeColor('background_input')};
  background-image: url(${ImgSelectArrowDown});
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding: 12px 36px 12px 16px;
  border-radius: 4px;
  width:${props => props.fullwidth ? '100%' : 'auto'};
  border:1px solid ${props => props.iserror ? colors.themeColor('border_error') + ' !important' : colors.themeColor('border_input')};
  outline: none;
  appearance: none;
  color: ${colors.themeColor('text_main')};
  font-size:${fonts.fontSizeRem('s')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${mixins.screenUnder()}{
    ${props => props.responsiveFullWidth ? 'width: 100%;' : ''}
  }
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`

export const SelectMenu = ({ id, name, label, options, defaultValue, mt, fullwidth, onChange, iserror, memo, value, ref, backGroundColor, responsiveFullWidth }) => {
  return (
    <SelectMenuStyle mt={mt} fullwidth={fullwidth}>
      {label ? (
        <label htmlFor={id}>{label}</label>
      ) : (<></>)}

      <SelectStyle defaultValue={defaultValue} id={id} name={name} fullwidth={fullwidth} responsiveFullWidth={responsiveFullWidth} onChange={onChange} data-testid={name} value={value} iserror={iserror} backGroundColor={backGroundColor}>
        {options.map((item, index) => (
          <option key={index} value={item.value} disabled={item.disabled} ref={ref}>{item.label}</option>
        ))}
      </SelectStyle>
      {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
    </SelectMenuStyle>
  );
};

SelectMenu.defaultProps = {
  mt: 16,
  fullwidth:false
};

