import { useEffect } from "react"
import ErrorBoundary from "../../api/ErrorBoundary"
import useGTMHooks from "../../hooks/useGTMHooks"
import UserRegisterAuthentication from "../rederPages/UserRegisterAuthentication"

const UserRegisterAuthenticationBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()
    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return(
        <ErrorBoundary>
            <UserRegisterAuthentication/>
        </ErrorBoundary>
    )
}
    
export default UserRegisterAuthenticationBeforeRender