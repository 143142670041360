import React from 'react';
import styled from 'styled-components'
import { ContentsWrapper } from './ContentsWrapper';
import { InquiryNavElement } from '../ui_elements';
import { Header } from './Header';
import { Footer } from './Footer';
import useInquiryNavHooks from '../../hooks/useInquiryNavHooks';
import {InquiryModalElement} from '../ui_elements';
import InquirySlideInElement from '../ui_elements/Inquiry/InquirySlideInElement';
import useContainerHooks from '../../hooks/useContainerHooks';
import { Loading } from '../ui-parts/Loading';
import { colors } from '../../css/variables/colors';
import EntryGuideWhiteIcon from '../../images/EntryGuideWhite.svg'
import { fonts } from '../../css/variables/fonts';
import { mixins } from '../../css/variables/mixins';
import CloseDatePopUp from '../ui_elements/Container/CloseDatePopUp';

const ContainerWrap = styled.div`
  display:flex;
  flex-direction:column;
  min-height: 100%;
`
const EntryGuideWrapper = styled.button`
  z-index: 990;
  box-sizing: border-box;
  position: fixed;
  bottom: 60px;
  right: 20px;
  height: 48px;
  background-color: ${colors.themeColor('background_button_primary')};
  border: 1px solid ${colors.themeColor('border_box')};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  gap: 8px;
  cursor: pointer;
  ${mixins.screenUnder()}{
    bottom: 16px;
    right: 8px;
    ${props => props.path.includes('entry/') ? "bottom: 68px;" : "" }
  }
`

const EntryGuideText = styled.p`
  font-size: ${fonts.fontSizeRem('s')};
  color: ${colors.themeColor('text_button_primary')};
  margin-bottom: 0px;
`

export const Container = ({ isLogin, isInquiry, background, hasFooterMenu, align, fullwidth, children }) => {
  
  const {isLoading, navigateEntryGuide, canceledFlag, closeState, closingNoticeText} = useContainerHooks({isLogin})
  const {InquiryData, InquiryCommentData,ModalFlag,modalOpenHandler,inquiryRegisterHandler,setInquiryRegisterHandler,InquiryRegisterErrMsg,ModalSendFlag,registerApiErrMsg,accordionNum,path,serchInquiryData,nonClosedCount,Toggle,toggleChangeHandler,inquiryApiErrMsg,inquiryCount,naviToggleChangeHandler,NaviFlag,slideInShowFlag,EntryGuideShowFlag,selectTitle} = useInquiryNavHooks(isLogin)

  return (
    <ContainerWrap>
      {/* ローディング画面 */}
      <Loading isLoading={isLoading} />
      {/* ヘッダー */}
      <Header isLogin={isLogin} ModalFlag={ModalFlag} modalOpenHandler={modalOpenHandler} canceledFlag={canceledFlag} closeState={closeState}/>
      {/* 不備連携通知 */}
      {(isInquiry && InquiryData.length !== 0)  &&
        <InquiryNavElement 
          InquiryData={InquiryData} 
          Toggle={Toggle} 
          toggleChangeHandler={toggleChangeHandler} 
          inquiryApiErrMsg={inquiryApiErrMsg} 
          nonClosedCount={nonClosedCount}
        />
      }
      {/* 画面クローズ通知 */}
      {(closeState.confirm_flag && isLogin) &&
        <CloseDatePopUp
          confirm_date={closeState.confirm_date}
          closeText={closingNoticeText}
        />
      }
      {/* 画面中身 */}
      <ContentsWrapper background={background} align={align} fullwidth={fullwidth}>
        {children}
      </ContentsWrapper>
      {/* 不備スライドイン */}
      {(isLogin && slideInShowFlag && inquiryCount.active !== 0) && 
        <InquirySlideInElement
          inquiryCount={inquiryCount}
          naviToggleChangeHandler={naviToggleChangeHandler}
          NaviFlag={NaviFlag}
          InquiryCommentData={InquiryCommentData}
          serchInquiryData={serchInquiryData}
          accordionNum={accordionNum}
        />
      }
      {/* お問い合わせモーダル */}
      {ModalFlag && 
          <InquiryModalElement
            modalOpenHandler={modalOpenHandler}
            inquiryHandler={inquiryRegisterHandler}
            setInquiryRegisterHandler={setInquiryRegisterHandler}
            modalSendFlag={ModalSendFlag}
            apiErrMsg={registerApiErrMsg}
            errMsg={InquiryRegisterErrMsg}
            selectOption={selectTitle}
          />
      }
      {/* 申し込みガイド */}
      {EntryGuideShowFlag &&
        <EntryGuideWrapper onClick={navigateEntryGuide} path={path}>
          <img src={EntryGuideWhiteIcon} alt='EntryGuideIcon' />
          <EntryGuideText>申し込みガイド</EntryGuideText>
        </EntryGuideWrapper>
      }
      <Footer hasFooterMenu={hasFooterMenu} />
    </ContainerWrap>
  );
};

Container.defaultProps = {
  isLogin: false,
  background: 'default',
  hasFooterMenu: false,
  align: 'center',
  fullwidth: false,
  isInquiry: false,
  loadingProp: null
};
