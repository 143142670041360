import React from 'react';
import styled from 'styled-components'
import { mixins } from '../../css/variables/mixins';

const EntryListWrapperStyle = styled.div`
  display: flex;
  ${mixins.screenOver()}{
    gap: 20px;
    &>*{
      width: calc(50% - 10px);
    }
  }
  ${mixins.screenUnder()}{
    gap: 16px;
    &>*{
      width: 100%;
    }
    flex-direction: column;
  }
`

export const EntryListWrapper = ({ children }) => (
  <EntryListWrapperStyle>
    {children}
  </EntryListWrapperStyle>
);


EntryListWrapper.defaultProps = {
};
