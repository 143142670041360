import React from 'react';
import styled from 'styled-components';
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import { mixins } from '../../css/variables/mixins';
import { EntryIcons } from './EntryIcons';
import imgChecked from '../../images/icon_check_white.svg';
import imgNg from '../../images/icon_ng_white.svg';

const EntryNavItemStyles = styled.li`
  list-style-type: none;
  justify-content: center;
  text-align: center;
  position: relative;
  min-width: 100px;
  cursor: ${props => props.isCurrent ? 'default' : 'pointer'};
  background: ${colors.themeColor('background_main')};
  padding: 12px 14px 8px;
  border:1px solid ${colors.themeColor('border_box')};
  &:not(:last-child){
    border-right: none;
  }
  &:first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:hover{
    ${props => props.isCurrent ? null : 'background:' + colors.themeColor('background_menu_hover')};
  }
  ${mixins.screenOver()}{
    flex: 1;
  }
  ${mixins.screenUnder()}{
    flex-grow: 1;
    flex: 1;
  }
  &::after{
    content:'';
    content: ${props => props.isCurrent ? '' : 'none'};
    display: block;
    width: 100%;
    height: 4px;
    background: ${colors.themeColor('primary_dark')};
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &::before{
    content:${props => props.status === 'default' ? 'none' : '""'};
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    color: ${colors.themeColor('text_white')};
    position: absolute;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: center center;
    background-color:${props => stepIconColor[props.status]};
    ${props => props.isCurrent ? 'background-color:' + colors.themeColor('primary_dark') : null};
    ${props => props.status === 'default' ? 'border:3px solid ' + colors.themeColor('border_box') : null};
    ${props => props.isCurrent ? 'border:none' : null};
    background-image:url(${props => stepIconImage[props.status]});
    z-index: 1;
    left: 11px;
    top: -6px;
    ${mixins.screenUnder()}{
      left: 4px;
    }
  }
`
const EntryNavIcon = styled.div`

`
const EntryNavTitle = styled.span`
  display: inline-block;
  font-weight:bold;
  font-size:${fonts.fontSizeRem('ms')};
  ${mixins.screenUnder()}{
    font-size: ${fonts.fontSizeRem('xxs')};
    white-space: nowrap;
  }
`
const stepIconColor = {
  'default': colors.themeColor('background_main'),
  'checked': colors.themeColor('primary'),
  'error': colors.themeColor('background_error')
}

const stepIconImage = {
  'default': 'none',
  'checked': imgChecked,
  'error': imgNg
}



export const EntryNavItem = ({ title, icon, isCurrent, status, onClick }) => {

  return (
    <EntryNavItemStyles isCurrent={isCurrent} status={status} onClick={onClick}>
      <EntryNavIcon>
        <EntryIcons icon={icon} />
      </EntryNavIcon>
      <EntryNavTitle>{title}</EntryNavTitle>
    </EntryNavItemStyles>
  );
};


EntryNavItem.defaultProps = {
  status: 'default',
  isCurrent: false
};

