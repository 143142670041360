import useLimitDirectAccess from "../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../hooks/useUserAuthHooks";
import ChangePassword from "../rederPages/ChangePassword";
import ErrorBoundary from "../../api/ErrorBoundary";
import { useEffect, useState } from "react";
import useGTMHooks from "../../hooks/useGTMHooks";

const ChangePasswordBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks();
    const {AuthCheck} = useUserAuthHooks();
    const {DirectAccessCheck} = useLimitDirectAccess()
    const [directFlag, setDirectFlag] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    const { pushDataLayerHandler } = useGTMHooks()
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      pushDataLayerHandler()
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('change_password')) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return

    return(
      <ErrorBoundary>
        <ChangePassword/>
      </ErrorBoundary>
    )
}

export default ChangePasswordBeforeRender