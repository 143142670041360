import { useState, useRef } from "react";

const useInquiryFileHooks = (FileDatas, setFileData) => {
    // ツールチップState
    const[tip, setTip] = useState(false);
    const FileRef = useRef();

    // ツールチップState変更関数
    const tipChangeHandler = () => {
        setTip(prev => !prev);
    };

    
    // ファイルの登録
    const loadFile = (e) => {
        const inputFiles = FileRef.current.files;
        for (let file of inputFiles) {
            var fr = new FileReader();
            fr.onload = async (e) => {
                let sumFileSize = 0
                let count = Object.keys(FileDatas).length;
                const fileType = file.type.split('/')[1]
                const insertFileData = e.target.result.split(',')[1]
                const tempFileData = { file_name_origin: file.name, file_data: insertFileData, size:file.size  }
                let preInsertFileData = { ...FileDatas, [count]: {...tempFileData} }
                const fileDataArray = Object.values(preInsertFileData)
                //ファイルデータが30Mb以上ないかチェック
                fileDataArray.forEach((file)=>{
                    sumFileSize += file.size
                })
                if(sumFileSize > 31457281) return alert('ファイルを登録できません。ファイルの上限は合計30MBです。')
                setFileData(Object.values(fileDataArray)) 
            }
            fr.readAsDataURL(file);
        }

        const setNullValue = () => {
            e.target.value = null
        }
        //同じファイルを登録->消去->登録ができないバグを解消する
        setTimeout(setNullValue, 1000)
    }
    // useEffect(()=>{
    //     let sumFileSize = 0
    //     FileDatas.forEach((file)=>{
    //         sumFileSize += file.size
    //     })
    //     if(sumFileSize > 3500000) alert('ファイルの上限は合計3.5MBです。')
    // },[FileDatas])

    // アップロードしたファイルの削除
    const deleteFile = (num) => {
        const newFileArray = FileDatas.filter((file, index) => (index !== num))
        setFileData(newFileArray)
    }

    return {
        tip,
        tipChangeHandler,
        FileRef,
        loadFile,
        deleteFile,
    }
}

export default useInquiryFileHooks;