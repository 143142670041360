import React from "react";
import useResetPasswordMailHooks from "../../hooks/useResetPasswordMailHooks";
import { ResetPasswordMailElement } from "../../components/ui_elements";
import { Container } from "../../components/layouts/Container";
import { ErrorHandler } from "../../api/ErrorHandler";

const ResetPasswordMail = () => {

  const { sendEmail, setEmailHandler, EmailData, ErrMsg, normal, abnormal } = useResetPasswordMailHooks();
  const { ErrorHandle } = ErrorHandler();

  //エラーハンドリング実行
  const sendEmailHandler = async () => {
    const response = await sendEmail(EmailData);
    ErrorHandle(response, normal, abnormal, true)
  }

  return (
    <Container>
      <ResetPasswordMailElement
        setEmailHandler={setEmailHandler}
        sendEmailHandler={sendEmailHandler}
        ErrMsg={ErrMsg}
      />
    </Container>
  )
}

export default ResetPasswordMail