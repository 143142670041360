import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../stores/Provider";
import { getSession } from "../sessionStorage/sessionStorageMethod";

export const useLoginCheckHooks = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);
    const loginInfoData = getSession('login')

    const LoginCheck = () => {
        let flag = false
        //セッションのログインデータが存在しない,またはコードが200以外の時は排除
        if (loginInfoData !== null && loginInfoData.code === 200){
            flag = true
        } else {
            dispatch({
                type: 'set-context-data',
                payload: {
                    auth: {
                        err_message: '不正なアクセスです。再度ログインをお願いします。'
                    }
                }
            })
            navigate('/login');
            flag = false
        }
        return flag
    }
    return {LoginCheck,state}
}