import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import IconArrowLeftImg from '../../images/icon_arrow_left_gray_16.svg'

const ButtonStyle = styled.button`
  background-color: ${colors.themeColor('background_main')};
  border: 1px solid ${colors.themeColor('border_box')};
  padding: 8px 12px 8px 32px;
  border-radius: 4px;
  background-image:url(${IconArrowLeftImg});
  background-position: 8px center;
  background-repeat: no-repeat;
  font-size:${fonts.fontSizeRem('xxs')};
  color: ${props => props.disabled ? colors.themeColor('text_disabled') : colors.themeColor('text_main')};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  &:hover,&:active{
    ${props => props.disabled ? null : `background-color: ${colors.themeColor('background_menu_hover')}`}
  }
  width: ${props => props.fullwidth ? '100%' : 'auto'};
`;


export const BackButton = ({ fullwidth, disabled, onClick, children }) => {
  return (
    <ButtonStyle fullwidth={fullwidth} disabled={disabled} onClick={onClick}>
      {children}
    </ButtonStyle>
  );
};


BackButton.defaultProps = {
  fullwidth: false,
  disabled: false
};

